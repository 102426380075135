import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ServerUrl } from "../../../../ADAPI";
import Select from "react-select";
const ProductUp = ({sno}) => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const [oldClientName, newClientName] = useState();
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", sno);
        axios.post(ServerUrl+"aderp/admin/quot/getquotlistsno.php", formdata).then(response => { 
            console.log(response.data);
            newClientName(response.data[0].aclientname);
        })
    },[sno])
    const proRefSwap = useRef();
    const [oldStatus, newStatus] = useState(false);
    const [oldClientPro, newClientPro] = useState([]);
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("api_key", "SwapnilAllowed");
        formdata.append("aclient", oldClientName)
        axios.post(ServerUrl+"aderp/admin/quot/getClientPro.php", formdata).then(response=>{ 
          newClientPro(response.data);
          proRefSwap.current.clearValue();
          newStatus(false);
          console.log(response.data);
         })
      },[oldClientName]);

      let count=1;

// Array For Selecting Product;
let clientProArray = oldClientPro.map(data =>{ return { label : data.productname, value: data.sno, value2:data.aproprice, value3:data.ahsncode, value4:data.aprodesc } }) 

const [oldSelVal, newSelVal] = useState([]);
const [oldProSelArr, newProSelArr] = useState([]);


useEffect(()=>{
    newProSelArr(oldSelVal.map(data=> { return { productname:data.label.replace(/(\r\n|\n|\r)/gm, " "),sno:data.value, productprice:data.value2, hsncode: data.value3, desc: data.value4.replace(/(\r\n|\n|\r)/gm, " "), productquanity:0 } }))
  
  },[oldSelVal]);
  
  const SwapShow = ()=>{
    console.log(oldProSelArr);
  }




  const HandleUpdateSubmit = (event) => { 
    event.preventDefault();
    const formdata = new FormData();
    formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formdata.append("sno", sno);
    formdata.append("aquotprod", JSON.stringify(oldProSelArr));
    axios.post(ServerUrl+"aderp/admin/quot/UpQuotPro.php", formdata).then(response => { 
        alert(response.data);
        window.location.reload();
    })
  }



    return <div>
        <form onSubmit={HandleUpdateSubmit}>
        <hr/>
<br/>
<label>Select Products</label>
<Select options={clientProArray} isMulti={true} isDisabled={oldStatus} ref={proRefSwap} onChange={(newValue)=>{ newSelVal(newValue); }} />
<br/>
<br/>

<table onClick={()=>{ newStatus(true); }} className="table table-bordered table-dark">
  <thead>
    <tr>
      <th scope="col">Sr.No.</th>
      <th scope="col">Product Name</th>
      <th scope="col">Product Price</th>
      <th scope="col">Product Quanity</th>
    </tr>
  </thead>
  <tbody>
{oldProSelArr.map(data => { return     <tr>
      <th scope="row"> {count++} PrID :{data.sno}</th>
      <td>{data.productname}</td>
      <td>{data.productprice}</td>
      <td><input type="number" className="form form-control" onChange={(e)=>{ data.productquanity=e.target.value; SwapShow()}} /></td>
    </tr>

})}


  </tbody>
</table>
<br/>

<button className="btn btn-success" type="submit">Update</button>

</form>
    </div>; 
}
export default ProductUp;
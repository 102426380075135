import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import { ServerUrl } from "../../../ADAPI";
import { useLocation, useNavigate } from "react-router-dom";
const ProOut = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    // Product List
    //Available
    const location = useLocation();
    console.log(location.state.api_key);
    const [oldData, newData] = useState([]);
    useEffect(
        ()=>{
            axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowStack.php").then(response=> {
                newData(response.data);
                console.log(response.data);
            })
        },[]
    )
    // Used
    // const [oldNData, newNData] = useState([]);
    // useEffect(
    //     ()=>{
    //         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowOut.php").then(response=> {
    //             newNData(response.data);
    //             console.log(response.data);
    //         })
    //     },[]
    // )
    // const [oldNotData, newNotData] = useState([]);
    // useEffect(
    //     ()=>{
    //         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowNonSel.php").then(response=> {
    //             newNotData(response.data);
    //             console.log(response.data);
    //         })
    //     },[]
    // )

        
    let proArray = oldData.map(data=>{
        return {value : data.productname, value2:parseInt(data.available_stock),label : data.productname+"   [ Available Quantity  "+data.available_stock +"]" }
    });

    // oldData.map(data=>{
    //     // console.log(data.productname)
    //      oldNData.map(ndata=>{
    //         // console.log(ndata.sin);
    //          if(ndata.productname===data.productname && data.sin-ndata.sout >0)
    //         {
    //             proArray.push({value : data.productname, value2: parseInt(data.sin-ndata.sout), label : data.productname+"  [ Available Quantity "+(data.sin-ndata.sout) +"]" });
    //         }
    //     })
    // })
    

    // oldNotData.map(data=>{
    //     proArray.push({value : data.productname, value2:parseInt(data.quantity),label : data.productname+"   [ Available Quantity  "+data.quantity +"]" })
    // })
    //  console.log(proArray);
 





    //  ShowNonSel.php


// Above Field is Done



    // Client Name + Job No
    const [oldJobNo, newJobNo] = useState([]);
    useEffect(
        ()=>{
            axios.get(ServerUrl+"aderp/user/store/getjobno.php").then(response=> {
                newJobNo(response.data);
            })
        },[]

    )
    // Later Old Job No

    const [oldOldJobNo, newOldJobNo] = useState([]);
    useEffect(
        ()=>{
            axios.get(ServerUrl+"aderp/user/store/getoldjobno.php").then(response=> {
                newOldJobNo(response.data);
                console.log(response.data);
            })
        },[]

    )
    // Later Old Job No

    const [oldClientData, newClientData] = useState([]);
    useEffect(()=>{
        axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newClientData(response.data);
        });
    },[]);

    const [oldVend, newVen] = useState([]);
    useEffect(()=>{
        axios.get(ServerUrl+"aderp/user/GetVendor.php").then(response=>{
            newVen(response.data);
        })
        
    },[]);
  /*
    let clientDataArray = oldClientData.map(data=>{
        return { value:data.acompanyname, label:data.acompanyname }
    }).concat( oldJobNo.map(data=>{ return {value:data.sno, label:"JOBNO"+data.sno} }) ).concat(oldVend.map(data=>{ return { label:data.acompanyname, value:data.acompanyname } })).concat(oldOldJobNo.map(data=>{
        return {value:data.sno+"(old)", label:"[OLD]JOBNO"+data.sno}
    }))

*/
    let clientDataArray =  oldJobNo.map(data=>{ return {value:data.sno, label:"JOBNO"+data.sno} }).concat(oldVend.map(data=>{ return { label:data.acompanyname, value:data.acompanyname } })).concat(oldOldJobNo.map(data=>{
        return {value:data.sno+"(old)", label:"[OLD]JOBNO"+data.sno}
    }))
    // Done This Field

// Value Detector
const [oldProductInfo, newProductInfo] = useState();
const [oldClientInfo, newClientInfo] = useState();
const [oldQuantInfo, newQuantInfo] = useState();

const HandleSubmitData = (event) =>
{
    event.preventDefault();
    if(Boolean(oldProductInfo) && Boolean(oldClientInfo) && Boolean(oldQuantInfo))
    {
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("productname", oldProductInfo);
            formdata.append("companyname", oldClientInfo);
            formdata.append("quantity", oldQuantInfo);
            axios.post(ServerUrl+"aderp/user/store/addproductout.php", formdata).then(
                response => {

                    if(response.data==="done"){
                        alert("Product Out Successfully");
                    }else if(response.data === "aval"){
                        alert("Out of Stock or Quantity is Greater than Available Stock")
                    }else{
                        alert("Something Went Wrong");
                    }

                
                    window.location.reload();
                }
            );
    }
    else {
        alert("All Field Required");
    }
}


const [oldAvailable, newAvailable] = useState(0);
console.log(oldAvailable);
    return(<>
      <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>
    <div className="container">
        <strong>
            Product Out (Store)
        </strong>
<br/>
<hr/>
        <form onSubmit={HandleSubmitData}>

            <label>Select Client or Job No.</label>
            <br/>
            <Select options={clientDataArray} onChange={(selectedvalue)=> { newClientInfo(selectedvalue.value); }} className="form form-control" maxMenuHeight={40} openMenuOnClick={false} />
            <br />

            <label>
                Select Product To Out 
            </label>
            <Select options={proArray} onChange={(selectedvalue)=>{newProductInfo(selectedvalue.value); newAvailable(selectedvalue.value2)}} className="form form-control" />
            <br/>

            <label>Enter A Quantity</label>
            <br/>
            <input type="number" onChange={(event)=>{ newQuantInfo(event.target.value); }} className="form form-control" max={oldAvailable} required/>

            <br/>
        
            <button type="submit" className="btn btn-primary">Product Out</button>
            &nbsp;
            <button type="reset" className="btn btn-danger">Clear</button>
        </form>


    </div>
    
    </>);
}
export default ProOut;
import { useLocation, useNavigate } from "react-router-dom";

const AMC = () => { 
  
    const location = useLocation();
    console.log(location.state.api_key);
    const navigate = useNavigate();
    return(<><div className="row">
    <div className="col-sm-3" onClick={()=>{navigate("uwamc", {replace:false, state:{api_key:"swapnilallowed"}})}}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title"><i className="fa fa-solid fa-mug-hot"></i>&nbsp; Under Warrenty AMC</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>
    <div className="col-sm-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title"><i className="fa fa-solid fa-file-invoice"></i>&nbsp; Proforma  Invoice</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>
  
    <div className="col-sm-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title"><i className="fa fa-solid fa-bug"></i>&nbsp;Report Bug</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>
  </div></>);
}

export default AMC;
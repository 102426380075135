import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import axios from "axios";
import Select from 'react-select';
const ReparWo = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    // Client Data Recieving
const [oldTabData, newTabData] = useState([]);
const SwapDataGetter = () =>  {
    axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newTabData(response.data);
     });
}
useEffect(SwapDataGetter,[]);

// Clientname Select Array
const ClientSelectArray = oldTabData.map((item) => { return { label : item.acompanyname, value : item.acompanyname, value2 : item.aadress, value3 : item.acontactperson, value4 : item.amobno, value5 : item.aqcemail } });





// Variables
const [comapanyname, setcomapanyname] = useState();
const [address, setaddress] = useState();
const [email, setemail] = useState();
const [prodesc, setprodesc] = useState();
const [qty, setqty] = useState();
const [remark, setremark] = useState();
const [specialinstru, setspecialinstru] = useState("Completed Within Date");
const [targetdate, settargetdate] = useState();
const [sercvicedept, setsercvicedept] = useState();
const [oldPayTerms, setPayTerms] = useState("After Work Completion");
const [oldStoreDept, setStoreDept] = useState();

const [prodDetails, setprodDetails] = useState([]);


const HandleFormSubmitData = (event) => {
    event.preventDefault();
    if(Boolean(comapanyname)){
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("comapanyname", comapanyname);
        formdata.append("address", address);
        formdata.append("mailid", email);
        // formdata.append("prodesc", prodesc);
        // formdata.append("qty", qty);
        formdata.append("prodDetails", JSON.stringify(prodDetails))
        formdata.append("payterms", oldPayTerms);
        formdata.append("remark", remark);
        formdata.append("specialinstru", specialinstru);
        formdata.append("targetdate", targetdate);
        formdata.append("prepby", location.state.aname);
        formdata.append("store_dept_per", oldStoreDept);
        formdata.append("service_dept_per", sercvicedept);
            axios.post(ServerUrl+"aderp/admin/repairworkorder/createworkorder.php", formdata).then(response => {
                if(response.data === "done"){
                    alert("Work Order Created");
                    window.location.reload();
                }else{
                    alert(response.data);
                }
            })
    }
}


    return <>
                    <button onClick={()=>{ navigate(-1) }} className="back back--one">
                    <span className="top-left" />
                    <span className="top-right" />
                    <span className="bottom-left" />
                    <span className="bottom-right" />
                    <span className="stalk" />
                    <span className="text">BACK</span>
                    </button>

                    <div className="container">
                        <button className="btn btn-success" onClick={()=>{
                            navigate("list", {replace:false, state: { aname: location.state.aname  }})
                        }}>List</button>
                        <hr/>


                <form onSubmit={HandleFormSubmitData}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <label>Select Comapny Name</label>
                            <Select options={ClientSelectArray} onChange={(newValue)=>{
                                        setcomapanyname(newValue.value);
                                        setaddress(newValue.value2);
                                        setemail(newValue.value5);
                                    }} />


                            <label>Address</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setaddress(e.target.value) }} defaultValue={address} />

                            <label>Email Id</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setemail(e.target.value) }} defaultValue={email} />
             
                           <label>Product Description</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setprodesc(e.target.value) }} value={prodesc}  />

                            <label>Quantity</label>
                            <input type="number" className="form form-control" onChange={(e)=>{ setqty(e.target.value) }} value={qty} />
                                    <button className="btn btn-primary" onClick={(e)=>{
                                        e.preventDefault();
                                        setprodDetails([...prodDetails, { prodDesc : prodesc, qty : qty }]);
                                        setprodesc("");
                                        setqty("");
                                    }} type="button"> Add </button>
                            <br/>
                            <label>Remark</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setremark(e.target.value) }}  />

                            <label>Special Instruction</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setspecialinstru(e.target.value) }} defaultValue={specialinstru} />

                            <label>Target Date</label>
                            <input type="date" className="form form-control" onChange={(e)=>{ settargetdate(e.target.value) }}  />


                            </div>
                            <div className="col-sm">

<label>Enter Payments Terms</label>
<input type="text" className="form form-control" onChange={(e)=>{
    setPayTerms(e.target.value);
}} defaultValue={oldPayTerms} />

                            <label>Service Dept. Person Name</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setsercvicedept(e.target.value) }}  />
                            <label>Store Dept. Person Name</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setStoreDept(e.target.value) }}   />

<br/>


<button className="btn btn-primary" type="submit">Create Work Order</button>
&nbsp;<button className="btn btn-danger" type="reset">Reset</button>
                            </div>
                         
                        </div>
                        </div>
                        </form>
<table className="table table-dark">
    <thead>
        <tr>
            <th>Product Description</th>    
            <th>Quantity</th>
        </tr>
    </thead>
    <tbody>
        {prodDetails.map((item)=>{
            return <tr>
                <td>{item.prodDesc}</td>
                <td>{item.qty}</td>
            </tr>
        })}
    </tbody>
</table>

                    </div>  

    </>;
};

export default ReparWo;

/*


        
        $comapanyname = $_POST['comapanyname'];
        $address = $_POST['address'];
        $mailid = $_POST['mailid'];
        $prodesc = $_POST['prodesc'];
        $qty = $_POST['qty'];
        $payterms = $_POST['payterms'];
        $remark = $_POST['remark'];
        $specialinstru = $_POST['specialinstru'];
        $targetdate = $_POST['targetdate'];
        $prepby = $_POST['prepby'];
        $store_dept_per = $_POST['store_dept_per'];
        $service_dept_per = $_POST['service_dept_per'];

*/
import axios from "axios";
import { useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import { useLocation, useNavigate } from "react-router-dom";
const JobNo = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state.api_key);
const [oldDesc, newDesc] = useState(null);

const handleSubmit = (event)=>{
    event.preventDefault();
    const formdata = new FormData();
    formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formdata.append("desc", oldDesc);
    axios.post(ServerUrl+"aderp/user/store/createjobno.php", formdata).then(
        response => {
            alert("Job No Created JOBNO-"+response.data)
        }
    )
}
    return(<>  <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
    <div className="container">
        <form onSubmit={handleSubmit}>
            <strong>Create Job No (Store)</strong>
            <br/>
            <h6>Enter A Description for Job No</h6>
            <input type="text" className="form form-control" placeholder="Description here" onChange={(e)=> { newDesc(e.target.value); }} required></input>
            <br></br>
            <button className="btn btn-primary" type="submit">Create Job No</button>
            &nbsp;
            <button type="reset" className="btn btn-danger">Clear</button>
        </form>
    </div></>)
}

export default JobNo;
import { useLocation, useNavigate } from "react-router-dom";

const PIPage = () => { 

    const navigate = useNavigate();
    const location = useLocation();
    return<>
     <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
  
<div className="container">
    <button className="btn btn-primary" onClick={ ()=>{
        navigate("po", { replace:false, state:{ aname: location.state.aname } })
    } }> Purchase Order </button>
    &nbsp;
    <button className="btn btn-primary" onClick={ ()=>{ navigate("pi", { replace:false, state:{
        aname:location.state.aname
    } })}}> Proforma Invoice </button>

</div>

  
  </>;
}

export default PIPage;
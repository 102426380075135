import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";

const AMCWOList = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state.aname);

    const [oldData , newData] = useState([]);
useEffect(()=>{
    axios.get(ServerUrl+"aderp/admin/amcworkorder/getlist.php").then(response => {
        newData(response.data);
    })
},[])

    return <>

                              
<button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

<div className="container">
<table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">Sr No</th>
      <th scope="col">Company Name</th>
      <th scope="col">Date</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {oldData.map(data => {
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.companyname}</td>
        <td>{data.adate}</td>
        <td><button className="btn btn-success" onClick={()=>{navigate("pdf", {replace:false, state:{
            sno: data.sno,
            aname : location.state.aname,
        }})}}>Print</button></td>
      </tr>
    }).reverse()}

  </tbody>
</table>

</div>
    </>;    

}

export default AMCWOList;
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import { useState, useEffect, useRef } from "react";
const ADUp = () => { 
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
const navigate = useNavigate();
const location = useLocation();
const [oldTabData, newTabData] = useState([]);
const sno = location.state.sno;
useEffect(()=>{ axios.post(ServerUrl+"aderp/admin/adprogetsno.php?api_key=SwapnilAllowed&sno="+sno).then(response => { newTabData(response.data) }).catch(err => { console.log(err) }) },[]);
console.log(oldTabData);


    // Data Store Variables
    const oldHsnCode = useRef();
    const oldAProName= useRef();
    const oldProPrice = useRef();
    const oldDesc = useRef();
    const oldProTac = useRef();

    // Variable For Equipment Properties
    const oldProRange = useRef();
    const oldProAcc = useRef();
    const oldUniForm = useRef();
    const oldEquConst = useRef();
    const oldContSyst = useRef();
    const oldRefigeration= useRef();
    const oldSafety= useRef();
    const oldOptStand = useRef();
    const oldCFR = useRef();
    const oldEQDocs = useRef();
    const oldSoftDocs = useRef();




let SwapObjData = <></>;
if(oldTabData.map(data=> { return data.aprocategory})=="equipment"){
    SwapObjData = <>
                <br/>
    <label>Product Range</label>
    <input type="text" ref={oldProRange} className="form form-control" placeholder="Enter A Product Range"
    defaultValue={oldTabData.map(data=> { return data.arange})}
    />
    <br/>
    <label>Product Accuracy</label>
    <input type="text" ref={oldProAcc} className="form form-control" placeholder="Enter A Product Accuracy"
     defaultValue={oldTabData.map(data=> { return data.aaccuracy})}
    />
    <br/>
    <label>Product Uniformity</label>
    <input type="text" ref={oldUniForm} className="form form-control" placeholder="Enter A Product Uniformity"
     defaultValue={oldTabData.map(data=> { return data.auniformity})}
    />
    <br/>
    <label>Equipment Construction</label>
    <textarea ref={oldEquConst} className="form form-control" placeholder="Enter A Product Contruction" 
     defaultValue={oldTabData.map(data=> { return data.aequpconst})}
    />
    <br/>
    <label>Control System</label>
    <textarea ref={oldContSyst} className="form form-control" placeholder="About Control System"
     defaultValue={oldTabData.map(data=> { return data.acontrolsys})}
    />
    <br/>
    <label>Refigeration System</label>
    <textarea ref={oldRefigeration} className="form form-control" placeholder="Enter About Refigeration System" 
     defaultValue={oldTabData.map(data=> { return data.arefsystem})}
    />
    <br/>
    <label>Safety Features System</label>
    <textarea ref={oldSafety} className="form form-control" placeholder="Enter Safety Features"
     defaultValue={oldTabData.map(data=> { return data.asafefeature})}
    />
    <br/>
    <label>Optional Stand By as Per Requirements</label>
    <textarea ref={oldOptStand} className="form form-control" placeholder="About Optional Stand by as Per Requirements"
     defaultValue={oldTabData.map(data=> { return data.aoptionalstand})}
    />
    <br/>
    <label>21 CFR Part 11 Compliance Software</label>
    <textarea ref={oldCFR} className="form form-control" placeholder="21 CFR Part 11 Compliance Software"
     defaultValue={oldTabData.map(data=> { return data.acfrpart})}
    />
    <br/>
    <label>Documentation for Equipement</label>
    <textarea ref={oldEQDocs} className="form form-control" placeholder="Documentation for Equipement"
     defaultValue={oldTabData.map(data=> { return data.adocequp})}
    />
    <br/>
    <label>Documentation for Software</label>
    <textarea ref={oldSoftDocs} className="form form-control" placeholder="Documentation for Software" 
     defaultValue={oldTabData.map(data=> { return data.adocsoft})}
    />
    </>;

    // newOptionMode(SwapObjData);
}else { 
    SwapObjData=<></>;
}

const HandleFormSubmit =(event) => { 
    event.preventDefault();
    if(Boolean(oldAProName.current.value) && Boolean(oldHsnCode.current.value) && Boolean(oldProPrice.current.value) && Boolean(oldDesc.current.value) && Boolean(oldProTac.current.value)){
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", sno);
        formdata.append("aprocategory", oldTabData.map(data=> { return data.aprocategory}));
        formdata.append( 'productname', oldAProName.current.value);
        formdata.append('ahsncode', oldHsnCode.current.value);
        formdata.append('aproprice', oldProPrice.current.value);
        formdata.append('aprodesc', oldDesc.current.value);
        formdata.append('aprodtandc', oldProTac.current.value);

        if(oldTabData.map(data=> { return data.aprocategory})=="equipment"){ 
            formdata.append('arange', oldProRange.current.value);
            formdata.append('aaccuracy', oldProAcc.current.value);
            formdata.append('auniformity', oldUniForm.current.value);
            formdata.append('aequpconst', oldEquConst.current.value);
            formdata.append('acontrolsys', oldContSyst.current.value);
            formdata.append('arefsystem', oldRefigeration.current.value);
            formdata.append('asafefeature', oldSafety.current.value);
            formdata.append('aoptionalstand', oldOptStand.current.value);
            formdata.append('acfrpart', oldCFR.current.value);
            formdata.append('adocequp', oldEQDocs.current.value);
            formdata.append('adocsoft', oldSoftDocs.current.value);
        }

        axios.post(ServerUrl+"aderp/admin/adproupdate.php", formdata).then(response=>{ alert(response.data);window.location.reload(); }).catch(err => { 
            console.log(err)
        })
    }else{
        alert("All Fields required");
    }

}


return<>    <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
  

  <div className="container">
  <form onSubmit={HandleFormSubmit}>

<hr />
<strong>Update Product</strong>
<br />
<br />


<label>Product Name</label>
<input type="text" ref={oldAProName} className="form form-control"  placeholder="Enter A Product Name" 

defaultValue={oldTabData.map(data=> { return data.productname})}
/>
<br/>
<label>HSN/SAC Code</label>
<input type="text" ref={oldHsnCode} className="form form-control" placeholder="Enter HSN/SAC Code" 
defaultValue={oldTabData.map(data=> { return data.ahsncode})}
/>
<br/>
<label>Product Price</label>
<input type="text" ref={oldProPrice} className="form form-control"  placeholder="Enter A Product Price"
defaultValue={oldTabData.map(data=> { return data.aproprice})}
/>
<br/>
<label>Product Description</label>
<textarea ref={oldDesc} className="form form-control"  placeholder="Enter A Product Description"
defaultValue={oldTabData.map(data=> { return data.aprodesc})}
/>
<br/>
<label>Product T&C</label>
<textarea type="text" ref={oldProTac} className="form form-control" placeholder="Enter A Product T & C" 
defaultValue={oldTabData.map(data=> { return data.aprodtandc})}
/>
{SwapObjData}
<br/>

<button className="btn btn-primary" type="submit">Submit</button>
&nbsp;&nbsp;

</form>


  </div>
  </>;
}

export default ADUp;
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ServerUrl } from "../../ADAPI";
const StorePage = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.aname);

    const navigate = useNavigate();
const [oldInData, newInData] = useState([]);
useEffect(()=>{
    axios.get(ServerUrl+"aderp/user/store/ProDataGet/In.php").then(response => { 
        newInData(response.data);    })
}, [])

const [oldOutData, newOutData] = useState([]);
useEffect(
    ()=>{ axios.get(ServerUrl+"aderp/user/store/ProDataGet/Out.php").then(response => {
        newOutData(response.data);
    }) },[]
)

console.log(oldInData);


    return(<>

  <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

    <div className="container">
        <div style={{"paddingTop":"50px"}}>
        <button className="btn btn-primary" onClick={()=>{navigate("addproduct", { replace:false, state:{api_key:"swapnilallowed" } })}}>Add Product or Product List</button>   
        &nbsp;
        <button className="btn btn-primary" onClick={()=>{navigate("productin", { replace:false, state:{ api_key:"swapnilallowed"} })}}>Store In</button>   
        &nbsp;
        <button className="btn btn-primary" onClick={()=>{navigate("productout", { replace:false, state:{api_key:"swapnilallowed" } })}}>Store Out</button>   
        &nbsp;
        <button className="btn btn-primary" onClick={()=>{navigate("createjobno", { replace:false, state:{ api_key:"swapnilallowed"} })}}>Create Job No</button>   
        &nbsp;
        <button className="btn btn-primary" onClick={()=>{navigate("dc", { replace:false, state:{ api_key:"swapnilallowed", aname : location.state.aname} })}}>Delivery Challan</button>   
        &nbsp;

        <button className="btn btn-primary" onClick={()=> { navigate("stack", { replace:false }) }}>Check Products Stack</button>

</div>

<strong>Store Data Table</strong>
<br></br>
{/* IN Data Table */}
<h4>In Data</h4>
<div className="overflow-auto" style={{"maxHeight":"200px"}}>
<table className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">Product Name</th>
      <th scope="col">Quantity</th>
      <th scope="col">Asset Type</th>
      <th scope="col">Product Check</th>
      {/* <th scope="col">Vendor Name</th> */}
    </tr>
  </thead>
  <tbody>
    {oldInData.map(data=>{
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.productname}</td>
        <td>{data.quantity}</td>
        <td>{data.assettype}</td>
        <td>{data.status}</td>
        {/* <td>{data.vendorname}</td> */}
      </tr>
    }).reverse()}

  </tbody>
</table>

</div>

{/* Out Data Table */}
<br/>
<h4>Out Data</h4>
<div className="overflow-auto" style={{"maxHeight":"200px"}}>

<table className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">Product Name</th>
      <th scope="col">Quantity</th>
      {/* <th scope="col">Client or Job Details</th> */}

    </tr>
  </thead>
  <tbody>
    {oldOutData.map(data=>{
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.productname}</td>
        <td>{data.quantity}</td>
        {/* <td>{data.clientname}</td> */}


      </tr>
    }).reverse()}

  </tbody>
</table>


</div>
    </div>
    
    </>);
}

export default StorePage;
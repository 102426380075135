import { useNavigate, useLocation } from "react-router-dom";
const WorkOrderHome = () => {
  
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div>
                 <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

{/* Main Body */}
<div className="container">
<div className="row">
    <div className="col-sm-3" onClick={() => { navigate("machine", { replace : false, state : { aname : location.state.aname } }) }}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
        
            &nbsp; Machine Work Order</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>
    <div className="col-sm-3" onClick={() => { navigate("amcwo", { replace : false, state : { aname : location.state.aname } }) }}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">&nbsp; AMC WORK ORDER</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>
  
    <div className="col-sm-3" onClick={() => { navigate("cal", { replace : false, state : { aname : location.state.aname } }) }}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">&nbsp;Calibration Work Order</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>


    <div className="col-sm-3" onClick={() => { navigate("rep", { replace : false, state : { aname : location.state.aname } }) }}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">&nbsp;Repairing Work Order</h5>
          {/* <p className="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a> */}
        </div>
      </div>
    </div>

  </div>
</div>




        </div>
    )
}
export default WorkOrderHome;
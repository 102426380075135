import axios from "axios";
import { createRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { ServerUrl } from "../../../ADAPI";
const MachineWO = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state.aname);
    // Select var for React Select
    const SeleOptions = [{ value : "YES", label : "YES" }, { value : "NO", label : "NO" }, { value : "NA", label : "NA" }];


// Variable To Sore Values
const equipname = createRef();
const equipcapacity = createRef();

const equipmodel = createRef();
const insidedim = createRef();
const overalldim = createRef();
const temprange = createRef();
const tempacc = createRef();
const tempuni = createRef();
const humrange = createRef();
const humacc = createRef();
const humuni = createRef();
const [microproces_based_control_sys, setmicroproces_based_control_sys] = useState("NA");
const [printer_interface_facility, setprinter_interface_facility] = useState("NA");
const [microprocess_based_Safety_control_sys, setmicroprocess_based_Safety_control_sys] = useState("NA");
const [cfr_21_soft_compilance, setcfr_21_soft_compilance] = useState("NA");
const [cfr_21_compliance_scanner, setcfr_21_compliance_scanner] = useState("NA");
const [safety_thermostat, setsafety_thermostat] = useState("NA");
const [plc_based_system, setplc_based_system] = useState("NA");
const [gsm_system, setgsm_system] = useState("NA");
const scanner = createRef();
const [timer, settimer] = useState("NA");
const [lux_meter_uv_meter, setlux_meter_uv_meter] = useState("NA");
const [lux_meter_uv_printer_interface, setlux_meter_uv_printer_interface] = useState("NA");
const [refrigeration_system, setrefrigeration_system] = useState("NA");
const [sg_system, setsg_system] = useState("NA");
const [sensor, setsensor] = useState("NA");
const [traymocon, settraymocon] = useState("NA");
const [tray_type, settray_type] = useState("NA");
const tray_quant = createRef();
const doumentation = createRef();
const otherifany = createRef();
const special_instru = createRef();
const target_date = createRef();
const reason_if_any = createRef();

const verify_by = createRef();
const prduction_dept_name = createRef();
const document_dept_name = createRef();
const store_dept_name = createRef();
const service_dept_name = createRef();

const HandleSubmitWorkOrder = (event) => { 
    event.preventDefault();
    if (Boolean(equipname.current.value)) {
        
        const formdata = new FormData();
        formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("equipname",equipname.current.value);
        formdata.append("capacity",equipcapacity.current.value);
        formdata.append("model",equipmodel.current.value);
        formdata.append("inside",insidedim.current.value);
        formdata.append("oversize",overalldim.current.value);
        formdata.append("temprange",temprange.current.value);
        formdata.append("taccuracy",tempacc.current.value);
        formdata.append("tuniformity",tempuni.current.value);
        formdata.append("humrange",humrange.current.value);
        formdata.append("haccuracy",humacc.current.value);
        formdata.append("huniformity",humuni.current.value);
        formdata.append("microproces_based_control_sys",microproces_based_control_sys);
        formdata.append("printer_interface_fac",printer_interface_facility);
        formdata.append("microprocess_based_Safety_control",microprocess_based_Safety_control_sys);
        formdata.append("cfr_21_soft_compilance",cfr_21_soft_compilance);
        formdata.append("cfr_21_compliance_scanner",cfr_21_compliance_scanner);
        formdata.append("safety_thermostat",safety_thermostat);
        formdata.append("plc_based_system",plc_based_system);
        formdata.append("gsm_system",gsm_system);
        formdata.append("scanner",scanner.current.value);
        formdata.append("timer",timer);
        formdata.append("lux_meter_uv_meter",lux_meter_uv_meter);
        formdata.append("lux_meter_uv_printer_interface",lux_meter_uv_printer_interface);
        formdata.append("refrigeration_system",refrigeration_system);
        formdata.append("sg_system",sg_system);
        formdata.append("sensor",sensor);
        formdata.append("traymoc",traymocon);
        formdata.append("tray_type",tray_type);
        formdata.append("tray_quant",tray_quant.current.value);
        formdata.append("doumentation",doumentation.current.value);
        formdata.append("otherifany",otherifany.current.value);
        formdata.append("special_instru",special_instru.current.value);
        formdata.append("target_date",target_date.current.value);
        formdata.append("reason_if_any",reason_if_any.current.value);
        formdata.append("prep_by",location.state.aname);
        formdata.append("verify_by",verify_by.current.value);
        formdata.append("prduction_dept_name",prduction_dept_name.current.value);
        formdata.append("document_dept_name",document_dept_name.current.value);
        formdata.append("store_dept_name",store_dept_name.current.value);
        formdata.append("service_dept_name",service_dept_name.current.value);
        for (var pair of formdata.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        axios.post(ServerUrl+"aderp/admin/machineworkorder/createworkorder.php", formdata).then(response =>{
            if(response.data === "done"){
                alert("Work Order Created Successfully");
                window.location.reload();
            }
            else if (response.data === "Failed") {
                console.log(response.data);
            }else{
                console.log(response.data);
            }
        }).catch(error =>{ console.log(error) })
        


    }else{
        alert("Please Enter Equipment Name");
        
    }
   
}







    return (
        <div>
           
                 <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

{/* Main Body */}

<div className="container">

<button className="btn btn-success" onClick={()=>{ 
     navigate("list", { replace: false, state : { 
        aname : location.state.aname
     } })
}}>List</button>
<hr/>

  <form onSubmit={HandleSubmitWorkOrder}>      
        <div className="container">
            <div className="row">
                
                {/* First Column */}
                
                <div className="col-sm">
                        <label>Equipment Name</label>
                        <input type="text" ref={equipname} className="form form-control" placeholder="Enter Equipment Name"  />
                        <label>Equipment Capacity</label>
                        <input type="text" 
                        ref={equipcapacity}
                        className="form form-control" placeholder="Enter Equipment Capacity" />
                        <label>Enter Equipment Model</label>
                        <input type="text" 
                        ref={equipmodel}
                        className="form form-control" placeholder="Enter Equipment Model" />
                        <label>Inside Dimensions</label>
                        <input type="text" 
                        ref={insidedim}
                        className="form form-control" placeholder="Enter Inside Dimensions" />
                        <label>Over All Dimensions</label>
                        <input type="text" 
                        ref={overalldim}
                        className="form form-control" placeholder="Enter Over All Dimensions" />
                        <label>Temperature Range</label>
                        <input type="text"
                        ref={temprange}
                        className="form form-control" placeholder="Enter Temperature Range" />
                        <label>Temperature Accuracy</label>
                        <input type="text" 
                        ref={tempacc}
                        className="form form-control" placeholder="Enter Temperature Accuracy" />
                        <label>Temperature Uniformity</label>
                        <input type="text"
                        ref={tempuni}
                        className="form form-control" placeholder="Enter Temperature Uniformity" />
                        <label>Humidity Range</label>
                        <input type="text" 
                        ref={humrange}
                        className="form form-control" placeholder="Enter Humidity Range" />
                        <label>Humidity Accuracy</label>
                        <input type="text" 
                        ref={humacc}
                        className="form form-control" placeholder="Enter Humidity Accuracy" />
                        <label>Humidity Uniformity</label>
                        <input type="text" 
                        ref={humuni}
                        className="form form-control" placeholder="Enter Humidity Uniformity" />
                        <label>Microproces Based Control System</label>
                        <Select options={SeleOptions} onChange={(newValue)=>{ 
                            setmicroproces_based_control_sys(newValue.value);
                         }}   placeholder="Select Microproces Based Control System" />

                </div>
                
                {/* Second Column */}
                
                <div className="col-sm">

                        <label>Printer Interface Facility</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setprinter_interface_facility(newValue.value);
                        }}
                        placeholder="Select Printer Interface Facility" />
                        <label>Microprocess Based Safety Control</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setmicroprocess_based_Safety_control_sys(newValue.value);
                        }}
                        placeholder="Select Microprocess Based Safety Control" />
                        <label>CFR 21 Soft Compilance</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setcfr_21_soft_compilance(newValue.value);
                        }}
                        placeholder="Select CFR 21 Soft Compilance" />
                        <label>CFR 21 Compliance Scanner</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setcfr_21_compliance_scanner(newValue.value);
                        }}
                        placeholder="Select CFR 21 Compliance Scanner" />
                        <label>Safety Thermostat</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setsafety_thermostat(newValue.value);
                        }}
                        placeholder="Select Safety Thermostat" />
                        <label>PLC Based System</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setplc_based_system(newValue.value);
                        }}
                        placeholder="Select PLC Based System" />
                        <label>GSM System</label>
                        <Select options={SeleOptions} placeholder="Select GSM System" 
                        onChange={(newValue)=>{
                            setgsm_system(newValue.value);
                        }}
                        />
                        <label>Scanner</label>
                        <input type="text" className="form form-control" ref={scanner}  placeholder="Enter Scanner" />
                        <label>Timer</label>
                        <Select options={SeleOptions} placeholder="Select Timer"
                        onChange={(newValue)=>{
                            settimer(newValue.value);
                        }}
                        />
                        
                <label>Lux Meter UV Meter</label>
                        <Select options={SeleOptions}
                        onChange={(newValue)=>{
                            setlux_meter_uv_meter(newValue.value);
                        }}
                        placeholder="Select Lux Meter UV Meter" />

                </div>
                
                {/* Third Column */}
                
                <div className="col-sm">

                        <label>Lux Meter Printer Interface</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setlux_meter_uv_printer_interface(newValue.value);
                        }}
                        placeholder="Select Lux Meter Printer Interface" />
                        <label>Refrigeration System</label>
                        <Select options={SeleOptions} 
                        onChange={(newValue)=>{
                            setrefrigeration_system(newValue.value);
                        }}
                        placeholder="Select Refrigeration System" />
                        <label>S. G. System </label>
                        <Select options={SeleOptions}
                        onChange={(newValue)=>{
                            setsg_system(newValue.value);
                        }}
                        placeholder="Select S. G. System" />
                        <label>Sensor</label>
                        <Select options={SeleOptions}
                        onChange={(newValue)=>{
                            setsensor(newValue.value);
                        }}
                        placeholder="Select Sensor" />
                        <label>Tray MOC</label>
                        <Select options={[{label:'S.S.304', value : 'S.S.304'},
                        {label:'S.S.316', value : 'S.S.316'},{label:'NA', value : 'NA'}
                    ]}
                    onChange={(newValue)=>{
                        settraymocon(newValue.value);
                    }}
                    placeholder="Select Tray MOC" />
                        <label>Tray Type</label>
                        <Select options={[{label:'Perforated', value : 'Perforated'},{label:'wire mesh', value : 'wire mesh'},{label:'NA', value : 'NA'}]} 
                        onChange={(newValue)=>{
                            settray_type(newValue.value);
                        }}
                        placeholder="Select Tray Type" />
                        <label>Tray Quantity</label>
                        <input type="text" ref={tray_quant} className="form form-control" placeholder="Enter Tray Quantity" />
                        <label>Documentation</label>
                        <textarea ref={doumentation} className="form form-control" placeholder="Enter Documentation" defaultValue="Protocol for IQ, PQ, DQ, OQ Documents will be provide.Calibration and Validation for 8 hrs with load & 8 hrs without load at one set point will be carried out.Any Extra calibration will be carried out with 8 probes for temperature & 8 probes for RH.PLC and software validation will be carried out.Training and handover of documents."></textarea>
                        <label>Other Option If Any</label>
                        <textarea ref={otherifany} className="form form-control" placeholder="Enter Other Option If Any"></textarea>
                        <label>Special Instruction</label>
                        <textarea ref={special_instru} className="form form-control" placeholder="Enter Special Instruction" defaultValue="TARGET DATE IS ALREADY COMMITED TO CLIENT"></textarea>
                </div>
                <div className="col-sm">

                        <label>Target Date</label>
                        <input ref={target_date} type="date" className="form form-control" placeholder="Enter Target Date" defaultValue={" "} />
                        {/* <label>Complete Date</label>
                        <input type="date" className="form form-control" 
                        ref={complete_date}
                        placeholder="Enter Complete Date" defaultValue={" "} /> */}
                        {/* <label>Dispatch Date</label>
                        <input type="date" className="form form-control"
                        ref={dispatch_date}
                        placeholder="Enter Dispatch Date" defaultValue={" "} /> */}
                        <label>Reason if Delay</label>
                        <input type="text" 
                        ref={reason_if_any}
                        className="form form-control" placeholder="Enter Reason if Delay" />
                       
                        <label>Verify By</label>
                        <input type="text" 
                        ref={verify_by}
                        className="form form-control" placeholder="Enter Verify By" />
                        <strong>CC&nbsp;</strong>
                        <label>Production Department Person Name</label>
                        <input type="text" 
                        ref={prduction_dept_name}
                        className="form form-control" placeholder="Enter Production Department Person Name" />
                        <strong>CC&nbsp;</strong>
                        <label>Document Department Person Name</label>
                        <input type="text" 
                        ref={document_dept_name}
                        className="form form-control" placeholder="Enter Document Department Person Name" />
                        <strong>CC&nbsp;</strong>
                        <label>Store Department Person Name</label>
                        <input type="text" 
                        ref={store_dept_name}
                        className="form form-control" placeholder="Enter Store Department Person Name" />
                        <strong>CC&nbsp;</strong>
                        <label>Service Department Person Name</label>
                        <input type="text" 
                        ref={service_dept_name}
                        className="form form-control" placeholder="Enter Service Department Person Name" />
<br/>
                        <button type="submit" className="btn btn-primary">Generate</button> &nbsp;
                        <button type="reset" className="btn btn-danger">Reset</button>
                </div>

            </div>
        </div>
   </form>


</div>
        </div>
    );
}

/*

$equipname = $_POST['equipname'];
        $capacity = $_POST['capacity'];
        $model = $_POST['model'];
        $inside = $_POST['inside'];
        $oversize = $_POST['oversize'];
        $temprange = $_POST['temprange'];
        $taccuracy = $_POST['taccuracy'];
        $tuniformity = $_POST['tuniformity'];
        $humrange = $_POST['humrange'];
        $haccuracy = $_POST['haccuracy'];
        $huniformity = $_POST['huniformity'];
        $microproces_based_control_sys = $_POST['microproces_based_control_sys'];
        $printer_interface_fac = $_POST['printer_interface_fac'];
        $microprocess_based_Safety_control = $_POST['microprocess_based_Safety_control'];
        $cfr_21_soft_compilance = $_POST['cfr_21_soft_compilance'];
        $cfr_21_compliance_scanner = $_POST['cfr_21_compliance_scanner'];
        $safety_thermostat = $_POST['safety_thermostat'];
        $plc_based_system = $_POST['plc_based_system'];
        $gsm_system = $_POST['gsm_system'];
        $scanner = $_POST['scanner'];
        $timer = $_POST['timer'];
        $lux_meter_uv_meter = $_POST['lux_meter_uv_meter'];
        $lux_meter_uv_printer_interface = $_POST['lux_meter_uv_printer_interface'];
        $refrigeration_system = $_POST['refrigeration_system'];
        $sg_system = $_POST['sg_system'];
        $sensor = $_POST['sensor'];
        $traymoc = $_POST['traymoc'];
        $tray_type = $_POST['tray_type'];
        $tray_quant = $_POST['tray_quant'];
        $doumentation = $_POST['doumentation'];
        $otherifany = $_POST['otherifany'];
        $special_instru = $_POST['special_instru'];
        $target_date = $_POST['target_date'];
        $complete_date = $_POST['complete_date'];
        $dispatch_date = $_POST['dispatch_date'];
        $reason_if_any = $_POST['reason_if_any'];
        $prep_by = $_POST['prep_by'];
        $verify_by = $_POST['verify_by'];
        $prduction_dept_name = $_POST['prduction_dept_name'];
        $document_dept_name = $_POST['document_dept_name'];
        $store_dept_name = $_POST['store_dept_name'];
        $service_dept_name = $_POST['service_dept_name'];

*/

export default MachineWO;
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import adlogo from "./logo.png"; 
import "./ViewS.css";
import { ServerUrl } from "../../../ADAPI";
import  html2pdf  from "html2pdf.js";
const Viewamccal =() => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
  let total=0;
  let count=1;



    const location = useLocation();
    const sno = location.state.sno;
    const csno = location.state.csno;
    const [oldQuotData, newQuotData] = useState([]);
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", sno);
         axios.post(ServerUrl+"aderp/admin/quot/getquotlistsno.php", formdata).then(response => { 
            newQuotData(response.data);
        }).catch(err => { console.log(err) })
    }, []);

//Product Array From Quot
const dataProducts = oldQuotData.map(data => { return JSON.parse(data.aquotprod) });



    // Get Client Data By Name
    // const clientSno = oldQuotData.map(data => { return  data.aclient })[0];
    // GetClientDetails
    const [oldClientData, newClientData] = useState([]);
    useEffect(()=>{ 
        const formdata = new FormData();
        formdata.append("sno", csno);
        axios.post(ServerUrl+"aderp/admin/GetClientsSno.php", formdata).then(response => {
            newClientData(response.data);
          //  console.log(response.data);
        }).catch(err => { console.log(err) })
    },[]);


    const discountAmm = oldQuotData.map(data=>{ return data.adiscount});



    // Get Products Terms And Condition by Sno
    // getProClientSno.phpAPI NAME

// Product Sno Extracted
    
let psnof =  dataProducts.map((data, index) => { 
  if(index===0){
    return data[0].sno;
  }
});

console.log(psnof[0]);
// getProClientSno.php
const [oldProDataTerms, newProDataterms] = useState([]);


useEffect(()=>{
  if(!isNaN(psnof[0])){
    const formdata = new FormData();
    formdata.append('api_key', "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formdata.append('sno', psnof[0]);
    axios.post(ServerUrl+"aderp/admin/quot/getProClientSno.php", formdata).then(response=>{
      console.log(response.data);
      newProDataterms(response.data);
    })
}

},[psnof[0]])


const dataArray = oldProDataTerms.map(data => { 
  return data.aprodtandc.split("-");
});
console.log(dataArray);

const [oldArrDataSwap, newArrDataSwap] = useState([]);
useEffect(()=>{
  dataArray.map(
    (data,index)=> { 
      newArrDataSwap(data);
      
  
    }
   )
},[oldProDataTerms])

const myTitle = document.getElementById("swaptitle");
myTitle.innerHTML = "APEPL-AMC-"+new Date(oldQuotData.map(data => {
  return data.adate })).getFullYear()+"-"+new Date(oldQuotData.map(data => { return data.adate })).getMonth()+1+"-0"+sno;
// Send PDF File

const printDocument = async () => {


  let opt = {
    margin:       5,
    filename:     'myfile.pdf',
    // image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 3 },
    jsPDF:        { orientation : 'p', format: 'A4', margin:20 },
    mode:['css', 'legacy']
  };  
  const el = document.getElementById('popdf');
  const d = html2pdf().set( opt ).from( el ).toPdf().output('datauristring').then(
    resultdata => { 
      // console.log(resultdata)
      // const formData = new FormData();
      // formData.append('file', resultdata);
      // formData.append('avemail', oldPOList.map(data=> { return data.avemail }));
      const dataObj = { file: resultdata, avemail: oldClientData.map(data=> { return data.apurchaseemail }) ,
      recname: oldClientData.map(data=> { return data.acontactperson }),
      qono:"APEPL-AMC-"+new Date(oldQuotData.map(data => { return data.adate })).getFullYear()+"-"+new Date(oldQuotData.map(data => { return data.adate })).getMonth()+1+"-0"+sno
    }

    console.log(dataObj);
      axios.post("https://walkinstabilitychamber.in/aderpmailapi/qomail.php", dataObj).then(response=>{ alert("Mail Sent Successfully") }).catch(err=>{console.log(err)})
      
    }
  );

 
}




return <>
<div id="popdf">
    <div className="container-fluid" style={{"fontFamily":"Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", "fontSize":"15px"}}>
<img src={adlogo} height={70} />
<hr style={{"border":"solid 0.6px blue"}} />
Dear Sir, 
<br />
<br />

<p>We are pleased to introduce ourselves as reliable suppliers of quality equipment’s to Pharmaceuticals, Electronics, Chemical
Plants, Universities and Petro - Chem. Industries, Research Institutes & Other industries. Also Annual Maintainance Contract
and Mapping for other Make.</p>

<p>
The main aim of company is to provide the good quality of equipment and services to Pharmaceuticals & Laboratory
equipment’s.
</p>
<p>With reference to our discussion regarding the requirement at your end, we are pleased to provide you with our commercial
offer for the same.</p>
<p>
Please refer the Technical Specifications and Commercials as mentioned as enclosed here with.
</p>
<p>What <strong>AD Pharma</strong> Provide:</p>
<ul>
    <li>Annual Maintenance Contracts for All Kinds of Chamber - Comprehensive and Semi-Comprehensive.</li>
    <li>Up gradation of PLC Control Systems and Components.</li>
    <li>Allows your staff to focus on higher priority operational issues.</li>
    <li>Resolve minor problems by one phone call.</li>
    <li>Strong team of qualified and experienced engineers.</li>
    <li>Proactive and Reactive service structure for our products.</li>
    <li>Calibration and Validation of all kinds of equipment.</li>
</ul>
<p>Our products include:-</p>
<ul>
    <li>Walk-in Stability Chamber / Humidity Chamber</li>
    <li>Walk-in BOD Incubator / Cooling Incubator</li>
    <li>Walk-in Cold Chamber / Cooling Cabinet</li>
    <li>Stability Chamber / Humidity Chamber</li>
    <li>BOD Incubator / Cooling Incubator</li>
    <li>Cold Chamber / Cooling Cabinet</li>
    <li>Photo Stability Chamber</li>
    <li>Incubator</li>
    <li>Deep Freezer</li>
    <li>Vacuum Oven</li>
    <li>Lab Oven / Hot Air Oven</li>
</ul>
<strong className="centerss"><i>QUOTATION FOR AMC AND CALIBRATION</i></strong>

{/* Table One */}
<>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:12px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:12px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      {/* <colgroup>
        <col style={{ width: "118.75px" }} />
        <col style={{ width: "182.75px" }} />
        <col style={{ width: "105.75px" }} />
        <col style={{ width: "184.75px" }} />
      </colgroup> */}
        <colgroup>
    <col style={{ width: "80.75px" }} />
    <col style={{ width: "182.75px" }} />
    <col style={{ width: "94.75px" }} />
    <col style={{ width: "184.75px" }} />
  </colgroup>
      <tbody>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>CUSTOMER</span>
          </td>
          <td className="tg-0lax" >{oldClientData.map(data  => { return data.acompanyname })}</td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>DATE</span>
          </td>
          <td className="tg-0lax" >{oldQuotData.map(data => { return data.adate })}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>ADDRESS</span>
          </td>
          <td className="tg-0lax" >{oldClientData.map(data  => { return data.aadress })}</td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>QUOTATION NO.</span>
          </td>
          <td className="tg-0lax" >APEPL-AMC-{new Date(oldQuotData.map(data => { return data.adate })).getFullYear()}-{new Date(oldQuotData.map(data => { return data.adate })).getMonth()+1}-0{sno}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>KINDLY ATTN.</span>
          </td>
          <td className="tg-0lax" >{oldClientData.map(data  => { return data.acontactperson })}</td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>PH./MOBILE NO</span>
          </td>
          <td className="tg-0lax" >{oldClientData.map(data  => { return data.amobno })} / {oldClientData.map(data  => { return data.aphoneno })}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>DEPARTMENT</span>
          </td>
          <td className="tg-0lax" />
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>E-MAIL ID</span>
          </td>
          <td className="tg-0lax"> <label onClick={()=>{printDocument()}}>{oldClientData.map(data  => { return data.apurchaseemail })}</label></td>
        </tr>
      </tbody>
    </table>
  </div>
</>

<br />
{/* Table 2 */}
<>
  {/* <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:2px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  /> */}
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      <colgroup>
        <col style={{ width: "118.75px" }} />
        <col style={{ width: "182.75px" }} />
        <col style={{ width: "105.75px" }} />
        <col style={{ width: "184.75px" }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-0lax" colSpan={4} ><strong className="centerss">-: QUOTATION DETAILS:-</strong></td>
        </tr>
      </tbody>
    </table>
  </div>
</>

<br />
{/* Table 3 */}
<>
  {/* <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:2px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n.tg .tg-73oq{border-color:#000000;text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  /> */}
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      {/* <colgroup>
        <col style={{ width: "58.75px" }} />
        <col style={{ width: "133.75px" }} />
        <col style={{ width: "75.75px" }} />
        <col style={{ width: "40.75px" }} />
        <col style={{ width: "55.75px" }} />
        <col style={{ width: "84.75px" }} />
      </colgroup> */}
          <colgroup>
    <col style={{ width: "30.75px" }} />
    <col style={{ width: "133.75px" }} />
    <col style={{ width: "45.75px" }} />
    <col style={{ width: "25.75px" }} />
    <col style={{ width: "45.75px" }} />
    <col style={{ width: "50.75px" }} />
  </colgroup>
      <tbody>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>SR NO</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>AMC AND CALIBRATION Details</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>HSN/SAC</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>QTY</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>RATE</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>TOTAL</span>
          </td>
        </tr>

      {dataProducts.map(data => { 
      return  data.map(adata => { 


      //    console.log(data);
          
       total =total+parseFloat(adata.productquanity*adata.productprice);
        return         <tr>
        <td className="tg-0pky">{count++}</td>
        <td className="tg-0pky">
          
        {adata.productname}
          {/* Vindas-Humidity Chamber */}
          <br />
         {adata.desc}
          {/* Sr. No. 03/08-19 , Sr. No. 04/08-09 */}
        </td>
        <td className="tg-0pky">{adata.hsncode}</td>
        <td className="tg-0pky">{adata.productquanity}</td>
        <td className="tg-0pky">{adata.productprice}</td>
        <td className="tg-0pky">{parseFloat(adata.productquanity*adata.productprice)}</td>
      </tr>

        })
       
       })} 



        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Discount (%)</span>
          </td>
          <td className="tg-0pky">{discountAmm}</td>
        </tr>
        <tr>

          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Total with Discount</span>
          </td>

          <td className="tg-73oq">
            {/* Price after discount = Original Price - (Original price × Discount %) */}
            <span style={{ fontWeight: "bold" }}>{parseFloat(total-(total*(discountAmm/100))).toFixed(2)}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</>
<br />


{/* Table 4 */}
<>
  {/* <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:2px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  /> */}
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      <colgroup>
        <col style={{ width: "118.75px" }} />
        <col style={{ width: "182.75px" }} />
        <col style={{ width: "105.75px" }} />
        <col style={{ width: "184.75px" }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-0lax" colSpan={4} ><strong className="centerss">-: THE TERMS & CONDITIONS OF OUR CONTARCT ARE AS FOLLOWS :-</strong></td>
        </tr>
      </tbody>
    </table>
  </div>
</>

<br/>
{/* <ul>
    <li>The AMC period will be of 1 year and revised after 1 year.</li>
    <li>Our service engineer will visit your organization THREE times annually and unlimited breakdown visit</li>
    <li>During the annual visits, preventive maintenance and necessary repairing work will be undertaken</li>
    <li>This visits will be free of cost however, any spare parts replacement or addition will be charged</li>
    <li>In AMC visit our service engineer will check & clean all the equipment as per checklist that will be duly signed by
you</li>
    <li>If any failure occurs in the equipment by negligence of your employees then we will not bear the cost of repairs or
replacement</li>
    <li>After giving suggestion to your employees for repeated problem occurred, then we are not responsible for further
damage</li>
    <li>Calibration & Validation will be carried out with eight probes only</li>
    <li>Calibration & Validation will be carried out with 04 Hrs Calibration & 24 Hrs Validation at one set point only</li>
    <li>Calibration & Validation at any other set point will be charged as per above rates</li>
    <li>Calibration certificate will be provided on successful completion of calibration</li>
    <li>Calibration will be carried out with calibrator having traceability to ERTL / NABL & required Protocol Document</li>
 

</ul> */}
<ul>
 {oldArrDataSwap.map(data => {
  return <li>&nbsp;&nbsp;{data}</li>
 })} 
  
</ul>
<br />


<>
  {/* <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  /> */}
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      <colgroup>
        <col style={{ width: "585.75px" }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>
              COMMERCIAL TERMS AND CONDITIONS
            </span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>PAYMENT TERMS:-</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            At Par Cheque /D.D. Should In Favor of "
            <span style={{ fontWeight: "bold" }}>
              AD PHARMA EQUIPMENT PVT. LTD.
            </span>
            " Payable at Sinner.
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">Advance - 100% (Basic Cost) With P.O.</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Taxes:-</span>
          </td>
        </tr>
       
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>GST</span> Will Be Charged
            Extra At Appropriate Rates Ruling On The Data Of Supply.
         
            
          </td>
        </tr>

        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Transportation:-</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            Transportation / Freight:- As Actual (If Applicable).
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Validity:-</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            Our Budgetary Offer Shall Remain Valid For The Period Of 10 Days
            From The Date Of Offer.
          </td>
        </tr>
        
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Details:-</span>
          </td>
        </tr>
       
        <tr>
          <td className="tg-0lax">
          <b>COMPANY GSTIN :</b> 27AAPCA3611H1ZQ &nbsp;
          <b>COMPANY PAN :</b> AAPCA3611H
&nbsp;
<b>COMPANY CIN :</b> U29309MH2017PTC292871&nbsp;&nbsp;
<br/>
{/* <b>COMPANY'S BANK DETAILS :</b><br/> */}
<b>Bank A/C Details:</b> State Bank of India
&nbsp;&nbsp;<b>A/C Number :</b> 38254788931
&nbsp;&nbsp;<b>IFSC Code :</b> SBIN0005392
&nbsp;&nbsp;<b>Branch :</b> Sinnar
            
          </td>
        </tr>


      </tbody>
    </table>
  </div>
</>
<br />
<p>We hope the above Terms & Conditions are in line with your requirement & look forward to hear favorably from you soon.</p>
<br />

<div className="rightss">
<b>Thanking You,</b>
<br/>

</div>
<div className="rightss">
Mr. Digamber Pawar
</div>
<div className="rightss">
Cell: +91 9673905522
</div>
<div className="rightss">
M/S.&nbsp;<label style={{"color":"red",}}>AD PHARMA &nbsp;</label> <label style={{"color":"blue",}}>EQUIPMENT PVT. LTD.</label>
<br/>
</div>


<div className="rightss">
E-Mail: <a href="mailto://sales@adpharmaequipment.com"><u>sales@adpharmaequipment.com</u></a>
</div>

<div className="rightss">
Web:<a href="https://adpharmaequipment.com/">www.adpharmaequipment.com</a>
</div>
<br />
<hr style={{"border":"solid 0.6px #00008B"}} />

<div style={{"display":"inline"}}><label style={{"fontSize":"9px"}}>M/S.</label> <label style={{"color":"red","fontSize":"9px"}}>AD PHARMA</label> <label style={{"color":"blue","fontSize":"9px"}}>EQUIPMENT PVT. LTD.</label> &nbsp;<label style={{"fontSize":"9px"}}><b>Regd. Off. /Head Office :</b> Shop No. 05, Dream Art, Mordern Colony, Saradwadi Road, Tal Sinner,Dist. Nashik, Maharashtra , India - 422103</label></div>



{/* <div className="rightss">
Thanking You,
<br/>
<br/>
</div>
<div className="rightss">
For, AD PHARMA EQUIPMENT PVT. LTD.
<br/>
</div>
<div className="rightss">
Mr. Digamber Pawar
</div>
<div className="rightss">
Cell: +91 9673905522
</div>

<div className="rightss">
E-Mail: sales@adpharmaequipment.com
</div>

<div className="rightss">
Web:<a href="https://adpharmaequipment.com/">www.adpharmaequipment.com</a>
</div>
<br />
<hr style={{"border":"solid 0.6px #00008B"}} />

<br />

<div style={{"color":"#006400"}} className="centerss">
AD PHARMA EQUIPMENT PVT. LTD
</div>
<div style={{"color":"#006400"}} className="centerss">

Regd. Off. /Head Office
</div>
<div style={{"color":"#006400"}} className="centerss">

Shop No. 05, Dream Art, Mordern Colony, Saradwadi Road, Tal Sinner,Dist. Nashik, Maharashtra , India - 422103
</div> */}
    </div>
    

</div>

    </>;
}

export default Viewamccal;
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";
import { useEffect, useState } from "react";
import Select from "react-select";
const AMCWO = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();

    
    const [oldTabData, newTabData] = useState([]);
    const SwapDataGetter = () =>  {
        axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newTabData(response.data);
         });
    }
    useEffect(SwapDataGetter,[]);

   const ClientSelectArray = oldTabData.map((item) => { return { label : item.acompanyname, value : item.acompanyname, value2 : item.aadress, value3 : item.acontactperson, value4 : item.amobno, value5 : item.aqcemail } });

    const amcType = [{ label : "COMPREHENSIVE", value : "COMPREHENSIVE" }, { label : "NON-COMPREHENSIVE", value : "NON-COMPREHENSIVE" }]

    const [comapanyname, setcomapanyname] = useState();
    const [address, setaddress] = useState();
    const [contactperson, setcontactperson] = useState();
    const [mobno, setmobno] = useState();
    const [email, setemail] = useState();
// console.log(comapanyname);
// console.log(address);
// console.log(contactperson);
// console.log(mobno);
// console.log(email);
const [puchaseOrderNo, setpuchaseOrderNo] = useState();
const [oldEquipName, setoldEquipName] = useState();
const [oldSrNo, setoldSrNo] = useState();
const [oldQty, setoldQty] = useState();
const [oldSetAMCStartDate, setoldSetAMCStartDate] = useState();

const [oldDueAMCDate, setoldDueAMCDate] = useState();
console.log(oldDueAMCDate);
console.log(oldSetAMCStartDate);
const [oldAMCPlanNoofVisits, setoldAMCPlanNoofVisits] = useState();
const [recievedby, setrecievedby] = useState();
const [serviceDeptPerson, setserviceDeptPerson] = useState();
const [oldamcType, setoldamcType] = useState();

// AMC DETAILS CHANGE
const [ArrayForStore, setArrayForStore] = useState([]);


function getDateAfterOneYearMinusOneDay(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);
  
    // Add one year to the date
    date.setFullYear(date.getFullYear() + 1);
  
    // Subtract one day from the date
    date.setDate(date.getDate() - 1);
  
    // Format the resulting date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }
console.log(oldamcType);    
    const HandleSubmitGenWorkOrder = (event) => {
        event.preventDefault();
        if(Boolean(comapanyname)){
                const formdata = new FormData();
                formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
                formdata.append("companyname", comapanyname);
                formdata.append("caddress", address);
                formdata.append("conatctperson", contactperson);
                formdata.append("mailid", email);
                formdata.append("mobno", mobno);
                formdata.append("purchaseorderno", puchaseOrderNo);

                // formdata.append("equipmentname", oldEquipName);
                // formdata.append("eqsrno", oldSrNo);
                // formdata.append("qty", oldQty);

                formdata.append("prodDetails", JSON.stringify(ArrayForStore));
                formdata.append("amc_type", oldamcType);
                formdata.append("amcstartdate", oldSetAMCStartDate);
                formdata.append("amcenddate", oldDueAMCDate);
                formdata.append("noofvisit", oldAMCPlanNoofVisits);
                formdata.append("service_dept_name", serviceDeptPerson);
                formdata.append("recievedby", recievedby);
                formdata.append("prepby",location.state.aname);

            axios.post(ServerUrl+"aderp/admin/amcworkorder/createworkorder.php", formdata).then(response => {  
                if(response.data === "done"){
                    alert("Work Order Generated");
                    window.location.reload();
                }else{
                    console.log(response.data);
                }
             });
        }

    }




    return (
            <>
                      
                      <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>


<div className="container">

<div className="container">

<button className="btn btn-success" onClick={()=>{ navigate("list", { replace:false, state: { 
     aname: location.state.aname,
} }); }}>List</button>
<br/>
<hr />
<form onSubmit={HandleSubmitGenWorkOrder}>
  <div className="row">
    <div className="col">
        <Select options={ClientSelectArray} onChange={(newValue)=>{
            setcomapanyname(newValue.value);
            setaddress(newValue.value2);
            setcontactperson(newValue.value3);
            setmobno(newValue.value4);
            setemail(newValue.value5);
        }} />
<label>Address</label>
<input type="text" className="form form-control" onChange={(e)=>{ setaddress(e.target.value) }} defaultValue={address} />
<label>Contact Person</label>
<input type="text" className="form form-control" onChange={(e)=>{ setcontactperson(e.target.value) }} defaultValue={contactperson} />
<label>Mobile No</label>
<input type="text" className="form form-control" onChange={(e)=>{ setmobno(e.target.value) }} defaultValue={mobno} />
<label>Email Id</label>
<input type="text" className="form form-control" onChange={(e)=>{ setemail(e.target.value) }} defaultValue={email} />


        <br/>
        <input type="text" className="form form-control" onChange={(e)=>{
            setpuchaseOrderNo(e.target.value);
        }} placeholder="Purchase Order No" />
        <br/>








<input type="text" className="form form-control" onChange={(e)=>{
            setoldEquipName(e.target.value);
        }} placeholder="Equipment Name" value={oldEquipName} />
        <br/>
        <input type="text" className="form form-control" onChange={(e)=>{
            setoldSrNo(e.target.value);
        }} placeholder="Sr No" value={oldSrNo} />
        <br/>

    </div>
    <div className="col">
    <input type="number" className="form form-control" onChange={(e)=>{
            setoldQty(e.target.value);
        }} placeholder="Enter Quntity" value={oldQty} />

        <button className="btn btn-primary" onClick={()=>{
           setArrayForStore([...ArrayForStore, { equipmentname : oldEquipName, eqsrno : oldSrNo, qty : oldQty }])
            setoldEquipName("");
            setoldQty("");
            setoldSrNo("");

        }} type="button">Add</button>
         









        <input type="date" className="form form-control" onChange={(e)=>{
            setoldSetAMCStartDate(e.target.value);
            const date = new Date(e.target.value);
            setoldDueAMCDate(getDateAfterOneYearMinusOneDay(date));
        }} placeholder="Sr No" />
        <br/>
        <label>AMC Due on <strong>{oldDueAMCDate}</strong></label>
        <br/>

        <br/>
        <label>Enter No of Visits</label>
        <input type="number" className="form form-control" onChange={(e)=>{
            setoldAMCPlanNoofVisits(e.target.value);
        }} /><br/>
        <label>Select AMC Type</label>
        <Select options={amcType} onChange={(newValue)=>{
            setoldamcType(newValue.value);
        }} />

        <label>Recieved By</label>
        <input type="text" onChange={(e)=>{ 
            setrecievedby(e.target.value);
        }} className="form form-control" placeholder="Recieved By" />
        <br/>

        <label><strong>CC</strong> Service Dept. </label>
        <input type="text" className="form form-control" 
        onChange={(e)=>{
            setserviceDeptPerson(e.target.value);
        }} placeholder="Service Deptatrtment Person" />
        <br/>
        <button className="btn btn-primary" type="submit">Generate Work Order </button> &nbsp;
        <button className="btn btn-danger" type="reset">Reset</button>
    </div>
    {/* <div className="col">
        
    </div> */}
  </div>
</form>

</div>
<table className="table table-dark">
                <thead>
                    <tr>
                        <th>Equipment Name</th>
                        <th>Sr No</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>
        {ArrayForStore.map((item)=>{
            return <tr>
                        <td>{item.equipmentname}</td>
                        <td>{item.eqsrno}</td>
                        <td>{item.qty}</td>
                    </tr>
                
        })}
        </tbody>
            </table>

</div>
            </>
    )
}

export default AMCWO;


/*

        $companyname = $_POST['companyname'];
        $caddress = $_POST['caddress'];
        $conatctperson = $_POST['conatctperson'];
        $mailid = $_POST['mailid'];
        $amc_type = $_POST['amc_type'];
        $purchaseorderno = $_POST['purchaseorderno'];
        $equipmentname = $_POST['equipmentname'];
        $eqsrno = $_POST['eqsrno'];
        $qty = $_POST['qty'];
        $amcstartdate = $_POST['amcstartdate'];
        $amcenddate = $_POST['amcenddate'];
        $noofvisit = $_POST['noofvisit'];
        $prepby = $_POST['prepby'];
        $recievedby = $_POST['recievedby'];
        $service_dept_name = $_POST['service_dept_name'];

*/
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";
import adlogo from "./logo.png";
const DeliveryChallan = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
const location = useLocation();
console.log(location.state.sno); 

const [oldDataChallan, newDataChallan] = useState([]);
useEffect(()=>{
  const formdata = new FormData();
  formdata.append("sno", location.state.sno);
  axios.post(ServerUrl+"aderp/user/dc/getchallandata.php", formdata).then(response => 
    {
      newDataChallan(response.data);
    });
},[]);
   console.log(oldDataChallan);

let Swap=[];
let count =0;
let quant = 0;
let nost=0;






//  <thead>
//       <tr>
//         <th className="tg-84g4" rowSpan={2}>
//           <label>&nbsp;&nbsp; </label>
          
//           {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
//           <img src={adlogo} style={{"height":"100px"}}/>
//           </th>
//           <th className="tg-84g4" colSpan={9}>
//             {/* <span style={{ fontWeight: "bold" }}>
//               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AD Pharma Equipment
//               Pvt. Ltd.
//             </span> */}
//           </th>
//         </tr>
//         <tr>
//           <th className="tg-0lax" colSpan={9}>
            
//             <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
//             :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//             <br />
//             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//           </th>
//         </tr>
//       </thead>
  



const currTime = new Date().toLocaleTimeString();
const currDate = new Date().toLocaleDateString();
   return(<>
    

  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;\n  overflow:hidden;padding:5px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:5px 5px;word-break:normal;}\n.tg .tg-84g4{font-size:18px;text-align:center;vertical-align:top}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n.tg .tg-7hap{font-size:20px;font-weight:bold;text-align:left;vertical-align:top}\n"
    }}
  />
  <table className="tg" style={{ tableLayout: "fixed", width: 736 }}>
    <colgroup>
      <col style={{ width: "211.005682px" }} />
      <col style={{ width: "85.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
      <col style={{ width: "55.005682px" }} />
    </colgroup>





        
    <tbody>
      <tr>
        <td className="tg-7hap" colSpan={10}>
        <img src={adlogo} style={{"height":"45px"}}/>
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> DELIVERY CHALLAN</span>
          <br/>
        <h6>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{oldDataChallan.map(data=>{ return data.goodtype })}</h6>
        </td>
      </tr>

      <tr>
        <td className="tg-7hap" colSpan={10}>
          <h6>
            {/* Challan Data */}
             <strong>DC No.</strong> {"  APEPL/DC/00"+location.state.sno}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <strong>Date.</strong>{oldDataChallan.map(data=>{return " "+new Date(data.adate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })})}
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <strong>P.O No.</strong>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;

   <strong>Date .</strong>
          </h6>
        </td>
      </tr>


      <tr>
        <td className="tg-0lax" colSpan={2} rowSpan={4}>
         <strong>To / Consignee.</strong>  {oldDataChallan.map(data=>{return <><br/>{data.clientdeta}</>})}
          <br/>
          
        </td>
        <td className="tg-0lax" colSpan={8} rowSpan={4}>
        
{/* 

 <strong> DC
          No.</strong>{"  APEPL/DC/00"+location.state.sno}<br/>
          
          <br/><strong>Date.</strong>{oldDataChallan.map(data=>{return " "+data.adate})}
          <br />
          <br />
          <strong>P.O No.</strong>
          <br />
          <br />
          <strong>Date .</strong><br />
*/}
<strong>From / Consignor.</strong> &nbsp; 
         <strong> AD Pharma Equipment Pvt. Ltd.</strong>{" "}<br/>
          Regd./Co.op Office: Shop No-05 Dream Art, Modern Colony, Saradwadi Road,{" "}
          <br />
          Tal Sinnar, Dist Nashik, Maharashtra, India 422103. +91 9673905522. +91 9673915522.<br/>
          Email : sales@adpharmaequipment.com {" "} Website : www.adpharmaequiment.com
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
    </tbody>
  </table>
  <table className="tg" style={{ tableLayout: "fixed", width: 737 }}>
  <colgroup>
    <col style={{ width: "31.005682px" }} />
    <col style={{ width: "21.005682px" }} />
    <col style={{ width: "30.005682px" }} />
    <col style={{ width: "30.005682px" }} />
    <col style={{ width: "30.005682px" }} />
    <col style={{ width: "30.005682px" }} />
    <col style={{ width: "30.005682px" }} />
    <col style={{ width: "415.005682px" }} />
    <col style={{ width: "39.005682px" }} />
    <col style={{ width: "45.005682px" }} />
  </colgroup>
  <thead>
    <tr>
      <th className="tg-nx8p" colSpan={2}>
       <strong> Sr. No</strong>
      </th>
      <th className="tg-nx8p" colSpan={6}>
        <strong>Material Desc / ID No</strong>
      </th>
      <th className="tg-nx8p" colSpan={1}>
        <strong>Qty</strong>
      </th>
      <th className="tg-nx8p" colSpan={1}>
        <strong>PKT/<br/>Wt.Kg</strong>
      </th>
    </tr>
  </thead>
  <tbody>

    {oldDataChallan.map(data=>{
       Swap = JSON.parse(data.materialdata);
    })}

    {Swap.map(data=>{
      count++;
      quant= parseInt(quant)+parseInt(data.quantity);
      nost = parseInt(nost)+parseInt(data.nos);
      const SwapStr = data.name;
      let SwapArr = [];
      SwapArr=SwapStr.split("-")
      const a = <br/>;
      return    <tr>
      <td className="tg-0lax" colSpan={2} >{count}</td>
      <td className="tg-0lax" colSpan={6} >
            <ol style={{"listStyle":"none"}}>
              {SwapArr.map((data, index)=> { 
                let atb = {"fontWeight":"normal"};
                if(index===0){
                  atb={"fontWeight":""}
                }
                return <li><b style={atb}>{data}</b></li>
              })}
            </ol>
      </td>
      <td className="tg-0lax" colSpan={1} >{data.quantity}</td>
      <td className="tg-0lax" colSpan={1} >{data.nos}</td>
    </tr>
    })}


    {/* <tr>
      <td className="tg-0lax" colSpan={2} />
      <td className="tg-0lax" colSpan={6} />
      <td className="tg-0lax" colSpan={2} />
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={2} />
      <td className="tg-0lax" colSpan={6} />
      <td className="tg-0lax" colSpan={2} />
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={2} />
      <td className="tg-0lax" colSpan={6} />
      <td className="tg-0lax" colSpan={2} />
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={2} />
      <td className="tg-0lax" colSpan={6} />
      <td className="tg-0lax" colSpan={2} />
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={2} />
      <td className="tg-0lax" colSpan={6} />
      <td className="tg-0lax" colSpan={2} />
    </tr>*/}
    <tr>
      <td className="tg-0lax" colSpan={2} ><strong>Total</strong></td>
      <td className="tg-0lax" colSpan={6} ></td>
      <td className="tg-0lax" colSpan={1} ><strong>{quant}</strong></td>
      <td className="tg-0lax" colSpan={1} ><strong>{nost}</strong></td>

    </tr> 
  </tbody>
</table>

<table className="tg" style={{ tableLayout: "fixed", width: 737 }}>
  <colgroup>
    <col style={{ width: "41.005682px" }} />
    <col style={{ width: "59.005682px" }} />
    <col style={{ width: "78.005682px" }} />
    <col style={{ width: "58.005682px" }} />
    <col style={{ width: "25.005682px" }} />
    <col style={{ width: "25.005682px" }} />
    <col style={{ width: "43.005682px" }} />
    <col style={{ width: "25.005682px" }} />
    <col style={{ width: "42.005682px" }} />
    <col style={{ width: "46.005682px" }} />
  </colgroup>
  <thead>
    <tr>
      <th className="tg-z9od" colSpan={10}>
        Remark :
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        Returnable within no of Days: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Initiating Dept :{" "}
      </td>
    </tr>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        Purpose for Sent :{" "}
      </td>
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={10}>
        <span style={{ fontWeight: "bold" }}>
          Transporter's Courier Details :{" "}
        </span>
      </td>
    </tr>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        Transporter :
      </td>
    </tr>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        Vehicle No :{" "}
      </td>
    </tr>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        LR No:. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LR Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Docket No.{" "}
      </td>
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={10}>
        <span style={{ fontWeight: "bold" }}>Consigner GSTIN</span> :
        27AAPCA3611H1ZQ
      </td>
    </tr>
    <tr>
      <td className="tg-z9od" colSpan={10}>
        Amount In Word :{" "}
      </td>
    </tr>
    <tr>
      <td className="tg-0lax" colSpan={10}>
        {" "}
        Generated By: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Received By:
        <br />
        {oldDataChallan.map(data=>{return " "+data.generatedby})}
        <br />
        {currDate}
  &nbsp;
        {currTime}      

        <br />
        <br /> 
        <br />
       
        For AD Pharma Equipment Pvt. Ltd. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seal &amp; Sign
      </td>
    </tr>
  </tbody>
</table>





    
    </>)
}

export default DeliveryChallan;
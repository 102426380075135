import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import  { ServerUrl } from "../../../ADAPI";
const CalList = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
const [oldList, setOldList] = useState([]);
useEffect(()=>{
    axios.get(ServerUrl+"aderp/admin/calworkorder/getlist.php").then(reponse =>{
        setOldList(reponse.data);
    })
},[])

    return <>
                             <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

<div className="container">

    <table className="table table-dark">
        <thead>
        <tr>
            <th>Sr No</th>
            <th>Company Name</th>
            <th>Date</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
                
                {oldList.map(data=>{
                    return <tr>
                        <td>{data.sno}</td>
                        <td>{data.comapnyname}</td>
                        <td>{data.adate}</td>
                        <td><button onClick={()=>{ navigate("pdf", {replace:false, state:{sno:data.sno, aname:location.state.aname}}) }} className="btn btn-success">Print</button></td>
                    </tr>
                }).reverse()}

        </tbody>
    </table>

</div>
    </>;
}

export default CalList;
import { useState, useEffect,useRef } from "react";

import axios from "axios";
import { ServerUrl } from "../../../ADAPI";

const UpVend = (props) =>  {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const [SwapData, newSwapData] = useState([]);

    const DataGetter = ()=>{
        const formdata = new FormData();
        formdata.append("sno", props.sno);
        axios.post(ServerUrl+"aderp/user/GetVenBySno.php", formdata).then(reponse => {
          newSwapData(reponse.data);
          console.log(reponse.data);
       })
      }
      useEffect(()=>{
            DataGetter();
      },[]);
     
    // const [oldCompName, newCompName] = useState();
    // const [oldAdress, newAdress] = useState();
    // const [oldComMob, newCompMob] = useState();
    // const [oldCompPhone, newCompPhone] = useState();
    // const [oldPurchPers, newPurchPers] = useState();
    // const [oldPurchPerEmail, newPurchPerEmail] = useState();
    // const [oldAccPerName, newAccPerName] = useState();
    // const [oldAccPerEmail, newAccPerEmail]= useState();
    
    // const [oldContPers, newContPers] = useState();
    // const [oldCountryName, newCountryName] = useState();
    // const [oldStateName, newStateName] = useState();
    // const [oldPinCode, newPinCode] = useState();
    // const [oldGSTIN, newGSTIN] = useState();

    const oldAdress = useRef();
    const oldComMob = useRef();
    const oldCompPhone = useRef();
    const oldPurchPers = useRef();
    const oldPurchPerEmail = useRef();
    const oldAccPerName = useRef();
    const oldAccPerEmail = useRef();
    const oldContPers = useRef();
    const oldCountryName = useRef();
    const oldStateName = useRef();
    const oldPinCode = useRef();
    const oldGSTIN = useRef();




    const HandleSubmit = (event)=>{
        event.preventDefault();

       
        if(Boolean(oldAdress.current.value) && Boolean(oldComMob.current.value) && Boolean(oldCompPhone.current.value) && Boolean(oldPurchPers.current.value) && Boolean(oldPurchPerEmail.current.value) && Boolean(oldAccPerName.current.value) && Boolean(oldAccPerEmail.current.value) &&  Boolean(oldContPers.current.value) && Boolean(oldCountryName.current.value) && Boolean(oldStateName.current.value) && Boolean(oldPinCode.current.value) && Boolean(oldGSTIN.current.value)){
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append('sno', props.sno);
            formdata.append('aadress', oldAdress.current.value);
            formdata.append('amobno', oldComMob.current.value);
            formdata.append('aphoneno', oldCompPhone.current.value);
            formdata.append('aemail', oldPurchPerEmail.current.value);
            formdata.append('apurchaseperson', oldPurchPers.current.value);
            formdata.append('apurchasepersonemail', oldPurchPerEmail.current.value);
            formdata.append('aaccountperson', oldAccPerName.current.value);
            formdata.append('aacountemail', oldAccPerEmail.current.value);
            formdata.append('acontactperson', oldContPers.current.value);
            formdata.append('acountry', oldCountryName.current.value);
            formdata.append('astate', oldStateName.current.value);
            formdata.append('apincode', oldPinCode.current.value);
            formdata.append('agstinno', oldGSTIN.current.value);
            axios.post(ServerUrl+"aderp/user/UpdVendor.php", formdata).then(
                response => { alert(response.data);window.location.reload(); }
          )

        }else {
            alert("All Fields Required");
        }
    }

    return<>
    

    <div className="container">
    <form onSubmit={HandleSubmit}>
        <label>Updating Vendor :  {SwapData.map(data=>{return data.acompanyname})}</label>
        <input type="text" ref={oldAdress} className="form form-control" placeholder="Enter Company Adress" defaultValue={SwapData.map(data=>{ return data.aadress; })} />

        <input type="text" ref={oldComMob} maxLength="10" className="form form-control" placeholder="Enter Company Mobile NO"
        defaultValue={SwapData.map(data=>{ return data.amobno; })}
        
        />
        
        
         <input type="text" ref={oldCompPhone} className="form form-control" placeholder="Enter Company Phone No" 
         defaultValue={SwapData.map(data=>{ return data.aphoneno; })}
         />
        <input type="text" ref={oldPurchPers}  className="form form-control" placeholder="Purchase Person Name"
        defaultValue={SwapData.map(data=>{ return data.apurchaseperson; })}
        />

        <input type="email" ref={oldPurchPerEmail}  className="form form-control" placeholder="Purchase Person Email"
        defaultValue={SwapData.map(data=>{ return data.apurchaseemail; })}
        
        />


        <input type="text" ref={oldAccPerName}  className="form form-control" placeholder="Account Person Name" 
        defaultValue={SwapData.map(data=>{ return data.aaccountperson; })}
        />
        <input type="email" ref={oldAccPerEmail}  className="form form-control" placeholder="Account Person Email" 
        defaultValue={SwapData.map(data=>{ return data.aacountemail; })}
        />


        <input type="text" ref={oldContPers}  className="form form-control" placeholder="Contact Person" 
        defaultValue={SwapData.map(data=>{ return data.acontactperson; })}
        />

        <input type="text" ref={oldCountryName}  className="form form-control" placeholder="Country Name"
        defaultValue={SwapData.map(data=>{ return data.acountry; })}
        />


        <input type="text" ref={oldStateName}  className="form form-control" placeholder="State Name"
        defaultValue={SwapData.map(data=>{ return data.astate; })}
        />


        <input type="text" ref={oldPinCode}  className="form form-control" placeholder="Pin Code"
        defaultValue={SwapData.map(data=>{ return data.apincode; })}
        />


        <input type="text" ref={oldGSTIN} maxLength={15} minLength={15}  className="form form-control" placeholder="GSTIN No"
        defaultValue={SwapData.map(data=>{ return data.agstinno; })}
        /> 


        <button type="submit" className="btn btn-primary">Update Vendor</button>&nbsp;<button type="reset" className="btn btn-danger"> Clear</button>

        

    </form> 
    
    
    </div>
    
    
    
    
    
    </>
}

export default UpVend;
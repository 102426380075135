import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import axios from "axios";

const ReparWoList = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    const [oldData, newData] = useState([]);
    useEffect(() => {
        axios.get(ServerUrl+"aderp/admin/repairworkorder/getlist.php").then(response => {
            newData(response.data);
        })
    }, []);
    return <>
                            <button onClick={()=>{ navigate(-1) }} className="back back--one">
                    <span className="top-left" />
                    <span className="top-right" />
                    <span className="bottom-left" />
                    <span className="bottom-right" />
                    <span className="stalk" />
                    <span className="text">BACK</span>
                    </button>


                    <div className="container">

    <table className="table table-dark">
        <thead>
            <tr>
                <th>Sr No</th>
                <th>Company Name</th>
                <th>Date</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {oldData.map(data=>{
                return <tr>
                <td>{data.sno}</td>
                <td>{data.comapanyname}</td>
                <td>{data.adate}</td>
                <td><button className="btn btn-success" onClick={()=>{
                    navigate("pdf", { replace:false, state:{ sno : data.sno, aname:location.state.aname  } })
                }}>Print</button></td>
            </tr>
            }).reverse()}
            
        </tbody>
    </table>

                    </div>
    </>;
};

export default ReparWoList;

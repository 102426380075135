// export const ServerUrl = "http://localhost/";
export const ServerUrl = "https://erp1.adigrp.com/";

// User Created
// user@03feb2023

// Document Pass
// $2y$10$2lCLfdqgeMqAySD6fSHlGuMMXookdFeiNxvF38VzHJFfjsd/A0otO

// Store Dept
// $2y$10$lnqbEoInTB2Be4C9eOsvg.8f.QlfuiCrvQMnOGTWJc5kpPe4mSYla

// swap@9474
import { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import { useLocation, useNavigate } from "react-router-dom";

const Product = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state.api_key);
    const [oldProName, newProName] = useState();

    const [oldHSNCode, newHSNCode] = useState();
    const formdata = new FormData();
    const HandleFormSubmit = (event) => { 
        event.preventDefault();
        if(Boolean(oldProName) && Boolean(oldHSNCode))
        {
            formdata.append('api_key', "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append('productname', oldProName.replace(/"/g, "inch"));


            formdata.append('hsncode', oldHSNCode);
            axios.post(ServerUrl+"aderp/user/store/addproduct.php", formdata).then(
                response => { alert(response.data);window.location.reload(); }
            )

        }else {
            alert("All Fields Required");
        }
    }




    const [oldProData, newProData] = useState([]);
    useEffect(
        ()=>{
            axios.get(ServerUrl+"aderp/user/store/getproduct.php").then(response=> {
                newProData(response.data);
            })
        }, []
    )



return(<>  <button onClick={()=>{ navigate(-1) }} className="back back--one">
<span className="top-left" />
<span className="top-right" />
<span className="bottom-left" />
<span className="bottom-right" />
<span className="stalk" />
<span className="text">BACK</span>
</button><div className="container">
    <form className="form" onSubmit={HandleFormSubmit}>

        <strong> Products</strong><br />
        <hr/>
        <label>Add Product name</label>
        <input type="text" onChange={(event) => { newProName(event.target.value); }} className="form form-control" placeholder="Enter A Product Name" required />
        <br/>        
        <label>Add HSN/SAC Code</label>
        <input type="text" onChange={(event) => { newHSNCode(event.target.value); }} className="form form-control"  placeholder="Enter A Product HSN Code" required />
        <br/>
        <button className="btn btn-primary" type="submit">Add Product</button>&nbsp;<button className="btn btn-danger" type="reset">Clear</button>
    </form>




<hr/>
{/* class="scrolldown" */}
<div className="overflow-auto" style={{"maxHeight":"300px"}}>
<table className="table table-dark " >
    <thead>
       <tr>
            <th>Product No</th>
            <th>Product Name and Desc</th>

       </tr>
    </thead>
    <tbody>
{oldProData.map(data=>{ 
     return         <tr>
     <td>
         {data.sno}
     </td>
     <td>
         {data.productname}
         </td>
 </tr>
})}
    </tbody>
</table>

</div>


    </div></>);
}
export default Product;
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../ADAPI";
const VendorsPage = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.api_key);
    const navigate = useNavigate();
    const [oldCompName, newCompName] = useState();
    const [oldAdress, newAdress] = useState();
    const [oldComMob, newCompMob] = useState();
    const [oldCompPhone, newCompPhone] = useState();
    const [oldPurchPers, newPurchPers] = useState();
    const [oldPurchPerEmail, newPurchPerEmail] = useState();
    const [oldAccPerName, newAccPerName] = useState();
    const [oldAccPerEmail, newAccPerEmail]= useState();
    
    const [oldContPers, newContPers] = useState();
    const [oldCountryName, newCountryName] = useState();
    const [oldStateName, newStateName] = useState();
    const [oldPinCode, newPinCode] = useState();
    const [oldGSTIN, newGSTIN] = useState();

    const HandleSubmit = (event) => {
        event.preventDefault();
        if(Boolean(oldCompName) && Boolean(oldAdress) && Boolean(oldComMob) && Boolean(oldCompPhone) && Boolean(oldPurchPers) && Boolean(oldPurchPerEmail) && Boolean(oldAccPerName) && Boolean(oldAccPerEmail) &&  Boolean(oldContPers) && Boolean(oldCountryName) && Boolean(oldStateName) && Boolean(oldPinCode) && Boolean(oldGSTIN)){
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append('acompanyname', oldCompName);
            formdata.append('aadress', oldAdress);
            formdata.append('amobno', oldComMob);
            formdata.append('aphoneno', oldCompPhone);
            formdata.append('aemail', oldPurchPerEmail);
            formdata.append('apurchaseperson', oldPurchPers);
            formdata.append('apurchasepersonemail', oldPurchPerEmail);
            formdata.append('aaccountperson', oldAccPerName);
            formdata.append('aacountemail', oldAccPerEmail);
            formdata.append('acontactperson', oldContPers);
            formdata.append('acountry', oldCountryName);
            formdata.append('astate', oldStateName);
            formdata.append('apincode', oldPinCode);
            formdata.append('agstinno', oldGSTIN);
            axios.post(ServerUrl+"aderp/user/AddVendor.php", formdata).then(
                response => { alert(response.data);window.location.reload(); }
            )

        }else {
            alert("All Fields Required");
        }
    }

    return(<>
      <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>
    <div className="container">
    <form onSubmit={HandleSubmit}>
        <input type="text" onChange={(event) => { newCompName(event.target.value); }} className="form form-control" placeholder="Enter Company Name" />
        <input type="text" onChange={(event) => { newAdress(event.target.value); }} className="form form-control" placeholder="Enter Company Adress" />
        <input type="text" onChange={(event) => { newCompMob(event.target.value); }} maxLength="10" className="form form-control" placeholder="Enter Company Mobile NO" />
        <input type="text" onChange={(event) => { newCompPhone(event.target.value); }} className="form form-control" placeholder="Enter Company Phone No" />
        <input type="text" onChange={(event) => { newPurchPers(event.target.value); }} className="form form-control" placeholder="Purchase Person Name" />
        <input type="email" onChange={(event) => { newPurchPerEmail(event.target.value); }} className="form form-control" placeholder="Purchase Person Email" />
        <input type="text" onChange={(event) => { newAccPerName(event.target.value); }} className="form form-control" placeholder="Account Person Name" />
        <input type="email" onChange={(event) => { newAccPerEmail(event.target.value); }} className="form form-control" placeholder="Account Person Email" />
        <input type="text" onChange={(event) => { newContPers(event.target.value); }} className="form form-control" placeholder="Contact Person" />
        <input type="text" onChange={(event) => { newCountryName(event.target.value); }} className="form form-control" placeholder="Country Name" />
        <input type="text" onChange={(event) => { newStateName(event.target.value); }} className="form form-control" placeholder="State Name" />
        <input type="text" onChange={(event) => { newPinCode(event.target.value); }} className="form form-control" placeholder="Pin Code" />
        <input type="text" maxLength={15} minLength={15} onChange={(event) => { newGSTIN(event.target.value); }} className="form form-control" placeholder="GSTIN No" />
        <button type="submit" className="btn btn-primary">Add Vendor</button>&nbsp;<button type="reset" className="btn btn-danger"> Clear</button>

        <hr />
        
    </form> 
    <button className="btn btn-info" onClick={()=>{ navigate("list", {replace:false, state:{"api_key":"swapnilallowed"}}); }}>Vendors List</button>
    </div>
    </>)
}

export default VendorsPage;
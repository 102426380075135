import { useNavigate } from "react-router-dom";
import { ServerUrl } from "../../ADAPI";
import axios from "axios";
import { useState, useEffect } from "react";

const ADStack = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
// Product List
    //Available

   
    const [oldData, newData] = useState([]);


    useEffect(()=>{
        axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowStack.php").then(response=> {
            newData(response.data);
            // console.log(response.data);
        })
    }, [])


    // useEffect(
    //     ()=>{
    //         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowStack.php").then(response=> {
    //             newData(response.data);
    //             // console.log(response.data);
    //         })
    //     },[]
    // )
    // // Used
    // const [oldNData, newNData] = useState([]);
    // useEffect(
    //     ()=>{
    //         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowOut.php").then(response=> {
    //             newNData(response.data);
    //             // console.log(response.data);
    //         })
    //     },[]
    // )
    // const [oldNotData, newNotData] = useState([]);
    // useEffect(
    //     ()=>{
    //         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowNonSel.php").then(response=> {
    //             newNotData(response.data);
    //             // console.log(response.data);
    //         })
    //     },[]
    // )

        
    let proArray = oldData.map(data=>{
        return {value : data.productname, value2:parseInt(data.available_stock),label : data.productname+"   [ Available Quantity  "+data.available_stock +"]" }
    });

    // oldData.map(data=>{
    //     // console.log(data.productname)
    //      oldNData.map(ndata=>{
    //         // console.log(ndata.sin);
    //          if(ndata.productname===data.productname && data.sin-ndata.sout >0)
    //         {
    //             proArray.push({value : data.productname, value2: parseInt(data.sin-ndata.sout), label : data.productname+"  [ Available Quantity "+(data.sin-ndata.sout) +"]" });
    //         }
    //     })
    // })
    

    // oldNotData.map(data=>{
    //     proArray.push({value : data.productname, value2:parseInt(data.quantity),label : data.productname+"   [ Available Quantity  "+data.quantity +"]" })
    // })
   

let oldMess = <></>;
if(proArray.length === 0){
    oldMess = <h1>No Products Available in Stack</h1>;
}



let newArrr = proArray.map(data=>{ return {"Product Name": data.value, "Quantity" : data.value2} })

console.log(newArrr);

const [oldFilterParam, newFilterParam] = useState("");

    return <>
  <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
  <div className="container">

<input type="text" className="form form-control" onChange={(e)=>{newFilterParam(e.target.value)}} placeholder="Search" /><br/>

  <div className="overflow-auto" style={{"maxHeight":"600px"}}>
  <table className="table table-dark">
  <thead>
    
    
    <tr>

      <th scope="col">Product Name</th>
      <th scope="col">Available  Quantity</th>
      
    </tr>
  </thead>
  {/* <tbody>
    {proArray.map(data => { 
        return <tr>
      
        <td>{data.value}</td>
        <td>{data.value2}</td>
      </tr>
    })}
    
   

  </tbody> */}

<tbody>
    {proArray.filter(data => data.value.toLowerCase().includes(oldFilterParam.toLowerCase())).map(data => { 
        return <tr>
      
        <td>{data.value}</td>
        <td>{data.value2}</td>
      </tr>
    })}
    
   

  </tbody> 
</table>
</div>
{oldMess}
  </div>
</>
}

export default ADStack;
import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import Select from 'react-select';

import { useLocation, useNavigate } from "react-router-dom";
const ProIn = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state.api_key);
    const [oldVend, newVen] = useState([]);
    const [oldVender, newVender] = useState();
useEffect(()=>{
    axios.get(ServerUrl+"aderp/user/GetVendor.php").then(response=>{
        newVen(response.data);
    })
    
},[]);
const [oldClientData, newClientData] = useState([]);

useEffect(()=>{
    axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newClientData(response.data);
    });
},[]);


let options = oldVend.map(data => { 
    return { value:data.acompanyname, label:data.acompanyname }
}).concat(oldClientData.map(data=>
  { return { value: data.acompanyname, label:data.acompanyname }
}))


// ProDuct Data

const [oldProData, newProData] = useState([]);
const [oldProSel,newProSel] = useState();
useEffect(
    ()=>{
        axios.get(ServerUrl+"aderp/user/store/getproduct.php").then(response=> {
            newProData(response.data);
        })
    }, []
)
let proData = oldProData.map(data => {
    return { value: data.productname, label: data.productname }
})


// Status



const [oldStatus, newStatus] = useState();

// Quantity
const [oldQuant, newQuant] = useState();


const [oldassettype, newassettype]= useState();

const HandleFormSubmit = (event) => {
    event.preventDefault();
    if(Boolean(oldProSel) && Boolean(oldQuant) && Boolean(oldStatus) && Boolean(oldVender) && Boolean(oldassettype))
    {
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("productname", oldProSel);
        formdata.append("vendorname", oldVender);
        formdata.append("quantity", oldQuant);
        formdata.append("status", oldStatus);
        formdata.append("assettype", oldassettype);
            axios.post(ServerUrl+"aderp/user/store/addproin.php", formdata).then(
                response => {
                    alert(response.data);
                    window.location.reload();
                }
            );


    }else {
        alert("All Fields Are Required");
    }
}

    return(<>
      <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>
    <div className="container">
        <form onSubmit={HandleFormSubmit}> 

    <strong>Product In (Store)</strong>
        <hr/>
        <label>Select A Vendor Name</label>
        <Select onChange={(selectedoption)=> {newVender (selectedoption.value);}} className="form form-control" options={options}  maxMenuHeight={45} openMenuOnClick={false} />
        <br/>
        <label>Select A Product Name</label>
        <Select onChange={(selectedoption)=> {newProSel(selectedoption.value);}} className="form form-control" options={proData}  />
    <br/>
    <label>Select A Asset Type</label>
        <Select onChange={(selectedoption)=> {newassettype(selectedoption.value);}} className="form form-control" options={[{value:"consumable", label:"Consumable Asset"}, { value:"fixed", label:"Fixed Asset" }]}  />
    <br/>
    <label>
        Enter Quantity
    </label>
    <input type="number" onChange={(event)=>{
        newQuant(event.target.value);
    }} className="form form-control" placeholder="Enter A Product Quantity" required/>
<br/>
<label>
       Status
    </label>
    <Select options={[{value:"ok", label:"Tested Ok"}, {value:"faulty", label:"Faulty"}, {value:"ur", label:"Under Repairing"}]} className="form form-control" onChange={(selectedoption)=>{
        newStatus(selectedoption.value);
    }
    }  />



<br />

<button className="btn btn-primary" type="submit">In Product</button>
&nbsp;
<button className="btn btn-danger" type="reset">Clear</button>

        </form>
    
    </div></>);
}
export default ProIn;
import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import { Form, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import UpVend from "./UpVend";
const VendorList = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state.api_key);
    const [oldData, newData] = useState([]);
const DataGetter = ()=>{
  axios.get(ServerUrl+"aderp/user/GetVendor.php").then(reponse => {
    newData(reponse.data);
    console.log(reponse.data);
 })
}
useEffect(()=>{
      DataGetter();
},[]);

const SwapOption = oldData.map(data=>{
  return { label:data.acompanyname, value:data.sno }
}) 



const HandleUpdateButton = (sno) => toast(<UpVend sno={sno} />);


// Search Variable Store

const [oldSno, newSno] = useState("");

const filterDataArray = oldData.filter(data=>{
  if(oldSno===""){
    return data;
  }else{
    return data.sno===oldSno;
  }
})



    return(<>
     <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>

    <div className="container">
        {/* <button className="btn btn-info" onClick={()=>{ window.print(); }}>Print</button> 
        
        onInputChange={()=>{ newSno(""); }}
        */}
        <hr />
        <Select options={SwapOption} onChange={(newValue)=>{ newSno(newValue.value); }}  onMenuOpen={()=>{ newSno(""); }}  />


        <div className="overflow-auto" style={{"maxHeight":"500px"}}>


    <table className="table">
  <thead>
    <tr>
      <th scope="col">S No</th>
      <th scope="col">Company Name</th>

      <th scope="col">Name</th>
      <th scope="col">Adress</th>
      <th scope="col">Contact No</th>
      <th scope="col">QC Email</th>

      <th scope="col">Action</th>

    </tr>
  </thead>
  <tbody>
 {filterDataArray.map(data =>{
    return    <tr>
    <th scope="row">{data.sno}</th>
    <td>{data.acompanyname}</td>
    <td>{data.acontactperson}</td>
    <td>{data.aadress}</td>
    <td>{data.amobno}</td>
    <td>{data.aemail}</td>
    <td><button className="btn btn-success" onClick={()=>{ HandleUpdateButton(data.sno); }}>Update</button></td>

  </tr>
 })}
  </tbody>
</table>
</div>

</div>

<ToastContainer  position="top-center"  autoClose={false} closeOnClick={false} hideProgressBar={true}  />
    </>);
}

export default VendorList;
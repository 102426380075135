import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Admin from './Admin/Admin';
import User from './Users/User';
import CreateUser from './Admin/Users/CreateUser';
import Clients from './Admin/Clients/Clients';
import AMC from './Admin/AMC/AMC';
import UnderWCMS from './Admin/AMC/Components/UnderWCMS';
import StorePage from './Admin/Store/StorePage';
import Product from './Admin/Store/StoreComp/Product';
import ProIn from './Admin/Store/StoreComp/ProIn';
import ProOut from './Admin/Store/StoreComp/ProOut';
import VendorsPage from './Admin/Vendors/VendorsPage';
import VendorList from './Admin/Vendors/Comp/VendorList';
import JobNo from './Admin/Store/StoreComp/JobNo';
import DeliveryChallan from './Admin/Store/StoreComp/DelieveryChallan';
import DCData from './Admin/Store/StoreComp/DCData';
import DCList from './Admin/Store/StoreComp/DCList';
import UpdateChallan from './Admin/Store/StoreComp/UpdateChallan';
import UserPrivilages from './Admin/Users/UserPrivilages';
import CourierPage from './Admin/Courier/CourierPage';
import PIPage from './Admin/proinvoice/PIPage';
import PI from './Admin/proinvoice/SubComp/PI';
import PO from './Admin/proinvoice/SubComp/PO';
import FPO from './Admin/proinvoice/SubComp/formats/FPO';
import FPI from './Admin/proinvoice/SubComp/formats/FPI';
import PoList from './Admin/proinvoice/SubComp/PoList';
import PIList from './Admin/proinvoice/SubComp/PIList';
import ADPro from './Admin/adproducts/ADPro';
import ADProList from './Admin/adproducts/ADProList';
import ADUp from './Admin/adproducts/ADProUpdate.js/ADUp';
import ADQuot from './Admin/quot/ADQuot';
import QuotList from './Admin/quot/compo/QuotList';

import Viewamccal from './Admin/quot/compo/Viewamccal';
import Viewcalval from './Admin/quot/compo/Viewcalval';
import Viewcmccal from './Admin/quot/compo/Viewcmccal';
import Vieweac from './Admin/quot/compo/Vieweac';
import Viewras from './Admin/quot/compo/Viewras';
import ADStack from './Admin/adproducts/ADStack';
import BackupData from './Admin/backup/BackupData';
import WorkOrderHome from './Admin/workorder/WorkOrderHome';
import MachineWO from './Admin/workorder/machine/MachineWO';
import MWOList from './Admin/workorder/machine/View/MWOList';
import MWO from './Admin/workorder/machine/View/MWO';
import AMCWO from './Admin/workorder/amc/AMCWO';
import AMCWOList from './Admin/workorder/amc/AMWOList';
import AMCWOPdf from './Admin/workorder/amc/AMCWOPdf';
import Calibration from './Admin/workorder/calibration/Calibration';
import CalList from './Admin/workorder/calibration/CalList';
import CalPdf from './Admin/workorder/calibration/CalPdf';

import ReparWoList from './Admin/workorder/repairing/ReparWoList';
import ReparWoPdf from './Admin/workorder/repairing/ReparWoPdf';
import ReparWo from './Admin/workorder/repairing/ReparWo';
import axios from 'axios';
const root = ReactDOM.createRoot(document.getElementById('root'));





var inactivityTime = function () {
      var time;
      window.onload = resetTimer;
      // DOM Events
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;

      function logout() {
          console.log("Session Expired Login Again");
            window.location.href = "/";
      }
  
      function resetTimer() {
          clearTimeout(time);
         
          if(window.location.pathname !== "/"){
            time = setTimeout(logout, 600000);
            // time = setTimeout(logout, 3000);

          }
          // 1000 milliseconds = 1 second
      }
  };

window.onload = function() {
      
      inactivityTime();
}
root.render(
  <BrowserRouter>
   <Routes>
      <Route path='/' element={<App />}></Route>
      {/* Backup Route */}
      <Route path='/admin/backup' element={<BackupData />}></Route>
      
{/* Admin Routes */}
      <Route path='/admin' element={<Admin />}></Route>
      <Route path='/admin/userprivilages' element={<UserPrivilages />}></Route>
      <Route path='/admin/createuser' element={<CreateUser />}></Route>
      <Route path='/admin/client' element={<Clients />}></Route>
      <Route path='/admin/amc' element={<AMC />}></Route>
      <Route path='/admin/amc/uwamc' element={<UnderWCMS />}></Route>
      <Route path='/admin/cms' element={<Clients />}></Route>
      <Route path='/admin/adamc' element={<Clients />}></Route>
{/* Store Page */}
      <Route path='/admin/store' element={<StorePage />}></Route>
      <Route path='/admin/store/stack' element={<ADStack />}></Route>

      <Route path='/admin/store/addproduct' element={<Product />}></Route>
      <Route path='/admin/store/productin' element={<ProIn />}></Route>
      <Route path='/admin/store/productout' element={<ProOut />}></Route>
      <Route path='/admin/store/createjobno' element={<JobNo />}></Route>
      <Route path='/admin/store/dc' element={<DCData />}></Route>
      <Route path='/admin/store/dc/pdf' element={<DeliveryChallan />}></Route>
      <Route path='/admin/store/dc/list' element={<DCList />}></Route>
      <Route path='/admin/store/dc/list/updatechallan' element={<UpdateChallan />}></Route>
      <Route path='/admin/store/dc/list/updatechallan/pdf' element={<DeliveryChallan />}></Route>

      <Route path='/admin/vendor' element={<VendorsPage />}></Route>  
      <Route path='/admin/vendor/list' element={<VendorList />}></Route>
{/* Courier Page */}
      <Route path='/admin/courier' element={<CourierPage />}></Route>

{/* PI Routes */}
      <Route path='/admin/pi' element={<PIPage />}></Route>
      <Route path='/admin/pi/pi' element={<PI />}></Route>
      <Route path='/admin/pi/pi/list/pdf' element={<FPI />}></Route>
      <Route path='/admin/pi/pi/list' element={<PIList />}></Route>
      <Route path='/admin/pi/po' element={<PO />}></Route>
      <Route path='/admin/pi/po/list/pdf' element={<FPO />}></Route>
      <Route path='/admin/pi/po/list' element={<PoList />}></Route>
{/* AD Pro Routes */}
      <Route path='/admin/adpro' element={<ADPro />}></Route>
      <Route path='/admin/adpro/list' element={<ADProList />}></Route>
      <Route path='/admin/adpro/stack' element={<ADStack />}></Route>

      <Route path='/admin/adpro/list/update' element={<ADUp />}></Route>
{/* AD Quat Routes */}
      <Route path='/admin/quot' element={<ADQuot />}></Route>
      <Route path='/admin/quot/list' element={<QuotList />}></Route>
                  {/* QuotationPDF Routes */}
                  <Route path='/admin/quot/list/amccal' element={<Viewamccal />}></Route>
                  <Route path='/admin/quot/list/calval' element={<Viewcalval />}></Route>
                  <Route path='/admin/quot/list/cmccal' element={<Viewcmccal />}></Route>
                  <Route path='/admin/quot/list/eac' element={<Vieweac />}></Route>
                  <Route path='/admin/quot/list/ras' element={<Viewras />}></Route>
                 
                 
 {/* Work Order Routes */}
      <Route path='/admin/workorder' element={<WorkOrderHome />}></Route>
      <Route path='/admin/workorder/machine' element={<MachineWO />}></Route>
      <Route path='/admin/workorder/machine/list' element={<MWOList />}></Route>
      <Route path='/admin/workorder/machine/list/pdf' element={<MWO />}></Route>
      
      
      <Route path='/admin/workorder/amcwo' element={<AMCWO />}></Route>
      <Route path='/admin/workorder/amcwo/list' element={<AMCWOList />}></Route>
      <Route path='/admin/workorder/amcwo/list/pdf' element={<AMCWOPdf />}></Route>
     
     
      <Route path='/admin/workorder/cal' element={<Calibration />}></Route>
      <Route path='/admin/workorder/cal/list' element={<CalList />}></Route>
      <Route path='/admin/workorder/cal/list/pdf' element={<CalPdf />}></Route>

      <Route path='/admin/workorder/rep' element={<ReparWo />}></Route>
      <Route path='/admin/workorder/rep/list' element={<ReparWoList />}></Route>
      <Route path='/admin/workorder/rep/list/pdf' element={<ReparWoPdf />}></Route>


 
 {/* User Routes */}




      <Route path='/user' element={<User />}></Route>

{/* Store Access Route */}

      <Route path='/user/store' element={<StorePage />}></Route>
      <Route path='/user/store/addproduct' element={<Product />}></Route>
      <Route path='/user/store/productin' element={<ProIn />}></Route>
      <Route path='/user/store/productout' element={<ProOut />}></Route>
      <Route path='/user/store/createjobno' element={<JobNo />}></Route>
      <Route path='/user/store/dc' element={<DCData />}></Route>
      <Route path='/user/store/dc/list' element={<DCList />}></Route>
      <Route path='/user/store/dc/list/updatechallan' element={<UpdateChallan />}></Route>
      <Route path='/user/store/dc/list/updatechallan/pdf' element={<DeliveryChallan />}></Route>


      <Route path='/user/store/dc/pdf' element={<DeliveryChallan />}></Route>
{/* Vendor Route User */}
      <Route path='/user/vendor' element={<VendorsPage />}></Route>  
      <Route path='/user/vendor/list' element={<VendorList />}></Route>
{/* Courier Routes */}
      <Route path='/user/courier' element={<CourierPage />}></Route>

{/* PI Routes */}
      <Route path='/user/pi' element={<PIPage />}></Route>


      <Route path='/user/pi/pi' element={<PI />}></Route>
      <Route path='/user/pi/pi/list/pdf' element={<FPI />}></Route>
      <Route path='/user/pi/pi/list' element={<PIList />}></Route>

      <Route path='/user/pi/po' element={<PO />}></Route>
      <Route path='/user/pi/po/list/pdf' element={<FPO />}></Route>
      <Route path='/user/pi/po/list' element={<PoList />}></Route>
{/* AD Product */}
      <Route path='/user/adpro' element={<ADPro />}></Route>
      <Route path='/user/adpro/list' element={<ADProList />}></Route>
      <Route path='/user/adpro/list/update' element={<ADUp />}></Route>
{/* AD Quot */}
      <Route path='/user/quot' element={<ADQuot />}></Route>
      <Route path='/user/quot/list' element={<QuotList />}></Route>
            {/* Quot Routes */}
                  <Route path='/user/quot/list/amccal' element={<Viewamccal />}></Route>
                  <Route path='/user/quot/list/calval' element={<Viewcalval />}></Route>
                  <Route path='/user/quot/list/cmccal' element={<Viewcmccal />}></Route>
                  <Route path='/user/quot/list/eac' element={<Vieweac />}></Route>
                  <Route path='/user/quot/list/ras' element={<Viewras />}></Route>

            {/* Work Order Routes */}
      {/* Machine WO */}
            <Route path='/user/workorder' element={<WorkOrderHome />}></Route>
            <Route path='/user/workorder/machine' element={<MachineWO />}></Route>
            <Route path='/user/workorder/machine/list' element={<MWOList />}></Route>
            <Route path='/user/workorder/machine/list/pdf' element={<MWO />}></Route>
      {/* Machine WO */}
      {/* AMC WORK ORDER */}

      {/* AMC WORK ORDER */}

{/* Client Routes For User Later Assigned */}
<Route path='/user/client' element={<Clients />}></Route>

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


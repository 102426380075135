import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import { useLocation, useNavigate } from "react-router-dom";


const DCData = () =>
{
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.aname);
    
    const [oldClientData, newClientData] = useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newClientData(response.data);
        });
    },[]);

    const [oldData, newData] = useState([]);
    const DataGetter = ()=>{
      axios.get(ServerUrl+"aderp/user/GetVendor.php").then(reponse => {
        newData(reponse.data);
        console.log(reponse.data);
     })
    }
    useEffect(()=>{
          DataGetter();
    },[]);

    let clientDataArray = oldClientData.map(data=>{
        return { value:data.acompanyname+"\n\n"+data.aadress+"\n GSTIN NO:"+data.agstinno, value2:data.acompanyname,label:data.acompanyname }
    }).concat(oldData.map(data=>{ return { value : data.acompanyname+"\n\n"+data.aadress+"\n GSTIN NO:"+data.agstinno, 
    value2:data.acompanyname,
    label:data.acompanyname } }))
    // console.log(clientDataArray);

// Ploduct List Here
/* 
const [oldProData, newProData] = useState([]);

useEffect(
    ()=>{
        axios.get(ServerUrl+"aderp/user/store/getproduct.php").then(response=> {
            newProData(response.data);
        })
    }, []
)
let proData = oldProData.map(data => {
    return { value: data.productname, label: data.productname }
})

 console.log(proData);
*/

const [oldAData, newAData] = useState([]);
useEffect(
    ()=>{
        axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowStack.php").then(response=> {
            newAData(response.data);
            console.log(response.data);
        })
    },[]
)
// // Used
// const [oldNData, newNData] = useState([]);
// useEffect(
//     ()=>{
//         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowOut.php").then(response=> {
//             newNData(response.data);
//             console.log(response.data);
//         })
//     },[]
// )
// const [oldNotData, newNotData] = useState([]);
// useEffect(
//     ()=>{
//         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowNonSel.php").then(response=> {
//             newNotData(response.data);
//             console.log(response.data);
//         })
//     },[]
// )

    
let proArray = oldAData.map(data=>{
    return {value : data.productname, value2:parseInt(data.available_stock),label : data.productname+"   [ Available Quantity  "+data.available_stock +"]" }
});

// oldAData.map(data=>{
//     // console.log(data.productname)
//      oldNData.map(ndata=>{
//         // console.log(ndata.sin);
//          if(ndata.productname===data.productname && data.sin-ndata.sout >0)
//         {
//             proArray.push({value : data.productname, value2: parseInt(data.sin-ndata.sout), label : data.productname+"  [ Available Quantity "+(data.sin-ndata.sout) +"]" });
//         }
//     })
// })


// oldNotData.map(data=>{
//     proArray.push({value : data.productname, value2:parseInt(data.quantity),label : data.productname+"   [ Available Quantity  "+data.quantity +"]" })
// })

// console.log(proArray);



const [oldProArr, newProArr] = useState([]);
let count =0;
// let arr = [];
// arr = oldProArr.map(data=>{
//     return {name:data.value, quantity: 0}
// });
const [oldSwapArr, newSwapArr] = useState([]);
useEffect(()=>{
    newSwapArr( oldProArr.map(data=>{ return {name:data.value,quantity: 0, nos:0}}))
}, [oldProArr])


const SwapData = () => {
    console.log(oldSwapArr);
}

// Goods Type
const [oldGtype, newGtype] = useState();
// Client Name
const [oldCname, newCname]= useState();
const [oldCCName, newCCName] = useState();
const HandleSubmitData = (event) =>{
    event.preventDefault();
    if(Boolean(oldGtype)&& Boolean(oldCname) && oldSwapArr.length>0 && Boolean(oldCCName)){
        
        let flag = true;

        oldSwapArr.map(data=>{
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("productname", data.name);
            formdata.append("companyname", oldCCName);
            formdata.append("quantity", data.quantity);
            axios.post(ServerUrl+"aderp/user/store/addproductoutdc.php", formdata).then(reponse => {
                console.log(reponse.data);
                if(reponse.data === "fail"){
                    alert("Failed To Add");
                    flag = false;
                }
            }).catch(err => { console.log(err); })
        })
        
        if(flag){
                   
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("clientname", oldCname);
            formdata.append("goodtype", oldGtype);
            formdata.append("productarr", JSON.stringify(oldSwapArr));
            formdata.append("generatedby", location.state.aname) //Put Name Here
            axios.post(ServerUrl+"aderp/user/dc/adchallan.php", formdata).then(
                response => {   
                    alert("CHALLAN NO"+response.data);
                    navigate("pdf", { replace:false, state:{ sno:response.data } });
                }
            )
        }


    }else{
        alert("Please Fill All Details");
    }
}


const [oldStatus, newStatus] = useState(false);
    return(<>
     <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
    <div className="container">
        <h1>Delivery Challan Generator</h1>
        <hr />
        <button className="btn btn-primary" onClick={()=>{ navigate('list', { replace:false, state:{ aname:location.state.aname } }) }}>List</button>
        <hr />
        <form onSubmit={HandleSubmitData}>
        <label>Select Client Name</label>
        <Select options={clientDataArray} onChange={(selectedval)=>{ newCname(selectedval.value); newCCName(selectedval.value2) }} maxMenuHeight={45} openMenuOnClick={false}  placeholder="Select Client Name" />
        <br />
        <label>Select Goods Type</label>
       
       
        <Select options={[{value:"Returnable", label:"Returnable"}, {value:"Non-Returnable", label:"Non-Returnable"}]} onChange={(selectedval)=>{ newGtype(selectedval.value) }} placeholder="Select Goods Type" />
       
       
        <br />
        <label>Select Products</label>
        <Select 
        isDisabled={oldStatus}
    isMulti
    options={proArray}
    onChange={(selectedvalue) => { newProArr(selectedvalue); }}
  />


<br />
<table onClick={()=>{newStatus(true)}} className="table">
  <thead>
    <tr>
      <th scope="col">Sr. No</th>
      <th scope="col">Product  Name</th>

      <th scope="col">Quantity</th>
      <th scope="col">Nos Pack./Box</th>

    </tr>
  </thead>
  <tbody>
   { oldSwapArr.map(data=>{
    
    

    // console.log(data);
    return (<tr>
    <th scope="row">   {count++}</th>
    <td>{data.name}</td>


    <td>
        {/* {proArray.filter(dataset=>dataset.value===data.name).map(data => { return data.value2 })} */}
        <input type="number" onChange={(event)=>{ data.quantity=event.target.value;
       SwapData();
        }} className="form form-control" max={proArray.filter(dataset=>dataset.value===data.name).map(data => { return data.value2 })} placeholder={"Available Quantity "+proArray.filter(dataset=>dataset.value===data.name).map(data => { return data.value2 })} />
    </td> 
    <td>
        <input type="number" onChange={(event)=>{ data.nos=event.target.value;
       SwapData();
        }} className="form form-control" />
    </td> 
 
  </tr>
  )
   
   })
   }
  </tbody>
</table>
<br/>
<br/>
<hr/>
<button type="submit" className="btn btn-primary">Create Delivery Challan</button>
&nbsp;
<button type="reset" className="btn btn-danger">Clear</button>

</form>
    </div>
    </>);
}

export default DCData;
import axios from "axios";
import { useEffect } from "react";
import {  Link, useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../ADAPI";

const Admin = () => { 
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state.aname);
  console.log(location.state.aemail);
   axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
useEffect(()=>{

// const config = {
//   headers: { Authorization: "Bearer " + localStorage.getItem("adtoken")}
// };
    axios.get(ServerUrl+"aderp/verifytoken.php").then(response => { 
      console.log(response.data);
    }).catch(err => { 
      console.log(err);
      navigate("/", { replace:true });
    });
      
  

},[])


    return(<>
    <nav className="navbar bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
      <img
        src="Assets/logo.png"
        alt="Logo"
        width={30}
        height={24}
        className="d-inline-block align-text-top"
      />&nbsp;
      Admin Panel AD Pharma Equipment Pvt. Ltd.
    </a>



    <strong>
      
      <Link to="backup" state={{aname : location.state.aname, aemail : location.state.aemail }} className="btn btn-success">Backup</Link>
      &nbsp;
      
      <a onClick={()=>{ localStorage.removeItem("adtoken") }} href="/" className="btn btn-danger">Logout</a></strong>
  </div>
</nav>

    <br />
    <br />
    <br />

    
<div className="container">

<div className="row">
  <div className="col-sm-3" onClick={()=>{ navigate("userprivilages", { replace:false, state:{ api_key:"swapnilallowed" } }) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-users"></i> &nbsp;  Users Privilages</h5>
        {/* <p className="card-text">
        
          With supporting text below as a natural lead-in to additional content.
        </p> */}
        {/* <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
  <div className="col-sm-3" onClick={()=>{navigate("createuser", {replace:false, state:{api_key:"swapnilallowed"}})}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-user-plus"></i>&nbsp; Create  User</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
  <div className="col-sm-3" onClick={()=>{ navigate("amc", {replace:false, state:{api_key:"swapnilallowed"}}) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-gears"></i>&nbsp; AMC
         </h5>
<br/>

{/* <div className="progress">
  <div
    className="progress-bar progress-bar-striped"
    role="progressbar"
    style={{ width: "100%" }}
    aria-valuenow={10}
    aria-valuemin={0}
    aria-valuemax={100}
  >10</div>
</div> */}




        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>

  <div className="col-sm-3">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-list"></i>&nbsp;Calibration </h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
</div>

<div className="row">
  <div className="col-sm-3" onClick={()=>{ navigate("store", {replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname}}) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-store"></i> &nbsp; Store</h5>
        {/* <p className="card-text">
        
          With supporting text below as a natural lead-in to additional content.
        </p> */}
        {/* <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
  <div className="col-sm-3" onClick={()=>{ navigate("courier", {replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname}}) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-envelope"></i>&nbsp; Couriers</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
  <div className="col-sm-3" onClick={()=>{ navigate("workorder", { replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname} }) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-briefcase"></i>&nbsp; Work Orders</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>

  <div className="col-sm-3" onClick={()=>{ 
    navigate("adpro", {replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname}}) }} >
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-industry"></i>&nbsp;Products</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
</div>

{/* Third Row */}
<div className="row">
  <div className="col-sm-3" onClick={()=>{ 
    navigate("quot", {replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname}}) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-file"></i> &nbsp; Quotations</h5>
        {/* <p className="card-text">
        
          With supporting text below as a natural lead-in to additional content.
        </p> */}
        {/* <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
  <div className="col-sm-3" onClick={()=>{navigate("client", {replace:false, state:{api_key:"swapnilallowed"}})}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-mug-hot"></i>&nbsp; Clients</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>

  <div className="col-sm-3" onClick={()=>{ navigate("pi", {replace:false, state:{api_key:"swapnilallowed", aname: location.state.aname}}) }}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-file-invoice"></i>&nbsp; Proforma  Invoice</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>


  <div className="col-sm-3">
  
  <div className="card" onClick={()=>{ navigate("vendor", {replace:false, state:{"api_key":"swapnilallowed"}}) }}>
    <div className="card-body">
      <h5 className="card-title"><i className="fa fa-solid fa-list"></i>&nbsp;Vendors </h5>
      {/* <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" className="btn btn-primary">
        Go somewhere
      </a> */}
    </div>
  </div>
</div>




  <div className="col-sm-3"style={{"paddingTop":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-bug"></i>&nbsp;Report Bug</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
</div>





</div>

    
    </>)
}

export default Admin;
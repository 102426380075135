import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServerUrl } from  "../../../ADAPI";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateQt from "./UpdateQt/UpdateQt";
const QuotList =() => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate(); 
// Quatation List From Database
        const [oldQutoList, newQuotList] = useState([]);
        useEffect(()=>{
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            axios.post(ServerUrl+"aderp/admin/quot/getquotlist.php", formdata).then(response=>{
                newQuotList(response.data);
                console.log(response.data);
            }).catch(err => { console.log(err); })


         }, []);

/* Update Button Logic Below
        * 5 Types of AMC Different Logic    
*/
const SwapViewFuction = (sno, csno,qtype) => { 
    if(qtype==="amc&cal"){
        navigate("amccal", {replace:false, state : { sno : sno, csno:csno }})
    }else if(qtype==="cmc&cal"){
        navigate("cmccal", {replace:false, state : { sno : sno, csno:csno }})
    }
    else if(qtype==="cal&val"){
        navigate("calval", {replace:false, state : { sno : sno, csno:csno }})
    }
    else if(qtype==="r&s"){
        navigate("ras", {replace:false, state : { sno : sno, csno:csno }})
    }else if(qtype==="e&c"){
        navigate("eac", {replace:false, state : { sno : sno, csno:csno }})
    }
}

const [oldFilterParam, newFilterParam] = useState("");
const notify = (sno) => toast(<UpdateQt sno={sno} />);
    return <>
      <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
    {/* QuotList Below */}
    <hr />
    <div className="container">
<input type="text" className="form form-control" placeholder="Search" onChange={(e)=>{newFilterParam(e.target.value);}} />
<br/>
    <div className="overflow-auto" style={{"maxHeight":"500px"}}>
    <table className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">Sr.No</th>
      <th scope="col">Client Name</th>
      <th scope="col">Quotation Type</th>
      <th scope="col">Qutation Date</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>

{oldQutoList.filter(data => data.aclientname.toLowerCase().includes(oldFilterParam.toLowerCase())).map(data => { 
    return     <tr>
    <th scope="row">{data.sno}</th>
    <td>{data.aclientname}</td>
    <td>{data.aquottype}</td>
    <td>{data.adate}</td>
    <td><button className="btn btn-primary" onClick={()=>{notify(data.sno)}}>Update</button> &nbsp; <button className="btn btn-success" onClick={()=> { SwapViewFuction(data.sno, data.aclient,data.aquottype); }}>Print</button></td>
  </tr>
}).reverse()}



  </tbody>
</table>
</div>

    </div>


    <ToastContainer position="top-center" style={{"width":"500px"}} autoClose={false} closeOnClick={false}  />
    </>
}
export default QuotList;
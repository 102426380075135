import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../ADAPI";

const UpdatePrivilages = (props) => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const sno = props.sno;
    const [oldUserData, newUserData] = useState([]);
    console.log(oldUserData);
    useEffect(()=>{
        axios.get(ServerUrl+"aderp/user/UserTableDataSno.php?api_key=7288edd0fc3ffcbe93a0cf06e3568e28521687bc&sno="+sno).then(response => {
            newUserData(response.data);
        }).catch(err => { console.log(err) });
    },[]);





     // AMC
     const [oldAMCP, newAMCP] = useState("denied");
     
     //Calibration
     const [oldCalP, newCalP] = useState("denied");
     // Store
     const [oldStoreP, newStoreP] = useState("denied");
     // Courier
     const [oldCourierP, newCourierP] = useState("denied");
     // WorkOrder
     const [oldWorkOrdP, newWorkOrdP] = useState("denied");
     // Products
     const [oldProductP, newProductP] = useState("denied");
     // Quotations
     const [oldQuotP, newQuotP] = useState("denied");
     // Clients
     const [oldClientP, newClientP] = useState("denied");
     // Proforma Invoice
     const [oldProInvo, newProInvo] = useState("denied");
        // Vendors 
        const [oldVendor, newVendor] = useState("denied");


    const HandleSubmitData = (e) => { 

        e.preventDefault();
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", sno);
        formdata.append("aamc",oldAMCP);
        formdata.append("acal",oldCalP);
        formdata.append("astore",oldStoreP);
        formdata.append("acourier",oldCourierP);
        formdata.append("aworder",oldWorkOrdP);
        formdata.append("aproduct",oldProductP);
        formdata.append("aqouet",oldQuotP);
        formdata.append("aclient",oldClientP);
        formdata.append("apreinvoice",oldProInvo);
        formdata.append("avendor",oldVendor);
        axios.post(ServerUrl+"aderp/admin/UpdateUser.php", formdata).then(response => { 
            alert(response.data);
        }).catch(err => { console.log(err)});
    }




    return <>
    
    Note : Default Previlages Are Denied.
    <br />
            <strong>AMC</strong> &emsp;&emsp;&emsp;&nbsp;&emsp; <input type="radio" value="allow" name="amc" onChange={(event)=> { newAMCP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="amc" onChange={(event)=> { newAMCP(event.target.value); }}  />Denied
            <br />
            <strong>Calibration</strong> &nbsp;&emsp; <input type="radio" value="allow" name="cal" onChange={(event)=> { newCalP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cal" onChange={(event)=> { newCalP(event.target.value); }} />Denied
            <br />
            <strong>Clients</strong> &nbsp;&emsp;&emsp;&emsp; <input type="radio" value="allow" name="cl" onChange={(event)=> { newClientP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cl" onChange={(event)=> { newClientP(event.target.value); }} />Denied
            <br />
            <strong>Couriers</strong> &emsp;&emsp;&nbsp;&nbsp; <input type="radio" value="allow" name="cr" onChange={(event)=> { newCourierP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cr" onChange={(event)=> { newCourierP(event.target.value); }} />Denied
            <br />
            <strong>Pro Invoice</strong> &nbsp;&emsp; <input type="radio" value="allow" name="pi" onChange={(event)=> { newProInvo(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="pi" onChange={(event)=> { newProInvo(event.target.value); }} />Denied
            <br />
            <strong>Products</strong> &emsp;&emsp;&nbsp; <input type="radio" value="allow" name="product" onChange={(event)=> { newProductP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="product" onChange={(event)=> { newProductP(event.target.value); }} />Denied
            <br />
            <strong>Quotations</strong> &emsp;&nbsp; <input type="radio" value="allow" name="Quotations" onChange={(event)=> { newQuotP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="Quotations" onChange={(event)=> { newQuotP(event.target.value); }} />Denied           
            <br />
             <strong>Strore</strong> &emsp;&emsp;&emsp;&nbsp;&nbsp; <input type="radio" value="allow" name="str" onChange={(event)=> { newStoreP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="str" onChange={(event)=> { newStoreP(event.target.value); }} />Denied
            <br />
             <strong>Work Orders</strong> &nbsp; <input type="radio" value="allow" name="wo" onChange={(event)=> { newWorkOrdP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="wo" onChange={(event)=> { newWorkOrdP(event.target.value); }} />Denied
            <br />
             <strong>Vendors</strong> &nbsp; <input type="radio" value="allow" name="v" onChange={(event)=> { newVendor(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="v" onChange={(event)=> { newVendor(event.target.value); }} />Denied
            <br />
            <button className="btn btn-primary" onClick={HandleSubmitData}>Update</button>

    
    
    </>;

}

export default UpdatePrivilages;

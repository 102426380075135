import { useLocation, useNavigate } from "react-router-dom";
import CreateClient from "./Components/CreateClient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServerUrl } from "../../ADAPI";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import UpdateClient from "./Components/UpdateClient";



const Clients = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.api_key);
    const navigate = useNavigate();
    const notify = () => toast(<CreateClient />);
    const UpClient = (sno) => toast(<UpdateClient sno={sno}  />);
    const [oldTabData, newTabData] = useState([]);
    const SwapDataGetter = () =>  {
        axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newTabData(response.data);
         });
    }
    useEffect(SwapDataGetter,[]);
    // Search Varaible Store
    const [oldClientSno, newClientSno] = useState("");
    const filterTabData = oldTabData.filter(data=>{
      if(oldClientSno===""){
          return data;
      }else{
        return data.sno===oldClientSno;
      }
    })
    const optionData = oldTabData.map(data=>{
      return { label:data.acompanyname, value:data.sno }
    })

    return(<>  <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button><div className="container">
            <button className="btn btn-primary" onClick={notify}>Add Client</button>
            <hr />
<Select options={optionData} onChange={(newValue)=>{ newClientSno(newValue.value) }} onMenuOpen={()=> { newClientSno("") }} />

<div className="overflow-auto" style={{"maxHeight":"500px"}}>

            <table class="table table-dark">
  <thead>
    <tr>
      <th scope="col">SNo.</th>
      <th scope="col">Name</th>
      <th scope="col">Purchase Email</th>
      <th scope="col">Date</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {filterTabData.map(data=>{ 
        return     <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.acompanyname}</td>
        <td>{data.apurchaseemail}</td>
        <td>{data.adate}</td>
        <td><button className="btn btn-success" onClick={()=>{ UpClient(data.sno); }}>Update</button></td>
  
      </tr>
     })}

</tbody>
</table>

</div>

    <ToastContainer position="top-center" style={{"width":"500px"}} autoClose={false} closeOnClick={false} />
    </div>
    </>)
}

export default Clients;
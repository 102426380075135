import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../../ADAPI";
import { useState } from "react";

const MWOList =()=>{
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state.aname);
const [oldList, newList] = useState([]);
axios.get(ServerUrl+"aderp/admin/machineworkorder/getlist.php").then(response => {
    newList(response.data);
})
console.log(oldList);

const month = ["Jan","Feb","March","April","May","June","July","Aug","Sept","Oct","Nov","Dec"];
function pad(n, length) {
    var len = length - (''+n).length;
    return (len > 0 ? new Array(++len).join('0') : '') + n
}




    return <>
    

               
    <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

    <div className="container">
    
    <table className="table table-dark">
  <thead>
  <tr>
    <th scope="col">Sr No</th>
    <th scope="col">Equipment Name</th>
    <th scope="col">Date</th>
    <th scope="col">Action</th>
  </tr>

  </thead>
  <tbody>


    {oldList.map(data=>{

    return <tr>
    <th scope="row"> {pad(data.sno,3)}/{month[new Date().getMonth()]}/{new Date().toLocaleDateString('en', {year: '2-digit'})}-{parseInt(new Date().toLocaleDateString('en', {year: '2-digit'}))+1} </th>
    <td>{data.equipname}</td>
    <td>{data.adate}</td>
    <td><button className="btn btn-success" onClick={()=>{ navigate("pdf", {replace:false, state: { aname : location.state.aname, sno : data.sno }}) }}>Print</button></td>
  </tr>   
}).reverse()}
  </tbody>
</table>

    
    </div>


    </>
}

export default MWOList;
import { useState,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import Select from "react-select";
import "./PO.css";



const PO = () => { 
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    // Vendors List
    
    const [oldVList, newVList] = useState([]);
    const DataGetter = ()=>{
        axios.get(ServerUrl+"aderp/user/GetVendor.php").then(reponse => {
          newVList(reponse.data);
          
       })
      }
      useEffect(()=>{
            DataGetter();
      },[]);

    // Creating Array of Calculations   
    const [oldSelectArr, newSelectArr] = useState([]);
    
    let count =1; //For Show Serial No
const [oldArrayDataObj, newArrayDataObj] = useState([]);

// .replace(/(\r\n|\n|\r)/gm, " ")

useEffect(()=>{
    newArrayDataObj(oldSelectArr.map(data=>{ return {name:data.value, desc: data.value2.replace(/(\r\n|\n|\r)/gm, " "),quantity: 0, rateperunit:0, amount:0 }}));
},[oldSelectArr])
//    let arr = oldSelectArr.map(data=>{
//         return {name:data.value, quantity: 0, rateperunit:0, amount:0 }
//     });  

    const SwapData = () => {
        console.log(oldArrayDataObj);
    }
    
    // End Creating Array of Calculation



      const SelectArrayVendor = oldVList.map(data=>{ return { label : data.acompanyname, value:data.acompanyname+" Address: "+ data.aadress+" GSTIN :" +data.agstinno, value2:data.aemail } })
      
    //End Vendors List

    // Products List And HSN Code
      
        const [oldProData, newProData] = useState([]);
        useEffect(
            ()=>{
                axios.get(ServerUrl+"aderp/user/store/getproduct.php").then(response=> {
                    newProData(response.data);
                })
            }, []
        )
        let proDataArray = oldProData.map(data => {
            const dataVal = data.productname.split("-");
            let dataV = " ";
            if(dataVal.length > 1){
                dataV = dataVal[1];
            }
            return { value: data.hsncode+"-"+data.productname, value2: dataV ,label: data.productname }
        })

    // End Product List And HSN Code



// Variable For Storing Result
const [oldToName, newToName] = useState();
const [oldToEmail, newToEmail] = useState();
const [oldSupName, newSupName] = useState();
const [oldProCods, newProCods] = useState();
const [oldMTPayment, newMTPayment] = useState();
const [oldSRef, newSupRef] = useState();
const [oldPrNo, newPrNo] = useState();
const [oldDispatch, newDispatch] = useState();
const [oldOtherRef, newOtherRef] = useState();
const [oldDiscount, newDiscount] = useState();
const [oldGst, newGst] = useState();
const [oldIGST, newIGST]= useState();



// End Variable For Storing Result



    const HandleFormSubmit = (event) => { 
   
        event.preventDefault();
        if(Boolean(oldToName) && Boolean(oldToEmail) &&
        Boolean(oldSupName) && Boolean(oldProCods) &&
        Boolean(oldMTPayment) && Boolean(oldSRef) &&
        Boolean(oldPrNo) && Boolean(oldDispatch) &&
        Boolean(oldOtherRef) && Boolean(oldDiscount) &&
        Boolean(oldGst) && Boolean(oldIGST)
        ){
                
                // Below the formdata object will created
                const formdata = new FormData();
                formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
                formdata.append("ato", oldToName);
                formdata.append("avemail", oldToEmail);
                formdata.append("asupplier", oldSupName);
                formdata.append("aprocods", oldProCods);
                formdata.append("amtpayment", oldMTPayment);
                formdata.append("asupref", oldSRef);
                formdata.append("aprno", oldPrNo);
                formdata.append("adispatcht", oldDispatch);
                formdata.append("aotherRef", oldOtherRef);
                formdata.append("adiscount", oldDiscount);
                formdata.append("aGstper", oldGst);
                formdata.append("aigst", oldIGST);

                // Array of Calcs
    
                formdata.append("amaterialarr", JSON.stringify(oldArrayDataObj));
                formdata.append("auser", location.state.aname);
                axios.post(ServerUrl+"aderp/user/po/genpo.php", formdata).then(response=>{
                    alert(response.data);
                    window.location.reload();
                }).catch(err => { console.log(err) });
        }else{
            alert("All Fields Required");
        }
    }





const [oldStatus, newStatus] = useState(false);

return<>
     <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
    
    <div className="container">
        
        {/* Button For PO Generating */}
            <button className="btn btn-primary" onClick={()=>{navigate("list", { replace:false, state:{ aname:location.state.aname } })}}>List of PO</button>
            <hr />

<form onSubmit={HandleFormSubmit}>            
            <strong>Generate Purchase Order        User : {location.state.aname}</strong>
            <br/>
            <label>Select PO To</label>
            <Select options={SelectArrayVendor}  onChange={(newValue)=> { newToName(newValue.value); newToEmail(newValue.value2); }} /> {/* for to*/}
            <br />
            <label>Select Supplier</label>
<br/>            <Select options={SelectArrayVendor} onChange={(newValue)=>{ 
    newSupName(newValue.value);
}} /> {/* for Supplier*/}
<br/>
<label>Enter A Project Cods</label>
<input type="number" onChange={(e)=>{e.preventDefault(); newProCods(e.target.value); }} className="form form-control"  placeholder="Project Cods" />
<br/>
<label>Mode/ Terms  of Payment</label>
<input type="text" className="form form-control" onChange={(e)=>{e.preventDefault(); newMTPayment(e.target.value); }} placeholder="Mode/ terms of Payment" />
<br/>
<label>Supplier Ref No / Date</label>
<input type="text" className="form form-control" onChange={(e)=>{e.preventDefault(); newSupRef(e.target.value); }} placeholder="Sipplier Ref No/ Date" />
<br/>
<label>Pr. No  &  Date</label>
<input type="text" className="form form-control" onChange={(e)=>{e.preventDefault(); newPrNo(e.target.value); }} placeholder="Pr.No/ Date" />

<br/>
<label>Dispatch Through</label>
<input type="text" className="form form-control" onChange={(e)=>{e.preventDefault(); newDispatch(e.target.value); } } placeholder="Dispatch Through" />
    
<br/>
<label>Other References</label>
<input type="text" className="form form-control" onChange={(e)=>{ e.preventDefault();newOtherRef(e.target.value); }} placeholder="Other References" />
    
    <br />
    <hr/>

     {/* Calculation Logics Start Here */}
 <label>Select Products</label><br/>
<Select options={proDataArray} isMulti={true} isDisabled={oldStatus}  maxMenuHeight={175} onChange={(newValue)=> { newSelectArr(newValue);
SwapData(); 
}} />
<br />

<table className="table" onClick={()=>{newStatus(true)}}>
  <thead className="thead-dark">
    <tr>
      <th scope="col">Sr.No</th>
      <th scope="col">Items Code & Description</th>
      <th scope="col">Quantity</th>
      <th scope="col">Rate Per Unit( Rs )</th>
      
    </tr>
  </thead>
  <tbody>
{oldArrayDataObj.map(data=>{
 
return <tr>
        <th scope="row">{count++}</th>
        <td>{data.name}</td>
        <td><input type="number" className="form form-control" onChange={(e)=> {
            e.preventDefault();
    
            data.quantity=e.target.value;
        data.amount=data.quantity*data.rateperunit;        
        SwapData(); }} defaultValue={data.quantity} /></td>
        <td><input type="text" className="form form-control" onChange={(e)=>{
            e.preventDefault();
        
            data.rateperunit=e.target.value;
            data.amount=data.quantity*data.rateperunit;
            SwapData();
        }}  /></td>
        
  
      </tr>
})}

  </tbody>
</table>

<hr />

    <label>Enter A Discount</label>
    
    <input type="number" max={100} className="form form-control" onChange={(e)=>{ e.preventDefault();newDiscount(e.target.value); }} placeholder="Enter A Discount in %" />
    <br/>
    <label>Enter A GST Percentage</label>
    
    <input type="number" max={100} onChange={(e)=>{e.preventDefault(); newGst(e.target.value); }} className="form form-control" placeholder="Enter A GST in %" />
<br />
    <label>Enter IGST Percentage</label>
    
    <input type="number" max={100} className="form form-control" onChange={(e)=>{e.preventDefault(); newIGST(e.target.value); }} placeholder="Enter A IGST in %" />
<br />
<br />



<br/>
<hr/>




<button type="submit" className="btn btn-primary">Generate Purchase Order</button>
&nbsp;&nbsp;
<button type="reset" className="btn btn-danger">Clear All Fields</button>    
<br/>
<br/>
</form>
{/* 
<strong style={{"color":"red"}}>Instruction :  </strong>
<h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. All fields are <strong>Mandatory</strong>.</h6>

<h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. If field has not Data mention <strong>NA</strong>.</h6> */}




    </div>



    </>
}

export default PO;
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";
import Select from "react-select";
const DCList = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const location = useLocation();
    const [oldDataTable, newDataTable] = useState([]);
    useEffect(()=>{ 
        const formadata = new FormData();
        formadata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        axios.post(ServerUrl+"aderp/user/dc/DCList.php", formadata).then(
            response => {
                // console.log(response.data); 
                newDataTable(response.data);
            }
        ).catch(err =>  {
            console.log(err);
        })
    },[])

const [oldSNoSerch, newSnoSearch]= useState('');
const SwapArr = oldDataTable.map(data => {
  return { value:data.sno, label:data.clientdeta }
})


const SwapDataFiltered = oldDataTable.filter(data => { 
  if(oldSNoSerch==="")
  {
    return data;
  }
  else {
    
    return data.sno===oldSNoSerch;
  }
})


    return(<>
     <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>

      


  </button>
        <div className="container">
          <Select options={SwapArr} maxMenuHeight={175} onChange={(newValue)=> { 
            newSnoSearch(newValue.value); 
            
            // console.log(oldSNoSerch);
          }} onMenuOpen={()=>{ newSnoSearch(""); }} />

{/* <Select options={SwapArr} maxMenuHeight={175} onInputChange={()=>{newSnoSearch("")}} onChange={(newValue)=> { 
            newSnoSearch(newValue.value);
            // console.log(oldSNoSerch);
          }} /> */}
    <h3>Challan List</h3>

            <br />
            <hr />

            <div className="overflow-auto" style={{"maxHeight":"500px"}}>
        <table class="table table-dark">
  <thead>
    <tr>
      <th scope="col">Challan No</th>
      <th scope="col">Client Descritpion</th>
      <th scope="col">Goods Type</th>
      <th scope="col">Material Data</th>
      {/* <th scope="col">Generate Date</th> */}
      <th scope="col">Action</th>


    </tr>
  </thead>
  <tbody>
    {SwapDataFiltered.map( data=> { 
        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.clientdeta}</td>
        <td>{data.goodtype}</td>
        {/* <td>{data.materialdata}</td> */}
        <td>{data.adate}</td>
        <td>
          {/* <button className="btn btn-primary" onClick={()=> { navigate('updatechallan', {  replace: false, state: { sno : data.sno, aname:location.state.aname  } }) }}>Update</button>  */}
        
         &nbsp; <button className="btn btn-success" onClick={()=>{ navigate('updatechallan/pdf',  { replace: false, state: { sno: data.sno } }); }}>Print</button></td>
      </tr>
    }).reverse()}


  </tbody>
</table>

</div>
        </div>
    </>);
}

export default DCList;
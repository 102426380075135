import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import PIUp from "./PIUp";
const PIList = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
  const [oldComp, newComp] = useState(<></>);

const [oldPOList, newPOList] = useState([]);
const location = useLocation();
console.log(location.state.aname)
const navigate = useNavigate();
const formdata = new FormData();
formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
useEffect(()=>{
axios.post(ServerUrl+"aderp/user/pi/getpo.php", formdata).then(response=>{
    newPOList(response.data);
    console.log(response.data);
}).catch(err => { 
    console.log(err);
})
},[]);
// const UpdateData = (sno) => { toast(<POUp sno={sno} aname={location.state.aname} />) };
return<>
<button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
</button>
<div className="container">
{oldComp}

<table class="table table-dark">
  <thead>
    <tr>
      <th scope="col">PO No</th>
      <th scope="col">To</th>
      <th scope="col">Supplier</th>
      <th scope="col">Email</th>
      <th scope="col">Date</th>
      <th scope="col">Action</th>


    </tr>
  </thead>
  <tbody>
   {oldPOList.map(data=>{
    return <tr>
    <th scope="row">APEPL-PI-{new Date(data.adate).getFullYear()}-{new Date(data.adate).getMonth()+1}-00{data.sno}</th>
    <td>{data.ato}</td>
    <td>{data.asuplier}</td>
    <td>{data.avemail}</td>
    <td>{data.adate}</td>
    <td><button className="btn btn-success" onClick={()=>{ navigate("pdf", {replace:false, state:{ sno: data.sno }})}}>Print</button> &nbsp;<button className="btn btn-primary" onClick={()=>{ newComp(<PIUp sno={data.sno} aname={location.state.aname} />)}}>Modify</button></td>

  </tr>
   }).reverse()}


  </tbody>
</table>
</div>
{/* <ToastContainer hideProgressBar={true} autoClose={false} closeOnClick={false} position="top-center" /> */}
    </>;
}

export default PIList;
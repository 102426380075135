import axios from "axios";
import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import "./App.css";
import { ServerUrl } from "./ADAPI";
const App = () =>  {
  
  const navigate = useNavigate();

  const [oldEmail, newEmail] = useState();
  const [oldPassword, newPassword] = useState();






  const HandleFormSubmit = (event) => {
    event.preventDefault();
    if (Boolean(oldEmail) && Boolean(oldPassword)) {
      const formdata = new FormData();
    formdata.append('api_key', '7288edd0fc3ffcbe93a0cf06e3568e28521687bc');
    formdata.append('aemail', oldEmail);
    formdata.append('apassword', oldPassword);


    axios.post(ServerUrl+"aderp/userdata.php", formdata).then(response=>{
      if(response.data){
        if (response.data.astatus==="active") {
          if(response.data.atype==="admin"){
            localStorage.removeItem("adtoken");
            localStorage.setItem("adtoken", response.data.adtoken);
            const formdata2 = new FormData();
            formdata2.append('uemail', response.data.aemail);
            formdata2.append('utoken', response.data.adtoken);
            axios.post(ServerUrl+"aderp/userlog.php", formdata2).then(response2=>{
             if(response2.data.status === true){
              navigate("/admin", { replace:false, state:{ aname:response.data.aname, aemail:response.data.aemail } })
             }
            }).catch(err => { console.log(err) });

          
            
          }else if(response.data.atype==="user"){
   
            localStorage.removeItem("adtoken");
            localStorage.setItem("adtoken", response.data.adtoken);
            const formdata2 = new FormData();
            formdata2.append('uemail', response.data.aemail);
            formdata2.append('utoken', response.data.adtoken);
            axios.post(ServerUrl+"aderp/userlog.php", formdata2).then(response2=>{
             if(response2.data.status === true){
              navigate("/user", { replace:false, state:{ aname:response.data.aname, aemail:response.data.aemail } })
             }
            }).catch(err => { console.log(err) });
          }
        }else {
          alert("Your Account Has Been Deactivated");
        }
      }else {
        
        alert("Login Failed Invalid Creds") 
      }
    }).catch(err => { console.log(err) });
    }
    
    else 
    {

      alert("All Fields Required");
    }

    
  }

return (<>

<nav className="navbar navbar-dark bg-primary">

<div className="container-fluid">
    <a className="navbar-brand" href="/">ERP - AD Pharma Equipment Pvt. Ltd.</a>

  </div>


</nav>


{/* <div className="container text-center"> */}


<br />
<br />
<br />

  <div className="container w-25 justify-content-center">
<div className="container">
  <form onSubmit={HandleFormSubmit}>
  <h6>Email ID</h6>
  <input type="email" onChange={(event)=>{newEmail(event.target.value)}} className="form form-control"  />
  <h6>Password</h6>
  <input type="password" onChange={(event)=>{ newPassword(event.target.value) }} className="form form-control" />
  <br />
  
  <button type="submit"  className="btn btn-primary w-50">Login</button>
  {/* <button type="submit" className="btn btn-danger">Forgot Password</button> */}
  </form>
</div>


  </div>

{/* </div> */}
</>);
}

export default App;

import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { ServerUrl } from "../../../ADAPI";

const UpdateClient = (props) => {   
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    // const [oldAdress, newAdress] = useState();
    // const [oldComMob, newCompMob] = useState();
    // const [oldCompPhone, newCompPhone] = useState();
    // const [oldPurchPers, newPurchPers] = useState();
    // const [oldPurchPerEmail, newPurchPerEmail] = useState();
    // const [oldAccPerName, newAccPerName] = useState();
    // const [oldAccPerEmail, newAccPerEmail]= useState();
    // const [oldQCMail, newQCMail] = useState();
    // const [oldContPers, newContPers] = useState();
    // const [oldCountryName, newCountryName] = useState();
    // const [oldStateName, newStateName] = useState();
    // const [oldPinCode, newPinCode] = useState();
    // const [oldGSTIN, newGSTIN] = useState(); 

/* GetClientsSno.php */
const [oldTabData, newTabData] = useState([]);
const SwapDataGetter = () =>  {
    const formdata = new FormData();
    formdata.append("sno", props.sno);
    axios.post(ServerUrl+"aderp/admin/GetClientsSno.php", formdata).then(response => { newTabData(response.data);
        console.log(response.data);
     });
}
useEffect(SwapDataGetter,[]);

    const oldAdress = useRef();
    const oldComMob = useRef();
    const oldCompPhone = useRef();
    const oldPurchPers = useRef();
    const oldPurchPerEmail = useRef();
    const oldAccPerName = useRef();
    const oldAccPerEmail = useRef();
    const oldQCMail = useRef();
    const oldContPers = useRef();
    const oldCountryName = useRef();
    const oldStateName = useRef();
    const oldPinCode = useRef();
    const oldGSTIN = useRef();





    const HandleSubmit = (event) => {
        event.preventDefault();
        if(Boolean(oldAdress.current.value) && Boolean(oldComMob.current.value) && Boolean(oldCompPhone.current.value) && Boolean(oldPurchPers.current.value) && Boolean(oldPurchPerEmail.current.value) && Boolean(oldAccPerName.current.value) && Boolean(oldAccPerEmail.current.value) && Boolean(oldQCMail.current.value) && Boolean(oldContPers.current.value) && Boolean(oldCountryName.current.value) && Boolean(oldStateName.current.value) && Boolean(oldPinCode.current.value) && Boolean(oldGSTIN.current.value)){
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append('sno', props.sno);
            formdata.append('aadress', oldAdress.current.value);
            formdata.append('amobno', oldComMob.current.value);
            formdata.append('aphoneno', oldCompPhone.current.value);
            formdata.append('aemail', oldPurchPerEmail.current.value);
            formdata.append('apurchaseperson', oldPurchPers.current.value);
            formdata.append('apurchasepersonemail', oldPurchPerEmail.current.value);
            formdata.append('aaccountperson', oldAccPerName.current.value);
            formdata.append('aacountemail', oldAccPerEmail.current.value);
            formdata.append('aqcemail', oldQCMail.current.value);
            formdata.append('acontactperson', oldContPers.current.value);
            formdata.append('acountry', oldCountryName.current.value);
            formdata.append('astate', oldStateName.current.value);
            formdata.append('apincode', oldPinCode.current.value);
            formdata.append('agstinno', oldGSTIN.current.value);
            axios.post(ServerUrl+"aderp/admin/upClients.php", formdata).then(
                response => { alert(response.data);window.location.reload(); }
            )

        }else {
            alert("All Fields Required");
        }
    }


    return <>
    
    <form onSubmit={HandleSubmit}>
      
        <input type="text" ref={oldAdress}  className="form form-control" placeholder="Enter Company Adress"
        defaultValue={oldTabData.map(data=>{ return data.aadress })}
        />
        <input type="text" ref={oldComMob} maxLength="10" className="form form-control" placeholder="Enter Company Mobile NO"
        defaultValue={oldTabData.map(data=>{ return data.amobno })}
        />
        <input type="text" ref={oldCompPhone}  className="form form-control" placeholder="Enter Company Phone No"
        defaultValue={oldTabData.map(data=>{ return data.aphoneno })}
        />
        <input type="text" ref={oldPurchPers} className="form form-control" placeholder="Purchase Person Name"
        defaultValue={oldTabData.map(data=>{ return data.apurchaseperson })}
        />
        <input type="email" ref={oldPurchPerEmail} className="form form-control" placeholder="Purchase Person Email" 
        defaultValue={oldTabData.map(data=>{ return data.apurchaseemail })}
        />
        <input type="text" ref={oldAccPerName} className="form form-control" placeholder="Account Person Name"
        defaultValue={oldTabData.map(data=>{ return data.aaccountperson })}
        />
        <input type="email" ref={oldAccPerEmail} className="form form-control" placeholder="Account Person Email" 
        defaultValue={oldTabData.map(data=>{ return data.aacountemail })}
        />
        <input type="email" ref={oldQCMail} className="form form-control" placeholder="QC Email" 
        defaultValue={oldTabData.map(data=>{ return data.aqcemail })}
        />
        <input type="text" ref={oldContPers} className="form form-control" placeholder="Contact Person"
        defaultValue={oldTabData.map(data=>{ return data.acontactperson })}
        />
        <input type="text" ref={oldCountryName}  className="form form-control" placeholder="Country Name"
        defaultValue={oldTabData.map(data=>{ return data.acountry })}
        />
        <input type="text" ref={oldStateName} className="form form-control" placeholder="State Name"
        defaultValue={oldTabData.map(data=>{ return data.astate })}
        />
        <input type="text" ref={oldPinCode} className="form form-control" placeholder="Pin Code" 
        defaultValue={oldTabData.map(data=>{ return data.apincode })}
        />
        <input type="text" ref={oldGSTIN} maxLength={15} minLength={15}  className="form form-control" placeholder="GSTIN No"
        defaultValue={oldTabData.map(data=>{ return data.agstinno })}
        />
        <button type="submit" className="btn btn-primary">Add Client</button>&nbsp;<button type="reset" className="btn btn-danger"> Clear</button>

        
    </form> 
    
    </>;
}

export default UpdateClient;
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ServerUrl } from "../../ADAPI.js"
import { useState } from "react";
import exportFromJSON from "export-from-json";

const BackupData = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const GetClientDataExcel = () => {
    
        axios.get(ServerUrl+"aderp/admin/backup.php?f=ClientData").then(response=>{
            const filename = "Client Data";
            const exporttype = "xls";
            exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
        }).catch(error=>{
            console.log(error);
        })
    }

    const GetCourierDataExcel = () => {
    
        axios.get(ServerUrl+"aderp/admin/backup.php?f=CourierData").then(response=>{
            const filename = "Courier Data";
            const exporttype = "xls";
            exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
        }).catch(error=>{
            console.log(error);
        })
    }

    // DeliveryChallan

    const DeliveryChallanExecelData = () => {
    
        axios.get(ServerUrl+"aderp/admin/backup.php?f=DeliveryChallan").then(response=>{
            const filename = "Delievry Challan";
            const exporttype = "xls";
            exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
        }).catch(error=>{
            console.log(error);
        })
    }
// VenProductList


const VendorProductsExecelData = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=VenProductList").then(response=>{
        const filename = "Product";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}


const InProductsExecelData = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=InProductList").then(response=>{
        const filename = "Product In List";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}
// OutProductList

const OutProductsExecelData = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=OutProductList").then(response=>{
        const filename = "Product Out List";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}

// QuotationList

const QuotationListExcelData = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=QuotationList").then(response=>{
        const filename = "Quotation List";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}
// VendorsDataList

const VendorsDataListExcelData = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=VendorsDataList").then(response=>{
        const filename = "Vendors List";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}


const GetADPharmaProductsList = () => {
    
    axios.get(ServerUrl+"aderp/admin/backup.php?f=ADProductList").then(response=>{
        const filename = "AD Products List";
        const exporttype = "xls";
        exportFromJSON({data: response.data, fileName: filename, exportType: exporttype});
    }).catch(error=>{
        console.log(error);
    })
}

return <>
        <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
       <div className="container">

        
<button className="btn btn-primary" onClick={()=>{GetClientDataExcel()}}>Get Clients Excel File</button>&nbsp;&nbsp;&nbsp;&nbsp;
        
<button className="btn btn-primary" onClick={()=>{GetCourierDataExcel()}}>Get Courier Excel File</button>


&nbsp;&nbsp;&nbsp;&nbsp;
        
<button className="btn btn-primary" onClick={()=>{DeliveryChallanExecelData()}}>Get DeliveryChallan Excel File</button>
{/* VendorProductsExecelData */}

&nbsp;&nbsp;&nbsp;&nbsp;
        
<button className="btn btn-primary" onClick={()=>{VendorProductsExecelData()}}>Get Vendor Products Excel File</button>

&nbsp;&nbsp;&nbsp;&nbsp;
        
<button className="btn btn-primary" onClick={()=>{InProductsExecelData()}}>Get In Products Excel File</button>
{/* OutProductsExecelData */}

&nbsp;&nbsp;&nbsp;&nbsp;
        
<button className="btn btn-primary" onClick={()=>{OutProductsExecelData()}}>Get Out Products Excel File</button>

     {/* QuotationListExcelData */}
     <hr/>

        
        <button className="btn btn-primary" onClick={()=>{QuotationListExcelData()}}>Get Quotation List Excel File</button>
     
        &nbsp;&nbsp;&nbsp;&nbsp;
        
        <button className="btn btn-primary" onClick={()=>{VendorsDataListExcelData()}}>Get Vendors Excel File</button>

        &nbsp;&nbsp;&nbsp;&nbsp;
        
        <button className="btn btn-primary" onClick={()=>{GetADPharmaProductsList()}}>Get AD Pharma Products List Excel File</button>



       </div>

       
    </>;
}

export default BackupData;
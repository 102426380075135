import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../ADAPI";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdatePrivilages from "./UpdatePrivilages";
import { useNavigate } from "react-router-dom";
const UserPrivilages = () =>  {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const [oldUserListData, newUserListData] = useState([]);
    const navigate = useNavigate();
    const notify = (sno) => toast(<UpdatePrivilages sno={sno} />);
    useEffect(()=>{

        axios.get(ServerUrl+"aderp/user/UsersTableData.php?api_key=7288edd0fc3ffcbe93a0cf06e3568e28521687bc").then(response => {
            newUserListData(response.data);
        }).catch(err => { console.log(err) });
    }, [])

    console.log(oldUserListData);

    const ChangeStatus = (astatus, id) => {
        if (astatus === "active") {

          const formdata = new FormData();
          formdata.append('id', id);
          formdata.append('astatus', "inactive");
            axios.post(ServerUrl+"aderp/admin/DisableUser.php", formdata).then(response => {
                
                    window.location.reload();
                
            }).catch(err => { console.log(err) });
        }else if(astatus === "inactive")
        {
          const formdata = new FormData();
          formdata.append('id', id);
          formdata.append('astatus', "active");
            axios.post(ServerUrl+"aderp/admin/DisableUser.php", formdata).then(response => {
                
                    window.location.reload();
                
            }).catch(err => { console.log(err) });
        }
    }


    return <>
      <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>
    <div className="container">
    <h1>Change Users Previlages</h1>
    <table class="table">
  <thead>
    <tr>
      <th scope="col">Sr. No</th>
      <th scope="col">User Name</th>
      <th scope="col">Email</th>
      <th scope="col">Type</th>
      <th scope="col">Action</th>

    </tr>
  </thead>
  <tbody>
    {oldUserListData.map(data => {
        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.aname}</td>
        <td>{data.aemail}</td>
        <td>{data.atype}</td>
        <td><button className={data.astatus === "active" ? "btn btn-success" : "btn btn-danger" } onClick={()=>{ChangeStatus(data.astatus, data.sno)}}>{data.astatus === "active" ? "Active" : "Inactive" }</button></td>
        <td><button className="btn btn-primary" onClick={()=> { notify(data.sno); }}>Change Privilages</button></td>

      </tr>
    })}


  </tbody>
</table>
    </div> <ToastContainer closeOnClick={false} hideProgressBar={true} position="top-center" /></>;
}
export default UserPrivilages;

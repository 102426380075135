import {  useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServerUrl } from "../../../ADAPI";
import axios from "axios";

const ReparWoPdf = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();

    const [oldData, newData] = useState([]);
    useEffect(()=>{ 
        const formData = new FormData();
        formData.append("api_key", "SwapnilAllowed");
        formData.append("sno", location.state.sno);
        axios.post(ServerUrl+"aderp/admin/repairworkorder/getsnowo.php", formData).then(response =>{
            newData(response.data);
        });
    },[])
    console.log(oldData);
    const [oldDataProDesc, newDataProDesc] = useState([]);
    useEffect(()=>{
      newDataProDesc(oldData.map(data=>{
        return JSON.parse(data.prodDetails)
      }));
    },[oldData])

    function pad(n, length) {
        var len = length - (''+n).length;
        return (len > 0 ? new Array(++len).join('0') : '') + n
    }

    return <>
        <>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-1wig{font-weight:bold;text-align:left;vertical-align:top}\n.tg .tg-baqh{text-align:center;vertical-align:top}\n.tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}\n.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 815 }}>
      <colgroup>
        <col style={{ width: "272.083333px" }} />
        <col style={{ width: "308.083333px" }} />
        <col style={{ width: "235.083333px" }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-c3ow" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>REPAIRING WORK ORDER</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>SERIAL NO :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>WO-RW-{new Date().toLocaleDateString('en-US', {year:'2-digit'})}-{parseInt(new Date().toLocaleDateString('en-US', {year:'2-digit'}))+1}/{pad(oldData.map(data=>{
            return data.sno
          }), 3)}</td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>COMPANY NAME :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>{oldData.map(data=> { return data.comapanyname })}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>ADDRESS :</span>
          </td>
          <td className="tg-0lax" colSpan={2}>{oldData.map(data=>{ return data.address })}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>MAIL ID</span> :
          </td>
          <td className="tg-0lax" colSpan={2} >{oldData.map(data=>{
            return data.mailid
          })}</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>PURCHASE ORDER NO :</span>{oldData.map(data=>{
                return data.purchaseorderno
            })}
          </td>
          <td className="tg-0lax" />
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>DATED :</span>{oldData.map(data=>{
                return data.dated
            })}
          </td>
        </tr>
       <tr>
          <td className="tg-baqh" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>DESCRIPTION</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>QTY</span>
          </td>
        </tr>
 


        {/* <tr>
          <td className="tg-0lax" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>{oldData.map(data=>{
                return data.prodesc
            })}</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>{oldData.map(data=>{
                return data.qty
            })}</span>
          </td>
        </tr> */}
       {oldDataProDesc ? oldDataProDesc.map(adata=>{
          return adata ? adata.map(bdata=>{
            return <tr>
            <td className="tg-0lax" colSpan={2}>
              <span style={{ fontWeight: "bold" }}>
                  {bdata.prodDesc}</span>
            </td>
            <td className="tg-baqh">
              <span style={{ fontWeight: "bold" }}>{bdata.qty}</span>
            </td>
          </tr>
          }) : null
        }) : null}

        <tr>
          <td className="tg-0lax" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>REMARK : </span>{oldData.map(data=>{
                return data.remark
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>SPECIAL INSTRUCTION :</span>{oldData.map(data=>{
                return data.specialinstru
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>TARGET DATE :</span>
          </td>
          <td className="tg-0lax" >
          {oldData.map(data=>{
                return data.targetdate
            })}
          </td>
          <td className="tg-1wig">Immediately</td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>DELIVERY PERIOD :</span>
          </td>
          <td className="tg-0lax" >
          {oldData.map(data=>{
                return data.delieveryperiod
            })}
          </td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Immediately</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Dispatch Date :</span>
          </td>
          <td className="tg-0lax" colSpan={2} >
          {oldData.map(data=>{
                return data.dispatchdate
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>Prepared By :</span>
            <br />
            Name :           {oldData.map(data=>{
                return data.prepby
            })}<br />
            Sign : <br />
          </td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>CC : Store Dept</span>
            <br />
            Name : {oldData.map(data=>{
                return data.store_dept_per
            })}<br />
            Sign:
            <br />
          </td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>CC : Service Dept.</span>
            <br />
            Name : {oldData.map(data=>{
                return data.service_dept_per
            })}<br />
            Sign :<br />
            <br />
            <br />
          </td>
        </tr>
        <tr>
          <td className="tg-baqh" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>STATUS</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax">1. By Courier (Details)</td>
          <td className="tg-0lax" colSpan={2} />
        </tr>
        <tr>
          <td className="tg-0lax">2. By Hand (Details)</td>
          <td className="tg-0lax" colSpan={2} />
        </tr>
        <tr>
          <td className="tg-0lax">3. Work Status</td>
          <td className="tg-0lax" colSpan={2} />
        </tr>
      </tbody>
    </table>
  </div>
</>

    </>;
};

export default ReparWoPdf;
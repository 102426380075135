import { Form, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../../../../ADAPI";
import {Converter} from "any-number-to-words";
// Test
import  html2pdf  from "html2pdf.js";

const FPO = () => 
{
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
//Test
 const printDocument = async () => {


  let opt = {
    margin:       1,
    filename:     'myfile.pdf',
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 2 },
    jsPDF:        { format: 'A4', margin:20 }
  };

  const el = document.getElementById('popdf');
  const d = html2pdf().set( opt ).from( el ).toPdf().output('datauristring').then(
    resultdata => { 
      // console.log(resultdata)
      // const formData = new FormData();
      // formData.append('file', resultdata);
      // formData.append('avemail', oldPOList.map(data=> { return data.avemail }));
      const dataObj = { file: resultdata, avemail: oldPOList.map(data=> { return data.avemail }) }
      axios.post("https://walkinstabilitychamber.in/aderpmailapi/pomail.php", dataObj).then(response=>{ alert("Mail Sent Successfully") }).catch(err=>{console.log(err)})
      
    }
  );

 
}


// TestEnd


const location = useLocation();
const [oldPOList, newPOList] = useState([]);
const converter = new Converter();
const formdata = new FormData();
    formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formdata.append("sno",location.state.sno);

useEffect(()=>{
    axios.post(ServerUrl+"aderp/user/po/getpobysno.php", formdata).then(response=>{
        newPOList(response.data);
        console.log(response.data);
    }).catch(err => { 
        console.log(err);
    })
},[]);


let count=1;
let Swap =[];
let total = 0;

let discper = 0;
let prad = 0;

let gstper = 0;
let pragst = 0;

let igstper = 0;
let praigst = 0;
return<>

<div id="popdf">
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}\n.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n.tg .tg-dvpl{border-color:inherit;text-align:right;vertical-align:top}\n"
    }}
  />
  {/* 632 */}
  <table className="tg"  style={{ tableLayout: "fixed", width: 720 }}>
    <colgroup>
      <col style={{ width: "37.75px" }} />
      <col style={{ width: "38.75px" }} />
      <col style={{ width: "38.75px" }} />
      <col style={{ width: "38.75px" }} />
      <col style={{ width: "38.75px" }} />
      <col style={{ width: "98.75px" }} />
      <col style={{ width: "35.75px" }} />
      <col style={{ width: "33.75px" }} />
      <col style={{ width: "97.75px" }} />
      <col style={{ width: "108.75px" }} />
      <col style={{ width: "33.75px" }} />
      <col style={{ width: "30.75px" }} />
    </colgroup>
    <thead>
      <tr>
        <th className="tg-c3ow" colSpan={12}>
          <span style={{ fontWeight: "bold" }}>PURCHASE ORDER</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="tg-0pky" colSpan={6} rowSpan={2}>
          <span style={{ fontWeight: "bold" }}>To,</span>
          <br />
          {oldPOList.map(data=> { return data.ato })}
          <br/>
          <label onClick={printDocument}>{oldPOList.map(data=> { return data.avemail })}</label>
          {/* 
          <br />
          <br />
          <br /> */}
        </td>
        <td className="tg-0pky" colSpan={6} rowSpan={2}>
          <span style={{ fontWeight: "bold" }}>Supplier,</span>
          <br />
          {oldPOList.map(data=> { return data.asuplier })}

          <br />
          <br />
          {/* <br />
          <br />  */}
        </td>
      </tr>
      <tr></tr>
      <tr>
        <td className="tg-0pky" colSpan={6} rowSpan={4}>
          <span style={{ fontWeight: "bold" }}>Dispatch To/ Delievery, </span>
          <br />
          <br />
          AD PHARMA EQUIPMENT PVT.LTD.
          <br />
          Regd. Off. / Head Office
          <br />
          Shop No. 05, Dream Art, Modern Colony, <br />
          Saradwadi Road, Tal Sinnar, Dist. Nashik,
          <br />
          <br />
          Maharashtra, India. - 422103
          <br />
          <br />
          GSTIN : 27AAPCA3611H1ZQ
        </td>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Purchase Order No</span>
          <br />
          APEPL-PO-{new Date(oldPOList.map(data => { return data.adate })).getFullYear() }-{new Date(oldPOList.map(data => { return data.adate })).getMonth()+1 }-00{oldPOList.map(data => { return data.sno })}
        </td>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Purchase Order Date</span>
          <br />
          {oldPOList.map(data => { return data.adate })}
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Project Codes</span>
          <br />
          {oldPOList.map(data => { return data.aprojectcods })}
        </td>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Mode/Terms of Payment</span>
          <br />
          {oldPOList.map(data => { return data.atermofpay })}
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Supplier Ref./Date</span>
          <br />
          {oldPOList.map(data => { return data.asuppliref })}
        </td>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>P.R. No. &amp; Date.</span>
          <br />
          {oldPOList.map(data => { return data.aprno })}
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Dispatch Through</span>
          <br />
          {oldPOList.map(data => { return data.adispatch })}
        </td>
        <td className="tg-0pky" colSpan={3}>
          <span style={{ fontWeight: "bold" }}>Other References</span>
          <br />
          {oldPOList.map(data => { return data.aotherref })}
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={12} />
      </tr>
      <tr>
        <td className="tg-0pky">
          <span style={{ fontWeight: "bold" }}>Sr. NO</span>
        </td>
        <td className="tg-0pky" colSpan={7}>
          <span style={{ fontWeight: "bold" }}>
            Item Code &amp; Description{" "}
          </span>
        </td>
        <td className="tg-0pky">
          <span style={{ fontWeight: "bold" }}>Quantity</span>
        </td>
        <td className="tg-0pky">
          <span style={{ fontWeight: "bold" }}>Rates/Unit(Rs)</span>
        </td>
        <td className="tg-0pky" colSpan={2}>
          <span style={{ fontWeight: "bold" }}>Amount </span>
        </td>
      </tr>


      {oldPOList.map(data => { Swap = JSON.parse(data.amateitem); })}
   {Swap.map(data => { 
    total= parseFloat(total)+parseFloat(data.amount);
    return       <tr>
    <td className="tg-0pky" >{count++}</td>
    <td className="tg-0pky" colSpan={7} >{data.name}
    <br/>{data.desc}</td>
    <td className="tg-0pky" >{data.quantity}</td>
    <td className="tg-0pky" >{data.rateperunit}</td>
    <td className="tg-0pky" colSpan={2} >{data.amount}</td>
  </tr>
    })}   

<tr>
        <td className="tg-0pky" colSpan={10}>
          <span style={{ fontWeight: "bold" }}>Sub Total</span> (Amount)
        </td>
        <td className="tg-0pky" colSpan={2} >{total}</td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={10}>
          <span style={{ fontWeight: "bold" }}>Discount</span> {oldPOList.map(data => { discper=data.adiscount;
          prad = parseFloat(total)-((parseFloat(total)*parseFloat(discper))/100)
          return data.adiscount  })}% ( Amount After Discount )
        </td>
        <td className="tg-0pky" colSpan={2}>{parseFloat((parseFloat(total)*parseFloat(discper))/100).toFixed(2)}</td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={10}>
          <span style={{ fontWeight: "bold" }}>GST</span> {oldPOList.map(data=>{ 
              gstper=data.agst;
              pragst = parseFloat(prad)+((parseFloat(prad)*parseFloat(gstper))/100)
              return data.agst;
           })}%
        </td>
        <td className="tg-0pky" colSpan={2} >{parseFloat((parseFloat(prad)*parseFloat(gstper))/100).toFixed(2)}</td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={10}>
          <span style={{ fontWeight: "bold" }}>IGST </span>
          <span style={{ fontWeight: "normal" }}>{oldPOList.map(data=>{ 
            igstper=data.aigst;
            praigst = parseFloat(pragst)+((parseFloat(pragst)*parseFloat(igstper))/100)
            return data.aigst;
           })}%</span>
        </td>
        <td className="tg-0pky" colSpan={2} >{parseFloat((parseFloat(pragst)*parseFloat(igstper))/100).toFixed(2)}</td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={10}>
          <span style={{ fontWeight: "bold" }}>Total</span>
        </td>
        <td className="tg-0pky" colSpan={2} >{parseFloat(praigst).toFixed(2)}</td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={12}>
          <span style={{ fontWeight: "bold" }}>
            Value In Word :&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>
            {converter.toWords(parseFloat(praigst).toFixed(2))}
          </span>
        </td>
      </tr>
      <tr>
        <td className="tg-c3ow" colSpan={12}>
          <span style={{ fontWeight: "bold" }}>TERMS &amp; CONDITIONS</span>
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={12}>
          {" "}
          <span style={{ fontWeight: "bold" }}> After PO &amp; Payment</span>
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={7} rowSpan={3}>
          <span style={{ fontWeight: "bold" }}>COMPANY GSTIN : </span>
          27AAPCA3611H1ZQ
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>COMPANY PAN : </span>AAPCA3611H
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>COMPANY CIN : </span>
          U29309MH2017PTC292871
        </td>
        <td className="tg-dvpl" colSpan={5} rowSpan={3}>
          <span style={{ fontWeight: "bold" }}>
            For AD Pharma Equipment Pvt. Ltd.
          </span>
          <br />
          {oldPOList.map(data=>{ return data.auser })}
          <br />
          {new Date().toLocaleDateString()}
          
          <br />
          <br />
          <br />
          Authorised Signatory
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
    </tbody>
  
  </table>
  <label>Computer Generated Purchase Order</label>
</div>


    </>;
}

export default FPO;
import { useState } from "react";
import ProductUp from "./ProductUp";
import DiscountUp from "./DiscountUp";


const UpdateQt = ({sno}) => {
    
    // console.log(sno);
    const [oldChangeUpdate, newChangeUpdate] = useState("discount");
    let comp = <></>;
    if(oldChangeUpdate==="product"){
        comp = <ProductUp sno={sno} />;
    }else if(oldChangeUpdate==="discount"){
        comp = <DiscountUp sno={sno} />
    }else{
        comp = <></>;
    }
    return (
        <div>
            <select onChange={(e)=>{ newChangeUpdate(e.target.value) }} >
                <option value="product">Products</option>
                <option value="discount" selected>Discount</option>
            </select>        
            {comp}
        </div>
    )
}


export default UpdateQt;
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
const UnderWCMS = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.api_key);
    const [oldDateOne, newDateOne] = useState();


    const SwapD1 = new Date(oldDateOne);
    const dateS = SwapD1.getDate();
    const monthS = SwapD1.getMonth()+1;
    const yearS = SwapD1.getFullYear()+1;
    const EndDate = yearS+"-"+monthS+"-"+dateS;
    const SwapD2 = new Date(EndDate);
    const [oldNoVisits, newNoVisits] = useState(0);
    let noofvisits = oldNoVisits;

    const DataArray = [
        {0:"jan"},{1:"feb"},{2:"march"},{3:"April"},{4:"May"},{5:"June"},{6:"July"},{7:"Aug"},{8:"Sept"},{9:"Oct"},{10:"Nov"},{11:"Dec"}
    ];

    return(<div className="container">
            <strong>Enter No of Visits</strong>
            <label>Enter valid Digit</label><br />
            <input type="text" onChange={(event)=>{ newNoVisits(event.target.value); }}></input>
            <br />
            <input type="date" onChange={(event)=>{ newDateOne(event.target.value) }} />
            <br />
            <label>AMC Will Valid Till <strong>{EndDate}</strong></label>
    </div>);
}

export default UnderWCMS;
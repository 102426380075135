import { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
const UpdateChallan = () => { 
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const chlno = location.state.sno;
    const [oldProData, newProData] = useState([]);
    const navigate = useNavigate();

    
const [oldDataChallan, newDataChallan] = useState([]);
const [oldClientName, newClientName] = useState();
useEffect(()=>{
  const formdata = new FormData();
  formdata.append("sno", chlno);
  axios.post(ServerUrl+"aderp/user/dc/getchallandata.php", formdata).then(response => 
    {
      newDataChallan(response.data);

    });
},[]);
// newClientName(oldDataChallan.map(data=> { return data.clientdeta.split("\r\n")[0]; })[0])
useEffect(()=>{
    if(oldDataChallan.length>0){
        newClientName(oldDataChallan.map(data=> { return data.clientdeta.split("\r\n")[0]; })[0])
    }
},[oldDataChallan])
// Client Name for Updating the Stack in Out Store
console.log(oldClientName);

// Challan Data
const [oldChallanArray, newChallanArray] = useState([]);
useEffect(()=>{
    if(oldDataChallan.length>0){
        if(oldDataChallan.map(data=> { return JSON.parse(data.materialdata) }).length>0){
            newChallanArray(oldDataChallan.map(data=> { return JSON.parse(data.materialdata) })[0])
        }
        
       
    }
},[oldDataChallan]);

console.log(oldChallanArray);
/// Challan Product Array Got

/*
useEffect(
    ()=>{
        axios.get(ServerUrl+"aderp/user/store/getproduct.php").then(response=> {
            newProData(response.data);
        })
    }, []
)
let proData = oldProData.map(data => {
    return { value: data.productname, label: data.productname }
})

 */


const [oldAData, newAData] = useState([]);
useEffect(
    ()=>{
        axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowStack.php").then(response=> {
            newAData(response.data);
            console.log(response.data);
        })
    },[]
)
// // Used
// const [oldNData, newNData] = useState([]);
// useEffect(
//     ()=>{
//         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowOut.php").then(response=> {
//             newNData(response.data);
//             console.log(response.data);
//         })
//     },[]
// )
// const [oldNotData, newNotData] = useState([]);
// useEffect(
//     ()=>{
//         axios.get(ServerUrl+"aderp/user/store/ProductCalc/ShowNonSel.php").then(response=> {
//             newNotData(response.data);
//             console.log(response.data);
//         })
//     },[]
// )

    
let proArray = oldAData.map(data=>{
    return {value : data.productname, value2:parseInt(data.available_stock),label : data.productname+"   [ Available Quantity  "+data.available_stock +"]" }
});

// oldAData.map(data=>{
//     // console.log(data.productname)
//      oldNData.map(ndata=>{
//         // console.log(ndata.sin);
//          if(ndata.productname===data.productname && data.sin-ndata.sout >0)
//         {
//             proArray.push({value : data.productname, value2: parseInt(data.sin-ndata.sout), label : data.productname+"  [ Available Quantity "+(data.sin-ndata.sout) +"]" });
//         }
//     })
// })


// oldNotData.map(data=>{
//     proArray.push({value : data.productname, value2:parseInt(data.quantity),label : data.productname+"   [ Available Quantity  "+data.quantity +"]" })
// })

// console.log(proArray);

const [oldProArr, newProArr] = useState([]);
let count =0;
const [oldSwapArr, newSwapArr] = useState([]);
useEffect(()=>{
    newSwapArr( oldProArr.map(data=>{ return {name:data.value, quantity: 0, nos:0}}))
}, [oldProArr])

const SwapData = () => {
    console.log(oldSwapArr);
}

/*  Update Challon Material List By Challon No 
    on Update Redirected to DC Print Page.
    /admin/store/dc/pdf -- Route for Generating PDF
    */
const UpdateDataAndGeneratePdf = () => { 
    let flag = true;

    if(oldChallanArray.length>0){
        oldChallanArray.map(data=>{
            // return  {"proname":data.name, "quantity": -parseInt(data.quantity)}
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("productname", data.name);
            formdata.append("companyname", oldClientName);
            formdata.append("quantity", -parseInt(data.quantity));
            axios.post(ServerUrl+"aderp/user/store/addproductoutdc.php", formdata).then(reponse => {
                console.log(reponse.data);
                if(reponse.data === "fail"){
                    alert("Failed To Add");
                    flag = false;
                }
            }).catch(err => { console.log(err); })

        })

        oldSwapArr.map(data=>{
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("productname", data.name);
            formdata.append("companyname", oldClientName);
            formdata.append("quantity", data.quantity);
            axios.post(ServerUrl+"aderp/user/store/addproductoutdc.php", formdata).then(reponse => {
                console.log(reponse.data);
                if(reponse.data === "fail"){
                    alert("Failed To Add");
                    flag = false;
                }
            }).catch(err => { console.log(err); })
        })
    }
    
    if(flag){
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", chlno);
        formdata.append("updatedby", location.state.aname);
        formdata.append("productarr", JSON.stringify(oldSwapArr));
        axios.post(ServerUrl+"aderp/user/dc/UpdateChallan.php", formdata).then(
            response => {   
                alert(response.data);
                navigate("pdf", { replace:false, state:{ sno:location.state.sno } });
            }
        )
    }



}





const [oldStatus, newStatus] = useState(false);

    return <>
 <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>

    <div className="container">
            <label>Select Products</label>
        <Select isDisabled={oldStatus}
    isMulti
    options={proArray}
    onChange={(selectedvalue) => { newProArr(selectedvalue); }}
 />

  
<br />
    <table onClick={()=> {newStatus(true); }} className="table">
  <thead>
    <tr>
      <th scope="col">Sr. No</th>
      <th scope="col">Product  Name</th>

      <th scope="col">Quantity</th>
      <th scope="col">Nos Pack./Box</th>
    </tr>
  </thead>
  <tbody>
   { oldSwapArr.map(data=>{
    
    

    // console.log(data);
    return (<tr>
    <th scope="row">   {count++}</th>
    <td>{data.name}</td>


    <td>
        <input type="number" onChange={(event)=>{ data.quantity=event.target.value;
       SwapData();
        }} className="form form-control"
        
        max={proArray.filter(dataset=>dataset.value===data.name).map(data => { return data.value2 })}

        placeholder={"Available Quantity "+proArray.filter(dataset=>dataset.value===data.name).map(data => { return data.value2 })}
        />
    </td> 
    
    <td>
        <input type="number" onChange={(event)=>{ data.nos=event.target.value;
       SwapData();
        }} className="form form-control" />
    </td> 
 
  </tr>
  )
   
   })
   }
  </tbody>
</table>
<button className="btn btn-primary" onClick={UpdateDataAndGeneratePdf}>Update</button>

</div>


    </>;
}

export default UpdateChallan;
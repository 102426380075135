import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import Select from 'react-select';

const Calibration = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
// Client Data Recieving
const [oldTabData, newTabData] = useState([]);
const SwapDataGetter = () =>  {
    axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newTabData(response.data);
     });
}
useEffect(SwapDataGetter,[]);

// Clientname Select Array
const ClientSelectArray = oldTabData.map((item) => { return { label : item.acompanyname, value : item.acompanyname, value2 : item.aadress, value3 : item.acontactperson, value4 : item.amobno, value5 : item.aqcemail } });





// Variables
const [comapanyname, setcomapanyname] = useState();
const [address, setaddress] = useState();
const [email, setemail] = useState();

// Next Variables
const [prodesc, setprodesc] = useState();
const [qty, setqty] = useState();
const [remark, setremark] = useState();
const [specialinstru, setspecialinstru] = useState("Completed Within Date");
const [targetdate, settargetdate] = useState();
const [termandcond, settermandcond] = useState("Calibration 1 Hrs & 24 Hrs Validation will be carried out.Calibration will be carried out at 1 Set Point only.Calibration and Validation will be carried out with 8 probes.Calibration and Validation Certificate will be provided on Successful completion of Calibration.Calibration and Validation will be carried out with calibrator having traceability to ERTL.");

const [sercvicedept, setsercvicedept] = useState();

const [prodDetails, setprodDetails] = useState([]);

const HandleFormSubmit = (e) => {
    e.preventDefault();
   if(Boolean(comapanyname)){
    const formData = new FormData();
    formData.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formData.append("companyname", comapanyname);
    formData.append("address", address);
    formData.append("mailid", email);
    // formData.append("prodesc", prodesc);
    // formData.append("qty", qty);
    formData.append("prodDetails", JSON.stringify(prodDetails))
    formData.append("remark", remark);
    formData.append("specialinstru", specialinstru);
    formData.append("targetdate", targetdate);
    formData.append("termandcond", termandcond);
    formData.append("prepby",location.state.aname);
    formData.append("sercvicedept", sercvicedept);
    axios.post(ServerUrl+"aderp/admin/calworkorder/createcalworkoder.php", formData).then((response) => {
        console.log(response.data);
        if (response.data === "done") {
                alert("Work Order Created Successfully");
                window.location.reload();
        } else  {

        }
            
        
    }).catch((error) => { console.log(error); });
   }
}
function getDateAfterOneYearMinusOneDay(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);
  
    // Add one year to the date
    date.setFullYear(date.getFullYear() + 1);
  
    // Subtract one day from the date
    date.setDate(date.getDate() - 1);
  
    // Format the resulting date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }

    return <>
                 <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

    <div className="container">


<form onSubmit={HandleFormSubmit}>
                <div className="container">
                <button className="btn btn-success" onClick={()=>{navigate("list", { replace: false, state : { aname : location.state.aname } })}}>List</button>
        <br/>
        <hr/>
                    <div className="row">
                        
                                                    <div className="col-sm">


                                                    <Select options={ClientSelectArray} onChange={(newValue)=>{
                                        setcomapanyname(newValue.value);
                                        setaddress(newValue.value2);
                                        setemail(newValue.value5);
                                    }} />


                            <label>Address</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setaddress(e.target.value) }} defaultValue={address} />

                            <label>Email Id</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setemail(e.target.value) }} defaultValue={email} />
             


                           <label>Product Description</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setprodesc(e.target.value) }}  value={prodesc} />

                            <label>Quantity</label>
                            <input type="number" className="form form-control" onChange={(e)=>{ setqty(e.target.value) }} value={qty} />

                                <button className="btn btn-primary" onClick={(e)=>{
                                    e.preventDefault();
                                    setprodDetails([...prodDetails, {prodesc:prodesc, qty:qty}]);
                                    setprodesc("");
                                    setqty("");
                                } } type="button">Add</button>
                      <br/>
                      
                            <label>Remark</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setremark(e.target.value) }}  />

                            <label>Special Instruction</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setspecialinstru(e.target.value) }} defaultValue={specialinstru} />

                            <label>Target Date</label>
                            <input type="date" className="form form-control" onChange={(e)=>{ settargetdate(e.target.value) }}  />

                            <label>Terms & Condition</label>
                            <textarea type="text" className="form form-control" onChange={(e)=>{ settermandcond(e.target.value) }}  defaultValue={termandcond} />
                                
             
             
             </div>
                        
                        
                        
                        
                        
                        
                        
                        <div className="col-sm">

                                    <label>Service Dept. Person Name</label>
                            <input type="text" className="form form-control" onChange={(e)=>{ setsercvicedept(e.target.value) }}  />

<br/>
<button className="btn btn-primary" type="submit">Create Work Order</button>
&nbsp;<button className="btn btn-danger" type="reset">Reset</button>
                        </div>

                    </div>
                </div>
 </form>

 <table className="table table-dark">
    <thead>
            <tr>
                <th>Product Description</th>
                <th>Quantity</th>
            </tr>
    </thead>
    <tbody>
        {prodDetails.map((item, index)=>{
            return <tr>
                <td>{item.prodesc}</td>
                <td>{item.qty}</td>
            </tr>
        })}
    </tbody>
</table>
    </div>




    </>;
}
/*
        $companyname = $_POST['companyname'];
        $address = $_POST['address'];
        $mailid = $_POST['mailid'];

        $prodesc = $_POST['prodesc'];
        $qty = $_POST['qty'];
        $remark = $_POST['remark'];
        $specialinstru = $_POST['specialinstru'];
        $targetdate = $_POST['targetdate'];
        $termandcond = $_POST['termandcond'];
        $prepby = $_POST['prepby'];
        $sercvicedept = $_POST['sercvicedept'];
*/

export default Calibration;
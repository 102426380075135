import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {  useNavigate } from "react-router-dom";
import Select from "react-select";
import { ServerUrl } from "../../ADAPI";
const ADQuot = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
  const navigate = useNavigate();
  // Cllient Selecter
  const proRefSwap = useRef();
  const [oldClientData, newClientData] = useState([]);
  useEffect(()=>{
      axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newClientData(response.data);
      });
  },[]);

  const clientArrayList = oldClientData.map(data=>{ return { label: data.acompanyname, value : data.acompanyname, value2:data.sno } })
  // Client Name Selected
  const [oldClientName, newClientName] = useState();
  // Client Data Getter
  const [oldClientSno, newClientSno] = useState();
  const [oldClientPro, newClientPro] = useState([]);
  useEffect(()=>{
    const formdata = new FormData();
    formdata.append("api_key", "SwapnilAllowed");
    formdata.append("aclient", oldClientName)
    axios.post(ServerUrl+"aderp/admin/quot/getClientPro.php", formdata).then(response=>{ 
      newClientPro(response.data);
      proRefSwap.current.clearValue();
      newStatus(false);
      console.log(response.data);
     })
  },[oldClientName]);

// Array For Selecting Product;
let clientProArray = oldClientPro.map(data =>{ return { label : data.productname, value: data.sno, value2:data.aproprice, value3:data.ahsncode, value4:data.aprodesc } }) 

let count=1;

const [oldSelVal, newSelVal] = useState([]);
const [oldProSelArr, newProSelArr] = useState([]);
/*
useEffect(()=>{
  newProSelArr(oldSelVal.map(data=> { return { productname:data.label,sno:data.value, productprice:data.value2, hsncode: data.value3, desc: data.value4, productquanity:0 } }))

},[oldSelVal]);
*/
useEffect(()=>{
  newProSelArr(oldSelVal.map(data=> { return { productname:data.label.replace(/(\r\n|\n|\r)/gm, " "),sno:data.value, productprice:data.value2, hsncode: data.value3, desc: data.value4.replace(/(\r\n|\n|\r)/gm, " "), productquanity:0 } }))

},[oldSelVal]);

const SwapShow = ()=>{
  console.log(oldProSelArr);
}
const [oldStatus, newStatus] = useState(false);


// Quotation Type Array
const QuotTypeArr = [{label: "AMC And Calibration", value: "amc&cal"}, { label:"CMC and Calibration", value: "cmc&cal" },  { label:"Calibration And Validation", value: "cal&val" }, {label : "Repairing/Service/Spare Parts", value:"r&s"}, { label:"Equipment/Instruments/Chamber", value:"e&c" }];

const [oldQuotType, newQuotType] = useState();
const [oldDiscount, newDiscount] = useState();
const HandleFormSubmit = (event) => { 
  event.preventDefault();
if(Boolean(oldClientSno) && Boolean(oldClientName) &&  Boolean(oldQuotType) && Boolean(oldDiscount)) {
  const formdata = new FormData();
  formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
  formdata.append("aclient", oldClientSno);
  formdata.append("aclientname", oldClientName);
  formdata.append("aquottype", oldQuotType);
  formdata.append("aquotprod", JSON.stringify(oldProSelArr));
  formdata.append("adiscount", oldDiscount);
  axios.post(ServerUrl+"aderp/admin/quot/addquot.php", formdata).then(response => { 
    alert(response.data);
    console.log(response.data);
    window.location.reload();
  }).catch(err => { 
    console.log(err);
  })
}else {
  alert("Alll Fields Required");
}
} 

return<>  
  <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
  



  <div className="container">
<button className="btn btn-primary" onClick={()=> { navigate("list", { replace:false }) }}>Quotation List</button>
<hr />
<form onSubmit={HandleFormSubmit}>
<label>Select Quotation Type</label>

<br/>
<Select options={QuotTypeArr} onChange={(newValue)=>{ newQuotType(newValue.value); }} />
<br/>

    <label>Select Client Name</label>
<Select options={clientArrayList} onChange={(newValue)=>{ newClientName(newValue.value); newClientSno(newValue.value2);} }  />
<br/>
<label>Select Products</label>
<Select options={clientProArray} isMulti={true} isDisabled={oldStatus} ref={proRefSwap} onChange={(newValue)=>{ newSelVal(newValue); }} />
<br/>
<br/>

<table onClick={()=>{ newStatus(true); }} className="table table-bordered table-dark">
  <thead>
    <tr>
      <th scope="col">Sr.No.</th>
      <th scope="col">Product Name</th>
      <th scope="col">Product Price</th>
      <th scope="col">Product Quanity</th>
    </tr>
  </thead>
  <tbody>
{oldProSelArr.map(data => { return     <tr>
      <th scope="row"> {count++} PrID :{data.sno}</th>
      <td>{data.productname}</td>
      <td>{data.productprice}</td>
      <td><input type="number" className="form form-control" onChange={(e)=>{ data.productquanity=e.target.value; SwapShow()}} /></td>
    </tr>

})}


  </tbody>
</table>
<br/>
<br/>
<label>Enter A Discount</label>
<input className="form form-control"  type="number" placeholder="Enter A Discount Amount Percentage" max={100} onChange={(e)=>{ newDiscount(e.target.value); }} />


<br/>
<button className="btn btn-primary" type="submit">Generate Quotation</button>
&nbsp;&nbsp;
<button className="btn btn-danger" type="reset">Clear</button>
</form>
  </div>
  </>;
}
export default ADQuot;
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ServerUrl } from "../../ADAPI";


const ADProList =() => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const navigate = useNavigate();
    const [oldTabData, newTabData] = useState([]);
    useEffect(()=>{ axios.post(ServerUrl+"aderp/admin/adgetpro.php?api_key=SwapnilAllowed").then(response => { newTabData(response.data) }).catch(err => { console.log(err) }) },[]);


    const SwapArrayOptionSearch = oldTabData.map(data=>{ return { label: data.aclient, value:data.aclient } })

    const [oldValData, newValData] = useState("");
    let TabData = [];
    TabData= oldTabData.filter(data => { 
        if (oldValData==="") {
            return data;
        } else {
            return data.aclient===oldValData;
        }
    })

    return<>
    
    <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>


<div className="container-fluid">
<Select options={SwapArrayOptionSearch} onChange={(newValue)=> { newValData(newValue.value); }} />

<table class="table table-bordered table-dark">
  <thead>
    <tr>
      <th scope="col">Sr No</th>
      <th scope="col">Client Name</th>

      <th scope="col">Product Category</th>
      <th scope="col">Product Name</th>
      <th scope="col">HSN/SAC Code</th>
      <th scope="col">Price</th>
      <th scope="col">Product Description</th>
      <th scope="col">Action</th>



    </tr>
  </thead>
  <tbody>
{TabData.map(data=>{
    return     <tr>
    <th scope="row">{data.sno}</th>
    <td>{data.aclient}</td>

    <td>{data.aprocategory}</td>
    <td>{data.productname}</td>
    <td>{data.ahsncode}</td>
    <td>{data.aproprice}</td>
    <td>{data.aprodesc}</td>
    <td><button  onClick={()=> { navigate("update", { replace:false, state: { sno:data.sno } }) }} className="btn btn-success">Update</button></td>

  </tr>
 }).reverse()}
  </tbody>
</table>
</div>
    </>;
}
export default ADProList;
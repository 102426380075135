import axios from "axios";
import { useState } from "react";
import { ServerUrl } from "../../../../ADAPI";
const DiscountUp = ({sno}) => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const [oldDiscAm, newDiscAm] = useState();
    const HandleSubmit = (event) => {
        event.preventDefault();
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append("sno", sno);
        formdata.append("adiscount", oldDiscAm);
        axios.post(ServerUrl+"aderp/admin/quot/upquot.php",formdata).then(response=>{
            alert(response.data);
        }).catch(err => { console.log(err); })


    }
    return <div>
        <hr/>
        <form onSubmit={HandleSubmit}>
<input type="text" className="form form-control" onChange={(e)=> { 
    if(isNaN(e.target.value)){
        alert("Enter Valid Number");
        e.target.value = "";
}else { 
    newDiscAm(e.target.value);
}

}} placeholder="Enter Discount Amount" />
<br/>
<button type="submit"  className="btn btn-success">Update</button>
</form>
    </div>;
}
export default DiscountUp;
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ServerUrl } from "../../../../ADAPI";

const MWO = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const sno = location.state.sno;
    const [WOData, SetWOData] = useState([]);
    useEffect(()=>{
        const formdata = new FormData();
        formdata.append("api_key", "SwapnilAllowed");
        formdata.append("sno", sno);
        axios.post(ServerUrl+"aderp/admin/machineworkorder/getsnowo.php", formdata).then(reponse => {
            SetWOData(reponse.data);
        })
    }, [sno])

    function pad(n, length) {
        var len = length - (''+n).length;
        return (len > 0 ? new Array(++len).join('0') : '') + n
    }

return <>
    <>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-nb2d{border-color:inherit;font-size:100%;text-align:center;vertical-align:top}\n.tg .tg-sg5v{border-color:inherit;font-size:100%;text-align:left;vertical-align:top}\n.tg .tg-c3ow{border-color:inherit;font-size:100%;text-align:center;vertical-align:top}\n.tg .tg-0pky{border-color:inherit;font-size:100%;text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 885 }}>
      <colgroup>
        <col style={{ width: 187 }} />
        <col style={{ width: 205 }} />
        <col style={{ width: 121 }} />
        <col style={{ width: 120 }} />
        <col style={{ width: 138 }} />
        <col style={{ width: 114 }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-sg5v" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>WO.NO : </span>APEPL/MC/{new Date().getFullYear()}/{pad(WOData.map(data=>{ return sno }),3)}

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <span style={{ fontWeight: "bold" }}> Date :</span>{WOData.map(data=>{
                return new Date(data.adate).toLocaleDateString();
            })}{" "}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>Equipment Name : </span>&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;{WOData.map(data=>{
                return data.equipname;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>Capacity : </span>&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;{WOData.map(data=>{
                return data.capacity;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>Model :</span>&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;{WOData.map(data=>{
                return data.model;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Dimension in</span>
          </td>
          <td className="tg-nb2d" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Inside :</span>
          </td>
          <td className="tg-nb2d" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Over all sizes : </span>
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={2}>
            MM ( W X D X H )
          </td>
          <td className="tg-sg5v" colSpan={2} >{WOData.map(data=>{
                return data.inside;    
            })}</td>
          <td className="tg-sg5v" colSpan={2}>
          {WOData.map(data=>{
                return data.oversize;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Temp Range (°C)</span>
          </td>
          <td className="tg-sg5v" >          {WOData.map(data=>{
                return data.temprange;    
            })}</td>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Accuracy (± °C)</span>
          </td>
          <td className="tg-sg5v" >
          {WOData.map(data=>{
                return data.taccuracy;    
            })}
          </td>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Uniformity (± °C)</span>
          </td>
          <td className="tg-sg5v" >
          {WOData.map(data=>{
                return data.tuniformity;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Humidity Range (%RH)</span>
          </td>
          <td className="tg-sg5v" >
          {WOData.map(data=>{
                return data.humrange;    
            })}
          </td>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Accuracy (± %RH)</span>
          </td>
          {/* haccuracy */}
          <td className="tg-sg5v" >          {WOData.map(data=>{
                return data.haccuracy;    
            })}</td>
          <td className="tg-sg5v">
            <span style={{ fontWeight: "bold" }}>Uniformity (± %RH)</span>
          </td>
          <td className="tg-sg5v" >
          {WOData.map(data=>{
                return data.huniformity;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              Microprocessor Based PID Controller System
            </span>
          </td>
          <td className="tg-nb2d">          {WOData.map(data=>{
                return data.microproces_based_control_sys;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              Printer Interface Facility
            </span>
          </td>
          <td className="tg-nb2d">{WOData.map(data=>{
                return data.printer_interface_fac;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              Microprocessor Based Safety Controller
            </span>
          </td>
          <td className="tg-nb2d">{WOData.map(data=>{
                return data.microprocess_based_Safety_control;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-sg5v" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              21 CFR Software Compliance for Controller
            </span>
          </td>
          <td className="tg-nb2d">{WOData.map(data=>{
                return data.cfr_21_soft_compilance;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              21 CFR Compliance for Scanner
            </span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.cfr_21_compliance_scanner;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Safety Thermostat</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.safety_thermostat;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>PLC Based System</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.plc_based_system;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>GSM System</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.gsm_system;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Scanner</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.scanner;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Timer</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.timer;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Lux Meter / UV Meter</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.lux_meter_uv_meter;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>
              Lux Meter / UV Meter : Printer Interface facility
            </span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.lux_meter_uv_printer_interface;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-c3ow" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>STAND BY SYSTEM</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Refrigeration System</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.refrigeration_system;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>S. G. System</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.sg_system;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Sensor</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.sensor;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-c3ow" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>TRAY</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Tray MOC</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.traymoc;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Tray Type</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.tray_type;    
            })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Tray Qty</span>
          </td>
          <td className="tg-c3ow">{WOData.map(data=>{
                return data.tray_quant;    
            })}</td>
        </tr>

{/* Documentation Splitting Here */}
<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

        <tr>
          <td className="tg-c3ow" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>DOCUMENTATION</span>
          </td>
        </tr>
{WOData.map(data=>{ 
    const data1 = data.doumentation.split('.');

   return data1.map(data2=>{
            return         <tr>
            <td className="tg-0pky" colSpan={6}>
            {data2}
            </td>
          </tr>
    })

})}


       
        {/* <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr> */}
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Other optional if any :</span>
          </td>
          <td className="tg-0pky" colSpan={5} >
          {WOData.map(data=>{
                return data.otherifany;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Special Instruction : </span>
          </td>
          <td className="tg-0pky" colSpan={5}>
            {/* special_instru */}
            {WOData.map(data=>{
                return data.special_instru;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Target Date :</span>
          </td>
          <td className="tg-0pky" colSpan={2} >
          {WOData.map(data=>{
                return new Date(data.target_date).toLocaleDateString();  
            })}
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Complete Date :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
            {/* complete_date */}
            {WOData.map(data=>{
                return data.complete_date;    
            })}
            </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Dispatch Date :</span>
          </td>
          <td className="tg-0pky" colSpan={5}>
          {WOData.map(data=>{
                return data.dispatch_date;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Reason if Delay :</span>
          </td>
          <td className="tg-0pky" colSpan={5}>
          {WOData.map(data=>{
                return data.reason_if_any;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={6} />
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Prepared By :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Verify By :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Authorized By</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={2}>
          {WOData.map(data=>{
                return data.prep_by;    
            })}
            <br />
            <br />
            <br />
          </td>
          <td className="tg-0pky" colSpan={2} >
          {WOData.map(data=>{
                return data.verify_by;    
            })}
          </td>
          <td className="tg-0pky" colSpan={2}>
            Mr. Digamber Pawar
            <br />
          </td>
        </tr>
        <tr>
          <td className="tg-c3ow" colSpan={6}>
            <span style={{ fontWeight: "bold" }}>CC</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>1) Production</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>2) Documents </span>
          </td>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>3) Store</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>4) Service</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">Name :           {WOData.map(data=>{
                return data.prduction_dept_name;    
            })}</td>
          <td className="tg-0pky">Name : {WOData.map(data=>{
                return data.document_dept_name;    
            })}</td>
          <td className="tg-0pky" colSpan={2}>
            Name :{WOData.map(data=>{
                return data.store_dept_name;    
            })}
          </td>
          <td className="tg-0pky" colSpan={2}>
            Name :{WOData.map(data=>{
                return data.service_dept_name;    
            })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            Sign : <br />
          </td>
          <td className="tg-0pky">
            Sign :<br />
          </td>
          <td className="tg-0pky" colSpan={2}>
            Sign :<br />
          </td>
          <td className="tg-0pky" colSpan={2}>
            Sign :<br />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</>

</>;
}

export default MWO;
import { useLocation } from "react-router-dom";
import PrivilagesComp from "./PrivilagesComp";
const User = () => { 
    const location = useLocation();
    // console.log(location.state);

    return(<>
        <PrivilagesComp aname={location.state.aname} aemail={location.state.aemail} />
    </>)
}

export default User;
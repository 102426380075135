import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../ADAPI";
const CreateUser = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    console.log(location.state.api_key);
  const navigate = useNavigate();
     // AMC
     const [oldAMCP, newAMCP] = useState("denied");
     //Calibration
     const [oldCalP, newCalP] = useState("denied");
     // Store
     const [oldStoreP, newStoreP] = useState("denied");
     // Courier
     const [oldCourierP, newCourierP] = useState("denied");
     // WorkOrder
     const [oldWorkOrdP, newWorkOrdP] = useState("denied");
     // Products
     const [oldProductP, newProductP] = useState("denied");
     // Quotations
     const [oldQuotP, newQuotP] = useState("denied");
     // Clients
     const [oldClientP, newClientP] = useState("denied");
     // Proforma Invoice
     const [oldProInvo, newProInvo] = useState("denied");
          // Vendors 
          const [oldVendor, newVendor] = useState("denied");
     // Name 
     const [oldaname, newaname] = useState();
     // Email
     const [oldEmail, newEmail]= useState();
     // Password 
     const [oldPassword, newPassword] = useState();
     
    const [oldTableData, newTableData] = useState([]);
    const HandleSubmit = (event) => {
        event.preventDefault();
        if(Boolean(oldaname) && Boolean(oldEmail) && Boolean(oldPassword)){
            const formdata = new FormData();
            formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("aname", oldaname);
            formdata.append("aemail", oldEmail);
            formdata.append("apassword", oldPassword);
            formdata.append("aamc",oldAMCP);
            formdata.append("acal",oldCalP);
            formdata.append("astore",oldStoreP);
            formdata.append("acourier",oldCourierP);
            formdata.append("aworder",oldWorkOrdP);
            formdata.append("aproduct",oldProductP);
            formdata.append("aqouet",oldQuotP);
            formdata.append("aclient",oldClientP);
            formdata.append("apreinvoice",oldProInvo);
            formdata.append("avendor",oldVendor);

            axios.post(ServerUrl+"aderp/admin/CreateUser.php", formdata).then(response => { 
                alert(response.data);
                event.target.reset();
                SwapDataGetter();
            }).catch(err => { console.log(err); })

        }else{
            alert("All Fields Required")
        }
    }
    
    console.log(oldAMCP);

    const SwapDataGetter = () => { 
      axios.get(ServerUrl+"aderp/user/UsersTableData.php?api_key=7288edd0fc3ffcbe93a0cf06e3568e28521687bc").then(response => { newTableData(response.data); }).catch(err => { console.log(err); })
    }
    useEffect(SwapDataGetter, []);


    return(<>
  <button onClick={()=>{ navigate(-1) }} className="back back--one">
  <span className="top-left" />
  <span className="top-right" />
  <span className="bottom-left" />
  <span className="bottom-right" />
  <span className="stalk" />
  <span className="text">BACK</span>
</button>

  <div className="row">
    <div className="col">
        <div className="container">
        <br />
    <strong> Create New User </strong>
    <hr />
        <form onSubmit={HandleSubmit}>
            <input type="text" placeholder="Enter UserName" onChange={(event)=>{
                newaname(event.target.value);
            }} className="form form-control" />
            <br />
            <input type="email" placeholder="Enter Email ID" onChange={(event)=>{
                newEmail(event.target.value);
            }} className="form form-control" />
            <br />

            <input type="text" onChange={(event)=>{newPassword(event.target.value);}} placeholder="Enter Password" className="form form-control" />


            <br />
            <strong>AMC</strong> &emsp;&emsp;&emsp;&nbsp;&emsp; <input type="radio" value="allow" name="amc" onChange={(event)=> { newAMCP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="amc" onChange={(event)=> { newAMCP(event.target.value); }} />Denied
            <br />
            <strong>Calibration</strong> &nbsp;&emsp; <input type="radio" value="allow" name="cal" onChange={(event)=> { newCalP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cal" onChange={(event)=> { newCalP(event.target.value); }} />Denied
            <br />
            <strong>Clients</strong> &nbsp;&emsp;&emsp;&emsp; <input type="radio" value="allow" name="cl" onChange={(event)=> { newClientP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cl" onChange={(event)=> { newClientP(event.target.value); }} />Denied
            <br />
            <strong>Couriers</strong> &emsp;&emsp;&nbsp;&nbsp; <input type="radio" value="allow" name="cr" onChange={(event)=> { newCourierP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="cr" onChange={(event)=> { newCourierP(event.target.value); }} />Denied
            <br />
            <strong>Pro Invoice</strong> &nbsp;&emsp; <input type="radio" value="allow" name="pi" onChange={(event)=> { newProInvo(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="pi" onChange={(event)=> { newProInvo(event.target.value); }} />Denied
            <br />
            <strong>Products</strong> &emsp;&emsp;&nbsp; <input type="radio" value="allow" name="product" onChange={(event)=> { newProductP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="product" onChange={(event)=> { newProductP(event.target.value); }} />Denied
            <br />
            <strong>Quotations</strong> &emsp;&nbsp; <input type="radio" value="allow" name="Quotations" onChange={(event)=> { newQuotP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="Quotations" onChange={(event)=> { newQuotP(event.target.value); }} />Denied           
            <br />
             <strong>Strore</strong> &emsp;&emsp;&emsp;&nbsp;&nbsp; <input type="radio" value="allow" name="str" onChange={(event)=> { newStoreP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="str" onChange={(event)=> { newStoreP(event.target.value); }} />Denied
            <br />
             <strong>Work Orders</strong> &nbsp; <input type="radio" value="allow" name="wo" onChange={(event)=> { newWorkOrdP(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="wo" onChange={(event)=> { newWorkOrdP(event.target.value); }} />Denied
            <br />
             <strong>Vendors</strong> &nbsp; <input type="radio" value="allow" name="v" onChange={(event)=> { newVendor(event.target.value); }} />Allow &nbsp;   
            <input type="radio" value="denied" name="v" onChange={(event)=> { newVendor(event.target.value); }} />Denied
            <br />
            <button type="submit" className="btn btn-primary">Submit</button>&emsp;<button type="reset" className="btn btn-danger">Clear</button>
        </form>
        </div>
    </div>
    <div className="col">
{/* Users List Table */}
<table className="table">
  <thead className="thead-dark">
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">UserName</th>
      <th scope="col">Email</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    {oldTableData.map(data => {
      return     <tr>
      <th scope="row">{data.sno}</th>
      <td>{data.aname}</td>
      <td>{data.aemail}</td>
      <td>{data.adate}</td>
    </tr>
    })}

    
  </tbody>
</table>

    </div>
  </div>



    </>);
}

export default CreateUser;
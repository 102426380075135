import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServerUrl } from "../ADAPI";
const PrivilagesComp = (props) => {
   
    // Components Variables Below
    const navigate = useNavigate();
    // AMC
    const [oldAMCP, newAMCP] = useState(<></>);
    //Calibration
    const [oldCalP, newCalP] = useState(<></>);
    // Store
    const [oldStoreP, newStoreP] = useState(<></>);
//Vendor
  const [oldVendor, newVendor] = useState(<></>);
    // Courier

    const [oldCourierP, newCourierP] = useState(<></>);
    // WorkOrder
    const [oldWorkOrdP, newWorkOrdP] = useState(<></>);
    // Products
    const [oldProductP, newProductP] = useState(<></>);
    // Quotations
    const [oldQuotP, newQuotP] = useState(<></>);
    // Clients
    const [oldClientP, newClientP] = useState(<></>);
    // Proforma Invoice
    const [oldProInvo, newProInvo] = useState(<></>);
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    useEffect(()=>{

      // const config = {
      //   headers: { Authorization: "Bearer " + localStorage.getItem("adtoken")}
      // };
          axios.get(ServerUrl+"aderp/verifytoken.php").then(response => { 
            console.log(response.data);
          }).catch(err => { 
            console.log(err);
            navigate("/", { replace:true });
          });
            
        
      
      },[])
    const formdata = new FormData();
    formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
    formdata.append('aemail', props.aemail);


    useEffect(()=>{
        axios.post(ServerUrl+"aderp/user/Privilages.php", formdata).then(response=>{ 
          console.log(response.data);
            if (response.data.aamc === "allow") {
                newAMCP(<><div className="col-sm-3" style={{"padding":"10px"}}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"><i className="fa fa-solid fa-gears"></i>&nbsp; AMC </h5>
                
                    {/* <p className="card-text">
                      With supporting text below as a natural lead-in to additional content.
                    </p>
                    <a href="#" className="btn btn-primary">
                      Go somewhere
                    </a> */}
                  </div>
                </div>
              </div></>);
            }
            if (response.data.acal === "allow") {
                // console.log("Allowed");
                newCalP(<>
                <div className="col-sm-3" style={{"padding":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-list"></i>&nbsp;Calibration </h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
</>);
            }   
            if (response.data.avendor === "allow") {
              // console.log("Allowed");
              newVendor(<>
              <div className="col-sm-3" onClick={()=>{ navigate("vendor", {replace:false, state:{"api_key":"swapnilallowed"}}) }} style={{"padding":"10px"}}>
  <div className="card">
    <div className="card-body">
      <h5 className="card-title"><i className="fa fa-solid fa-list"></i>&nbsp;Vendors </h5>
      {/* <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" className="btn btn-primary">
        Go somewhere
      </a> */}
    </div>
  </div>
</div>
</>);
          }   
             if (response.data.aclient === "allow") {
                // console.log("Allowed");

                newClientP(<>
                  <div className="col-sm-3" onClick={()=>{ navigate("client", {replace:false, state:{"api_key":"swapnilallowed"}}) }} style={{"padding":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-mug-hot"></i>&nbsp; Clients</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
                </>)

            }   
             if (response.data.acourier === "allow") {
            // console.log("Allowed");
            newCourierP(<><div className="col-sm-3" onClick={()=>{ navigate("courier", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }} style={{"padding":"10px"}}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title"><i className="fa fa-solid fa-envelope"></i>&nbsp; Couriers</h5>
                {/* <p className="card-text">
                  With supporting text below as a natural lead-in to additional content.
                </p>
                <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
              </div>
            </div>
          </div></>);
            }   
             if (response.data.apreinvoice === "allow") {
                // console.log("Allowed");
                newProInvo(<>  <div className="col-sm-3"
                onClick={()=>{ navigate("pi", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }} 
                style={{"padding":"10px"}}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"><i className="fa fa-solid fa-file-invoice"></i>&nbsp; Proforma  Invoice</h5>
                    {/* <p className="card-text">
                      With supporting text below as a natural lead-in to additional content.
                    </p>
                    <a href="#" className="btn btn-primary">
                      Go somewhere
                    </a> */}
                  </div>
                </div>
              </div>
                </>);
            }    
            if (response.data.aproduct === "allow") {
                // console.log("Allowed");
                newProductP(<>
                  <div className="col-sm-3" onClick={()=>{ navigate("adpro", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }}  style={{"padding":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-industry"></i>&nbsp;Products</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>
</>);
            }   
             if (response.data.aqouet === "allow") {
                // console.log("Allowed");
                newQuotP(<>
                <div className="col-sm-3" onClick={()=>{ navigate("quot", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }} style={{"padding":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-file"></i> &nbsp; Quotations</h5>
        {/* <p className="card-text">
        
          With supporting text below as a natural lead-in to additional content.
        </p> */}
        {/* <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div></>);
            }
            if (response.data.astore === "allow") {
                // console.log("Allowed");
                newStoreP(<><div className="col-sm-3" onClick={()=>{ navigate("store", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }} style={{"padding":"10px"}}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"><i className="fa fa-solid fa-store"></i> &nbsp; Store</h5>
                    {/* <p className="card-text">
                    
                      With supporting text below as a natural lead-in to additional content.
                    </p> */}
                    {/* <a href="#" className="btn btn-primary">
                      Go somewhere
                    </a> */}
                  </div>
                </div>
              </div></>);
            }
            if (response.data.aworder === "allow") {
                // console.log("Allowed");
                newWorkOrdP(<>  <div className="col-sm-3" 
                onClick={()=>{ navigate("workorder", {replace:false, state:{api_key:"swapnilallowed", aname: response.data.aname}}) }}
                style={{"padding":"10px"}}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"><i className="fa fa-solid fa-briefcase"></i>&nbsp; Work Orders</h5>
                    {/* <p className="card-text">
                      With supporting text below as a natural lead-in to additional content.
                    </p>
                    <a href="#" className="btn btn-primary">
                      Go somewhere
                    </a> */}
                  </div>
                </div>
              </div></>);
            }

         }).catch(err => { console.log(err) })
    },[]);

    // Below Will Get Permissions
    // console.log(oldReponse);
    
    return(<>
        <nav className="navbar bg-light" style={{"backgroundColor":"skyblue"}}>
  <div className="container-fluid">
    <a className="navbar-brand" href="#">
      <img
        src="Assets/logo.png"
        alt="Logo"
        width={30}
        height={24}
        className="d-inline-block align-text-top"
      />&nbsp;
   <strong>ERP</strong> AD Pharma Equipment Pvt. Ltd. 
    </a>
    <label>Login User : {props.aname} &nbsp; <a href="/" className="btn btn-danger">Logout</a></label>
    
  </div>
</nav>

    <br />
    <br />
    <br />
    
    
    <div className="container">

<div className="row">


{oldAMCP}
{oldCalP}
{oldClientP}
{oldCourierP}
{oldProInvo
}
{oldProductP}
{oldQuotP}{
    oldStoreP
}
{oldWorkOrdP}
{oldVendor}
<div className="col-sm-3" style={{"padding":"10px"}}>
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fa fa-solid fa-bug"></i>&nbsp;Report Bug</h5>
        {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  </div>

</div>


        </div>
    </>)
}

export default PrivilagesComp;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { ServerUrl } from "../../ADAPI";
const ADPro = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    // AD Product Comp equipment amc/calibration/sparepart
    const navigate = useNavigate();
    // Options Array
    const optionArray = [ { label: "Equipment", value:"equipment" }, {label : "Amc / Calibration / Spare Part", value: "acs"} ];

    // Data Store Variables
    const [oldClientName, newClientName] = useState();
    const [oldProCat, newProCat] = useState();
    const [oldAProName, newProName] = useState();
    const [oldHsnCode, newHSNCode] = useState();
    const [oldProPrice, newProPrice] = useState();
    const [oldDesc, newDesc] = useState();
    const [oldProTac, newProTac] = useState();

    // Variable For Equipment Properties
    const [oldProRange, newProRange] = useState(null);
    const [oldProAcc, newProAcc] = useState(null);
    const [oldUniForm, newuniform] = useState(null);
    const [oldEquConst, newEquConst] = useState(null);
    const [oldContSyst, newContSyst] = useState(null);
    const [oldRefigeration, newRefigeration] = useState(null);
    const [oldSafety, newSafety] = useState(null);
    const [oldOptStand, newOptStand] = useState(null);
    const [oldCFR, newCFR] = useState(null);
    const [oldEQDocs, newEQDocs] = useState(null);
    const [oldSoftDocs, newSoftDocs] = useState(null);


// Getting Client Data
const [oldTabData, newTabData] = useState([]);
const SwapDataGetter = () =>  {
    axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newTabData(response.data);
     });
}
useEffect(SwapDataGetter,[]);
const ClientOptArr = oldTabData.map(data => { return { label : data.acompanyname, value : data.acompanyname } })

// const [oldOptionMode, newOptionMode] = useState(<></>);
let SwapObjData = <></>;
if(oldProCat==="equipment"){
    SwapObjData = <>
                <br/>
    <label>Product Range</label>
    <input type="text" onChange={(e)=>{ newProRange(e.target.value); }} className="form form-control" placeholder="Enter A Product Range" />
    <br/>
    <label>Product Accuracy</label>
    <input type="text" onChange={(e)=>{ newProAcc(e.target.value); }} className="form form-control" placeholder="Enter A Product Accuracy" />
    <br/>
    <label>Product Uniformity</label>
    <input type="text" onChange={
        (e)=> { newuniform(e.target.value); }
    } className="form form-control" placeholder="Enter A Product Uniformity" />
    <br/>
    <label>Equipment Construction</label>
    <textarea onChange={(e)=>{ newEquConst(e.target.value); }} className="form form-control" placeholder="Enter A Product Contruction" />
    <br/>
    <label>Control System</label>
    <textarea onChange={(e)=>{ newContSyst(e.target.value); }} className="form form-control" placeholder="About Control System" />
    <br/>
    <label>Refigeration System</label>
    <textarea className="form form-control" onChange={(e)=>{ newRefigeration(e.target.value); }} placeholder="Enter About Refigeration System" />
    <br/>
    <label>Safety Features System</label>
    <textarea onChange={(e)=>{ newSafety(e.target.value) }} className="form form-control" placeholder="Enter Safety Features" />
    <br/>
    <label>Optional Stand By as Per Requirements</label>
    <textarea onChange={(e)=> { newOptStand(e.target.value); }} className="form form-control" placeholder="About Optional Stand by as Per Requirements" />
    <br/>
    <label>21 CFR Part 11 Compliance Software</label>
    <textarea onChange={(e)=>{ newCFR(e.target.value); }} className="form form-control" placeholder="21 CFR Part 11 Compliance Software" />
    <br/>
    <label>Documentation for Equipement</label>
    <textarea onChange={(e)=>{ newEQDocs(e.target.value); }} className="form form-control" placeholder="Documentation for Equipement" />
    <br/>
    <label>Documentation for Software</label>
    <textarea onChange={(e)=>{ newSoftDocs(e.target.value); }} className="form form-control" placeholder="Documentation for Software" />
    </>;

    // newOptionMode(SwapObjData);
}else { 
    SwapObjData=<></>;
}




const HandleFormSubmit = (event) => { 
    event.preventDefault();
    if(Boolean(oldClientName) && Boolean(oldProCat) && Boolean(oldAProName) && Boolean(oldHsnCode) && Boolean(oldProPrice) && Boolean(oldDesc) && Boolean(oldProTac)){
        const formdata = new FormData();
        formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
        formdata.append('aclient', oldClientName);
        formdata.append('aprocategory', oldProCat);
        formdata.append( 'productname', oldAProName);
        formdata.append('ahsncode', oldHsnCode);
        formdata.append('aproprice', oldProPrice);
        formdata.append('aprodesc', oldDesc);
        formdata.append('aprodtandc', oldProTac);

        if(oldProCat==="equipment"){ 
            formdata.append('arange', oldProRange);
            formdata.append('aaccuracy', oldProAcc);
            formdata.append('auniformity', oldUniForm);
            formdata.append('aequpconst', oldEquConst);
            formdata.append('acontrolsys', oldContSyst);
            formdata.append('arefsystem', oldRefigeration);
            formdata.append('asafefeature', oldSafety);
            formdata.append('aoptionalstand', oldOptStand);
            formdata.append('acfrpart', oldCFR);
            formdata.append('adocequp', oldEQDocs);
            formdata.append('adocsoft', oldSoftDocs);
        }

        axios.post(ServerUrl+"aderp/admin/adaddpro.php", formdata).then(response=>{ alert(response.data);window.location.reload(); }).catch(err => { 
            console.log(err)
        })
    }else{
        alert("All Fields required");
    }
}





    return(<>    <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
  
<div className="container">


<button className="btn btn-primary" onClick={()=> { navigate("list", { replace:false }) }}>List</button>

&nbsp; &nbsp;
<button className="btn btn-primary" onClick={()=> { navigate("stack", { replace:false }) }}>Check Products Stack</button>



<form onSubmit={HandleFormSubmit}>

    <hr />
    <strong>Add Products</strong>
    <br />
    <br />
    <label>Select Client Name</label>
    <br/>
  
    <Select options={ClientOptArr} onChange={(newValue)=>{ newClientName(newValue.value); }} placeholder="Select Client" /><br/>
    <label>Select Product Category</label>
    <br/>
    
    <Select options={optionArray} onChange={(newValue) =>{ newProCat(newValue.value); }}  placeholder="Select Category" />
    <br/>
    <label>Product Name</label>
    <input type="text" className="form form-control" onChange={(e)=>{ newProName(e.target.value); }} placeholder="Enter A Product Name" />
    <br/>
    <label>HSN/SAC Code</label>
    <input type="text" className="form form-control" onChange={(e)=>{ newHSNCode(e.target.value); }} placeholder="Enter HSN/SAC Code" />
    <br/>
    <label>Product Price</label>
    <input type="text" className="form form-control" onChange={(e)=>{ newProPrice(e.target.value); }} placeholder="Enter A Product Price" />
    <br/>
    <label>Product Description</label>
    <textarea  className="form form-control" onChange={(e)=>{ newDesc(e.target.value); }} placeholder="Enter A Product Description" />
    <br/>
    <label>Product T&C</label>
    <textarea type="text" className="form form-control" onChange={(e)=>{ newProTac(e.target.value);}} placeholder="Enter A Product T & C" />
    {SwapObjData}
<br/>

<button className="btn btn-primary" type="submit">Submit</button>
&nbsp;&nbsp;
<button className="btn btn-danger" type="reset">Clear</button>
</form>
    


</div>

  
  </>);
}

export default ADPro;
import { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";
import Select from "react-select";


const PIUp = (props) => { 

    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
 // Creating Array of Calculations   
 const [oldSelectArr, newSelectArr] = useState([]);
    
 let count =1; //For Show Serial No
const [oldArrayDataObj, newArrayDataObj] = useState([]);

useEffect(()=>{
 newArrayDataObj(oldSelectArr.map(data=>{ return {name:data.value, desc:data.value2.replace(/(\r\n|\n|\r)/gm, " "),quantity: 0, rateperunit:0, amount:0 }}));
},[oldSelectArr])
//    let arr = oldSelectArr.map(data=>{
//         return {name:data.value, quantity: 0, rateperunit:0, amount:0 }
//     });  

 const SwapData = () => {
     console.log(oldArrayDataObj);
 }
 
 // End Creating Array of Calculation

 // Products List And HSN Code
      
 const [oldProData, newProData] = useState([]);
 useEffect(
     ()=>{
         axios.get(ServerUrl+"aderp/admin/adgetpro.php?api_key=SwapnilAllowed").then(response=> {
             newProData(response.data);
         })
     }, []
 )
 let proDataArray = oldProData.map(data => {
     return {  value: data.ahsncode+"-"+data.productname, value2 : data.aprodesc,label: data.productname }
 })

// End Product List And HSN Code
const [oldDiscount, newDiscount] = useState();
const [oldGst, newGst] = useState();
const [oldIGST, newIGST]= useState();
    const HandleFormSubmit = (event) => { 
   
        event.preventDefault();
/* 
        $sno=$_POST['sno'];
        $amateitem = $_POST['amaterialarr'];
        $adiscount = $_POST['adiscount'];
    $agst = $_POST['aGstper'];
    
    $aigst = $_POST['aigst'];
    // $atotal = $_POST['generatedby'];
    $auser = $_POST['auser'];*/
        if(Boolean(oldDiscount) && Boolean(oldGst) && Boolean(oldIGST)){
                const formdata = new FormData();
                formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
                formdata.append("sno", props.sno);
                formdata.append("amaterialarr", JSON.stringify(oldArrayDataObj));
                formdata.append("adiscount", oldDiscount);
                formdata.append("aGstper", oldGst);
                formdata.append("aigst", oldIGST);
                formdata.append("auser", props.aname);
                axios.post(ServerUrl+"aderp/user/pi/uppo.php", formdata).then(
                    response => { 
                         alert(response.data);
                         window.location.reload();
                    }
                )

        }else { 
            alert("All Fields Reuired")
        }
    }

    const [oldStatus, newStatus] = useState(false);
    
    return <>
    <form  onSubmit={HandleFormSubmit}>
    <div className="container">

        <label>Modifying Purchase Order No  00{props.sno} by User {props.aname}</label>
        <br />
        {/* Calculation Logics Start Here */}
 <label>Select Products</label><br/>
<Select options={proDataArray} isMulti={true} isDisabled={oldStatus}  maxMenuHeight={175} onChange={(newValue)=> { newSelectArr(newValue);
SwapData(); 
}} />
<br />

<table className="table" onClick={()=>{newStatus(true)}}>
  <thead className="thead-dark">
    <tr>
      <th scope="col">Sr.No</th>
      <th scope="col">Items Code & Description</th>
      <th scope="col">Quantity</th>
      <th scope="col">Rate Per Unit( Rs )</th>
      
    </tr>
  </thead>
  <tbody>
{oldArrayDataObj.map(data=>{
 
return <tr>
        <th scope="row">{count++}</th>
        <td>{data.name}</td>
        <td><input type="number" className="form form-control" onChange={(e)=> {
            e.preventDefault();
    
            data.quantity=e.target.value;
        data.amount=data.quantity*data.rateperunit;        
        SwapData(); }} defaultValue={data.quantity} /></td>
        <td><input type="text" className="form form-control" onChange={(e)=>{
            e.preventDefault();
        
            data.rateperunit=e.target.value;
            data.amount=data.quantity*data.rateperunit;
            SwapData();
        }}  /></td>
        
  
      </tr>
})}

  </tbody>
</table>

<hr />

    <label>Enter A Discount</label>
    
    <input type="number" max={100} className="form form-control" onChange={(e)=>{ e.preventDefault();newDiscount(e.target.value); }} placeholder="Enter A Discount in %" />
    <br/>
    <label>Enter A GST Percentage</label>
    
    <input type="number" max={100} onChange={(e)=>{e.preventDefault(); newGst(e.target.value); }} className="form form-control" placeholder="Enter A GST in %" />
<br />
    <label>Enter IGST Percentage</label>
    
    <input type="number" max={100} className="form form-control" onChange={(e)=>{e.preventDefault(); newIGST(e.target.value); }} placeholder="Enter A IGST in %" />
<br />
<br />



<br/>
<hr/>




<button type="submit" className="btn btn-primary">Generate Purchase Order</button>
&nbsp;&nbsp;
<button type="reset" className="btn btn-danger">Clear All Fields</button>    
<br/>
<br/>
    </div>
    </form>
    </>;
}

export default PIUp;
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../../ADAPI";

const AMCWOPdf = () => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const navigate = useNavigate();
  

    const [oldData, newData] = useState([]);
   const [OldArrayData, setOldArrayData] = useState([]);
    useEffect(()=>{ 
        const formData = new FormData();
        formData.append("api_key", "SwapnilAllowed");
        formData.append("sno", location.state.sno);
        axios.post(ServerUrl+"aderp/admin/amcworkorder/getsnowo.php", formData).then(response =>{
            newData(response.data);
        });
    },[])
    console.log(oldData);

    useEffect(() => {
      setOldArrayData(oldData.map(data => { return JSON.parse(data.prodDetails) }));
    }, [oldData]);

    console.log(OldArrayData);
    function pad(n, length) {
        var len = length - (''+n).length;
        return (len > 0 ? new Array(++len).join('0') : '') + n
    }



    
    let count = 1;
    return <>
    
    <>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-baqh{text-align:center;vertical-align:top}\n.tg .tg-bzci{font-size:20px;text-align:center;vertical-align:top}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 1036 }}>
      <colgroup>
        <col style={{ width: 112 }} />
        <col style={{ width: 115 }} />
        <col style={{ width: 94 }} />
        <col style={{ width: 98 }} />
        <col style={{ width: 140 }} />
        <col style={{ width: 131 }} />
        <col style={{ width: 145 }} />
        <col style={{ width: 201 }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-bzci" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>AMC WORK ORDER</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>SERIAL NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </span> &nbsp;WO-AMC-{new Date(
                oldData.map(data => { return data.adate })
            ).toLocaleString('en-US',{year:'2-digit'})}-{parseInt(new Date(
                oldData.map(data => { return data.adate })
            ).toLocaleString('en-US',{year:'2-digit'}))+1}/{pad(oldData.map(data => { return data.sno }),3)} 
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>COMAPNY NAME &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;

            {oldData.map(data => { return data.companyname })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>ADDRESS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span> &nbsp;
            {oldData.map(data => { return data.caddress })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>CONTACT PERSON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;
            {oldData.map(data => { return data.conatctperson })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>CONTACT NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;
            {oldData.map(data => { return data.mobno })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>MAIL ID &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;
            {oldData.map(data => { return data.mailid })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>AMC TYPE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;
            {oldData.map(data => { return data.amc_type })}
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>PURCHASE ORDER NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;
            {oldData.map(data => { return data.purchaseorderno })}
          </td>
          <td className="tg-0lax">
            <span style={{ fontWeight: "bold" }}>DATED :</span>&nbsp;
            {oldData.map(data => { return data.podated })}  
          </td>
          <td className="tg-0lax" colSpan={2} />
        </tr>
        <tr>
          {/* <td className="tg-0lax" colSpan={8} /> */}
        </tr>
        <tr>


          <td className="tg-baqh" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>EQUIPMENT NAME</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>SR.NO.</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>QTY</span>
          </td>


          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>AMC Starts On</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>AMC Due On</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>No. of Visits</span>
          </td>
        </tr>
        {/* <tr>


          <td className="tg-0lax" colSpan={3} >
          {oldData.map(data => { return data.equipmentname })} 
          </td>
          <td className="tg-0lax" >
          {oldData.map(data => { return data.eqsrno })} 
          </td>
          <td className="tg-0lax" >
          {oldData.map(data => { return data.qty })} 
          </td>


          <td className="tg-0lax" >
          
          {oldData.map(data => { return data.amcstartdate })} 
          </td>
          <td className="tg-0lax" >
          {oldData.map(data => { return data.amcenddate })} 
          </td>
          <td className="tg-0lax" >
          {oldData.map(data => {return data.noofvisit })} 
          </td>

        </tr> */}
{OldArrayData ? OldArrayData.map(adata => {
 return adata ? adata.map(bdata => {
      return <tr>


      <td className="tg-0lax" colSpan={3} >
      {bdata.equipmentname} 
      </td>
      <td className="tg-0lax" >
      {bdata.eqsrno} 
      </td>
      <td className="tg-0lax" >
      {bdata.qty} 
      </td>


      <td className="tg-0lax" >
      
      {oldData.map(data => { return data.amcstartdate })} 
      </td>
      <td className="tg-0lax" >
      {oldData.map(data => { return data.amcenddate })} 
      </td>
      <td className="tg-0lax" >
      {oldData.map(data => {return data.noofvisit })} 
      </td>

    </tr> 
  }) : null
}): null}



        <tr>
          {/* <td className="tg-0lax" colSpan={8} /> */}
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={5}>
            <span style={{ fontWeight: "bold" }}>Prepared By :</span>
            <br />
            Name :         {oldData.map(data => { return data.prepby })} 
            <br />
            Sign : <br />
          </td>
          <td className="tg-0lax" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>Recieved By :</span>
            <br />
            Name : {oldData.map(data => { return data.recievedby })} 
            <br />
            Sign :
          </td>
        </tr>
        <tr>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>Sr. No.</span>
          </td>
          <td className="tg-baqh" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>AMC Planning</span>
          </td>
          <td className="tg-baqh" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Actual Visit Date</span>
          </td>
          <td className="tg-baqh" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Visit Number</span>
          </td>
          <td className="tg-baqh">
            <span style={{ fontWeight: "bold" }}>Engineer Name</span>
          </td>
        </tr>


{Array(oldData.length ? parseInt(oldData.map(data => {return data.noofvisit })[0]) : 0).fill(1).map(data=>{
    
    return <tr>
    <td className="tg-0lax" >{count++}</td>
    <td className="tg-0lax" colSpan={2} />
    <td className="tg-0lax" colSpan={2} />
    <td className="tg-0lax" colSpan={2} />
    <td className="tg-0lax" />
  </tr>
})}


        {/* <tr>
          <td className="tg-0lax" />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" />
        </tr>
        <tr>
          <td className="tg-0lax" />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" colSpan={2} />
          <td className="tg-0lax" />
        </tr> */}





        <tr>
          {/* <td className="tg-0lax" colSpan={8} /> */}
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={8}>
            <span style={{ fontWeight: "bold" }}>CC</span>
            <br />
            1) Service Department <br />
            <br />
            Name : {oldData.map(data=> { return data.service_dept_name })}<br />
            <br />
            Sign :<br />
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</>

    
    </>;
}

export default AMCWOPdf;
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ServerUrl } from "../../../ADAPI";

const CalPdf = () => { 
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    const [oldData, newData] = useState([]);
    useEffect(()=>{ 
        const formData = new FormData();
        formData.append("api_key", "SwapnilAllowed");
        formData.append("sno", location.state.sno);
        axios.post(ServerUrl+"aderp/admin/calworkorder/getsnowo.php", formData).then(response =>{
            newData(response.data);
        });
    },[])
    console.log(oldData);

    const [oldDataArryProDesc, newDataArryProDesc] = useState([]);
    useEffect(()=>{
      newDataArryProDesc(oldData.map(data=>{ return JSON.parse(data.prodDetails) }));
    },[oldData])

    function pad(n, length) {
        var len = length - (''+n).length;
        return (len > 0 ? new Array(++len).join('0') : '') + n
    }
    return <>
        <>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}\n.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 768 }}>
      <colgroup>
        <col style={{ width: 286 }} />
        <col style={{ width: 298 }} />
        <col style={{ width: 184 }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-c3ow" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>CALIBRATION WORK ORDER</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>SERIAL NO</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
          WO-CAL-{new Date().toLocaleDateString('en-US', {year : '2-digit'})}-{parseInt(new Date().toLocaleDateString('en-US', {year : '2-digit'}))+1}/{pad(oldData.map(data=>{ return data.sno }), 3)}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>COMPANY NAME</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
          {oldData.map(data=>{ return data.comapnyname })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>ADDRESS</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
          {oldData.map(data=>{ return data.address })}
          </td>
          
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>MAIL ID</span>
          </td>
          <td className="tg-0pky" colSpan={2} >{oldData.map(data=>{ return data.mailid })}</td>
        
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>PURCHASE ORDER NO</span>
          </td>
          <td className="tg-0pky" />
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>DATED :</span>
          </td>
        </tr>
        <tr>
          <td className="tg-c3ow" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>DESCRIPTION</span>
          </td>
          <td className="tg-c3ow">
            <span style={{ fontWeight: "bold" }}>QTY</span>
          </td>
        </tr>
        {/* <tr>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>{oldData.map(data=>{ return data.prodesc })}</span>
          </td>
          <td className="tg-c3ow">
            <span style={{ fontWeight: "bold" }}>{pad(oldData.map(data=>{ return data.qty }),2)} Qty</span>
          </td>
        </tr> */}
        {oldDataArryProDesc ? oldDataArryProDesc.map(adata=>{
          return adata ? adata.map(bdata=>{
            return <tr>
            <td className="tg-0pky" colSpan={2}>
              <span style={{ fontWeight: "bold" }}>{bdata.prodesc}</span>
            </td>
            <td className="tg-c3ow">
              <span style={{ fontWeight: "bold" }}>{pad(bdata.qty,2)} Qty</span>
            </td>
          </tr>
          }) : <></>
        }):<></>}
        <tr>
          <td className="tg-0pky" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>Remark : </span>{oldData.map(data=>{ return data.remark })}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Special Instruction :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>
          {oldData.map(data=>{ return data.specialinstru })}
            <br />
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Target Date :</span>
          </td>
          <td className="tg-0pky">{oldData.map(data=>{ return data.targetdate })}</td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Immediately</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Delivery Period :</span>
          </td>
          <td className="tg-0pky" />
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Immediately</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Dispatch Date :</span>
          </td>
          <td className="tg-0pky" colSpan={2}>{oldData.map(data=>{ return data.dispatchdate })}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={3}>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              THE TEARMS AND CONDITIONS OF OUR CONTRACT ARE AS FOLLOWS :-
            </span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={3}>
          {oldData.map(data=>{ return data.termandcond.split('.').map(adata=>{
                if (adata !== "") {
                    return <>✔ {adata}.
                <br /></>
                }
          }) })}
           
            

            
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Prepared By : </span>
            <br />
            Name :{oldData.map(data=>{ return data.prepby })}<br />
            Sign :<br />
          </td>
          <td className="tg-0pky" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>CC :</span>
            <br />1 ) Service Department : <br /> Name : {oldData.map(data=>{ return data.sercvicedept  })}<br /> Sign :<br />
            <br />
            <br />
          </td>
        </tr>
        <tr>
          <td className="tg-c3ow" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>
              CALIBRATION AND VALIDATION STATUS
            </span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">Calibration Report No. &amp; Date :</td>
          <td className="tg-0pky" colSpan={2} />
        </tr>
        <tr>
          <td className="tg-0lax">Courier Details :</td>
          <td className="tg-0lax" colSpan={2} />
        </tr>
      </tbody>
    </table>
  </div>
</>

    </>;
 }

export default CalPdf;
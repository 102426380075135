import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from "../../ADAPI";
import Select from "react-select";
const CourierPage = () => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("adtoken");
    const location = useLocation();
    // console.log(location.state.aname);
    const navigate = useNavigate();
    /*

        $atransponame = $_POST['atransponame'];
        $adocketno = $_POST['adocketno'];
        $acdate = $_POST['acdate'];
        $atype = $_POST['atype'];

        $afrom = $_POST['afrom'];
        $ato = $_POST['ato'];

        $aitemdetails = $_POST['aitemdetails'];

        $auser = $_POST['auser'];
    */
   //Courier From to 
const [oldFromTo, newFromTo] = useState();

const HandleRadioChange = (e) => {

    newFromTo(e.target.value);
}
console.log(oldFromTo);
const CourierSelectionAct = () => { 

}
//  Clients List
const [oldClientData, newClientData] = useState([]);
useEffect(()=>{
    axios.get(ServerUrl+"aderp/admin/GetClients.php").then(response => { newClientData(response.data);
    });
},[]);

let clientDataArray = oldClientData.map(data=>{
    return { value:data.acompanyname+"\n\n"+data.aadress+"\n GSTIN NO:"+data.agstinno, label:data.acompanyname }
})

// Vendors + Client List
const [oldVend, newVen] = useState([]);
useEffect(()=>{
    axios.get(ServerUrl+"aderp/user/GetVendor.php").then(response=>{
        newVen(response.data);
    })
    
},[]);
let VenClientArray = oldVend.map(data => { 
    return { value:data.acompanyname, label:data.acompanyname }
}).concat(clientDataArray);



const [oldCname, newCname]= useState();
const [oldVname, newVname] = useState();

const ToSendClient =<>
<strong>To</strong>
<Select options={VenClientArray} onChange={(selectedval)=>{ newCname(selectedval.value) }} maxMenuHeight={40} openMenuOnClick={false} /></>;
const FromGetVend = <>
<strong>From</strong>
<Select options={VenClientArray} onChange={(selectedval)=>{ newVname(selectedval.value) }} maxMenuHeight={40} openMenuOnClick={false} />
</>;
let oldShowChoice=<></>;
if(oldFromTo==="to"){
    oldShowChoice = ToSendClient;
}else if(oldFromTo==="from")
{
    oldShowChoice=FromGetVend;
}

const [oldTransport, newTransport] = useState();
const [oldDocNo, newDocNo] = useState();
const [oldCourierDate, newCourierDate]= useState();
const [oldCourierDetails, newCourierDetails] = useState();



const HandleSubmitData = (e) => {
    e.preventDefault();
    if(Boolean(oldTransport) && Boolean(oldDocNo) && Boolean(oldCourierDate) && Boolean(oldCourierDetails) && Boolean(oldFromTo)){
        const formdata = new FormData();
        formdata.append("api_key","7288edd0fc3ffcbe93a0cf06e3568e28521687bc");
            formdata.append("atransponame", oldTransport);
            formdata.append("adocketno", oldDocNo);
            formdata.append("acdate", oldCourierDate);
            formdata.append("atype", oldFromTo);
            formdata.append("aitemdetails", oldCourierDetails);
            formdata.append("auser", location.state.aname);

        if (Boolean(oldCname)) {
            formdata.append("ato", oldCname);
            axios.post(ServerUrl+"aderp/user/courier/AddCourierTo.php", formdata).then(response => { 
                alert(response.data);
                window.location.reload();
            }).catch(err => { console.log(err)})
        }else if(Boolean(oldVname)){
            formdata.append("afrom", oldVname);
            axios.post(ServerUrl+"aderp/user/courier/AddCourierFrom.php", formdata).then(response => { 
                alert(response.data);
                window.location.reload();
            }).catch(err => { console.log(err)})
        }else {
            alert("Please Select Client or vendor Name");
        }
    }else 
    {
        alert("All Fields Reuired");
    }
   }

const [oldSwapData, newSwapData] = useState([]);
useEffect(()=>{ 
    const formdata = new FormData();
    formdata.append("api_key", "7288edd0fc3ffcbe93a0cf06e3568e28521687bc")
    axios.post(ServerUrl+"aderp/user/courier/GetCourierDetails.php", formdata).then(response=> { newSwapData(response.data) }).catch(err => { 
        console.log(err);
    })
}, [])
console.log(oldSwapData);


// Variable to Store SNO for Search

const [oldCSno, newCSno] = useState("");
const optionArrayTab = oldSwapData.map(data => { 
    return { label: data.atransponame+" "+data.adocketno, value: data.sno }
})
const filteredData = oldSwapData.filter(data=>{
    if(oldCSno===""){
        return data;
    }
    else {
        return data.sno===oldCSno;
    }
})




return <>
    <button onClick={()=>{ navigate(-1) }} className="back back--one">
    <span className="top-left" />
    <span className="top-right" />
    <span className="bottom-left" />
    <span className="bottom-right" />
    <span className="stalk" />
    <span className="text">BACK</span>
  </button>
    <div className="container">

    <form onSubmit={HandleSubmitData}>
        <strong>Add Courier Entry</strong>
        <hr />
                <input type="text" className="form form-control" placeholder="Enter Courier/ transporter Name" onChange={(e)=> { newTransport(e.target.value);}} />
                <br />
                <input type="text" className="form form-control" placeholder="Enter A Docket No" onChange={(e)=> { 
                    newDocNo(e.target.value);
                }} />
                <br/>
                 <label>Select Date of Courier</label>
                <input type="date" onChange={(e)=> { 
                    newCourierDate(e.target.value);
                }} className="form form-control" />
                <br />
                <label>Courier Type Send / Recieved</label>
                <br />
                <input type="radio" name="ctype" onClick={HandleRadioChange}  value="from" /> IN
            &nbsp;&nbsp;
                <input type="radio" name="ctype" onClick={HandleRadioChange} value="to" /> OUT
<br />
                {oldShowChoice}

                <br />
                <label><strong>Enter Inside Items Details</strong></label>
                <textarea placeholder="Enter Detaiils"  className="form form-control" onChange={(e)=> { 
                    newCourierDetails(e.target.value);
                }}></textarea>
           <br/> <button type="submit" className="btn btn-primary">Submit</button>&nbsp;
            <button type="reset" className="btn btn-danger">Clear</button>
    </form>
<br />




{/* Data Table Below */}
<Select options={optionArrayTab} onChange={(newValue)=>{
    newCSno(newValue.value);
}} onMenuOpen={()=>{newCSno("") }} />
<br/>
<div className="overflow-auto" style={{"maxHeight":"500px"}}>

    <table className="table table-dark">
    <thead className="thead-dark">
      <tr>
        <th scope="col">SR NO</th>
        <th scope="col">COURIER / TRANSPORTER NAME</th>
        <th scope="col">LR/ DOCKET NO.</th>
        <th scope="col">DATE</th>
        <th scope="col">FROM</th>
        <th scope="col">TO</th>
        <th scope="col">ITEM DETAILS</th>
        

      </tr>
    </thead>
    <tbody>
{filteredData.map(data => { 
        return  <tr>
          <th scope="row">{data.sno}</th>
          <td>{data.atransponame}</td>
          <td>{data.adocketno}</td>
          <td>{data.acdate}</td>
          <td>{data.afrom}</td>
          <td>{data.ato}</td>
          <td>{data.aitemdetails}</td>
          




        </tr>
}).reverse()}


    </tbody>
  </table>

</div>

    </div>
    </>
}

export default CourierPage;